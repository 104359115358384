import { get } from "lodash";
import React, { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { multilanguage } from "redux-multilanguage";
import Constant from '../../../state/utils/constant';
import './GooglePlaceDropDown.scss';
/**
 * A custom React hook that returns the previous value of a given state or prop.
 * @param {*} value - The current value of the state or prop.
 * @returns {*} The previous value of the state or prop.
 */
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

/**
 * A dropdown component that uses the Google Places Autocomplete API to suggest locations.
 * @param {Object} props - The props object.
 * @param {Object} props.strings - An object containing localized strings.
 * @param {Function} props.getLocation - A function to call when a location is selected.
 * @param {Object} props.defaultValue - The default value for the dropdown.
 * @returns A dropdown component that suggests locations based on user input.
 */
const GooglePlaceDropDown = props => {
    let { strings, getLocation, defaultValue } = props;
    const [value, setValue] = useState("New York, NY, USA");
    const prevDefaultValue = usePrevious(defaultValue);

    /**
     * useEffect hook that updates the value state when the defaultValue prop changes.
     * If the label of the defaultValue prop is different from the label of the previous
     * defaultValue prop, then the value state is updated to the new defaultValue.
     * If the label of the defaultValue prop is an empty string, then the value state is
     * set to an empty string.
     * @param {any} defaultValue - the default value prop
     * @returns None
     */
    useEffect(() => {
        if (get(defaultValue, 'label', '') !== get(prevDefaultValue, 'label', ''))
            setValue(defaultValue)
        else if (get(defaultValue, 'label', '') === '') {
            setValue('')
        }
    }, [defaultValue])

    /**
     * Retrieves the latitude and longitude of a location using its place ID.
     * @param {string} placeId - The place ID of the location to retrieve.
     * @returns {Promise<{lat: number, long: number}>} - A promise that resolves to an object containing the latitude and longitude of the location.
     */
    const getLocationAction = async (placeId) => {
        let location = {
            lat: 1,
            long: 1
        }
        try {
            let place = placeId && await geocodeByPlaceId(placeId)
            if (place.length > 0) {
                location['lat'] = place[0].geometry.location.lat()
                location['long'] = place[0].geometry.location.lng()
            }
        } catch (error) {

        }
        return location
    }

    return (
        <div className="googleDropdown">
            <GooglePlacesAutocomplete
                apiKey={Constant.GOOGLEMAPAPI.APIKEY}
                selectProps={{
                    value,
                    backspaceRemovesValue: true,
                    controlShouldRenderValue: true,
                    isClearable: true,
                    placeholder: strings["SEARCH_LOCATION"],
                    onChange: async (e) => {
                        setValue(e)
                        if (e) {
                            let location = await getLocationAction(e.value?.place_id)
                            let address = {
                                address: e.label,
                                location
                            }
                            getLocation && getLocation(address)
                        } else {
                            getLocation(null)
                        }
                    }
                }}
            />
        </div>
    )
}

GooglePlaceDropDown.defaultProps = {
}

export default (multilanguage(GooglePlaceDropDown))
