import React, { useEffect, useState, useRef } from "react";
import { multilanguage } from "redux-multilanguage";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Toast } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import useWindowDimension from "../../../hooks/useWindowDimension";
import SearchBox from "../../../assets/components/UI/SearchBox/SearchBox";
import RightSideMenu from "../RightSideMenu/RightSideMenu";
import Map from "../../../assets/IMGS/Map";
import GoogleMap from "../GoogleMap";
import AxiosInstance from "../../../services/axios";
import MapRightSideMenu from "../MapRightSideMenu/MapRightSideMenu";
import ConfirmModal from "../../../assets/components/UI/ConfirmModal/ConfirmModal";
import ModalPopup from "../../../assets/components/UI/ModalPopup/ModalPopup";
import MetaTags from "react-meta-tags";

import "./DiscoverScreen.scss";

const DiscoverScreen = (props) => {
    let history = useNavigate();
    let { strings } = props;

    let location = useLocation();
    const dimension = useWindowDimension();
    const [searchbar_areaHeight, setSearchbar_areaHeight] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [mapIndex, setMapIndex] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [videoURL, setVideoURL] = useState("");
    const [mapShow, setMapShow] = useState(false);
    const [infoModalShow, setInfoModalShow] = useState(false);
    const [regionList, setRegionList] = useState([]);
    const [regionPinsData, setRegionPinsData] = useState({});
    const [searchText, setSearchText] = useState("");
    const [activeButton, setActiveButton] = useState(0);
    const [sidebarTitle, setSidebarTitle] = useState("All");
    const [search, setSearch] = useState("");
    const [mapDeatilsSidebar, setMapDetailsSidebar] = useState("all");
    const [selectedItem, setSelectedItem] = useState();
    const [regionPinsLists, setRegionPinsLists] = useState([]);
    const [bookMark, setBookMark] = useState(false);

    // const [removeBookMark, setRemoveBookMark] = useState(false);
    const [isOpen, setIsOpen] = useState({ open: false, isFrom: "" });

    const [toastBookMark, setToastBookMark] = useState(false);
    const [selectedBookMark, setSelectedBookMark] = useState();

    const [redirectTab, setRedirectTab] = useState(null);
    const [mainpinsList, setMainpinsList] = useState([]);
    const [searchBarList, setSearchBarList] = useState(false);

    const zoomRef = useRef(null);

    /**
     * Resets the zoom level of the referenced container to 100%.
     * @returns None
     */
    const zoomRefContainer = () => {
        zoomRef.current.resetTransform(1);
    };

    /**
     * Removes a bookmark from local storage and updates the state of the selected item.
     * @returns None
     */
    const onPressRemoveBookMark = () => {
        if (isOpen?.isFrom === "list") {
            const getbookMarkBookArr = JSON.parse(
                localStorage.getItem("bookmark_data")
            );
            if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
                const newData = getbookMarkBookArr.filter(
                    (item) => selectedBookMark._id !== item._id
                );
                localStorage.setItem("bookmark_data", JSON.stringify(newData));
            }
            setBookMark(false);
        } else {
            const getbookMarkBookArr = JSON.parse(
                localStorage.getItem("bookmark_data")
            );
            if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
                selectedItem.bookmark = false;
                const newData = getbookMarkBookArr.filter(
                    (item) => selectedItem._id !== item._id
                );
                localStorage.setItem("bookmark_data", JSON.stringify(newData));
                setSelectedItem(selectedItem);
            }
        }
        setIsOpen({ open: false });
        setToastBookMark(true);
        setTimeout(() => {
            setToastBookMark(false);
        }, 1500);
    };

    /**
     * Updates the region pins list based on the selected value from a filter.
     * @param {any} val - The selected value from the filter.
     * @returns None
     */
    const onSelectFromFilt = (val) => {
        Array.isArray(val) ? setRegionPinsLists(val) : setRegionPinsLists([val]);
    };

    /**
     * useEffect hook that sets the state variables based on the location state object.
     * @param {Object} location - The location object containing the state.
     * @returns None
     */
    useEffect(() => {
        if (location.state !== null) {
            setMapShow(true);
            setRegionPinsData(location.state?.val);
            onSelectFromFilt([location.state?.val]);
            setMapDetailsSidebar(location.state?.detailType);
            setSidebarTitle(location.state?.type);
            setSelectedItem(location.state?.val);
            setOpenSidebar(true);
            setActiveButton(
                location.state?.type == "Books"
                    ? 1
                    : location.state?.type == "Shops"
                        ? 2
                        : location.state?.type == "Events"
                            ? 3
                            : ""
            );
        }
    }, []);

    /**
     * useEffect hook that sets the height of the search bar area and retrieves all regions
     * based on the current search text.
     * @returns None
     */
    useEffect(() => {
        setSearchbar_areaHeight(
            document.getElementsByClassName("searchbar_area")[0]?.offsetHeight
        );
        getAllRegion();
    }, [searchText]);

    /**
     * Runs a function to get the explore region based on the active button and search term.
     * @returns None
     */
    useEffect(() => {
        !_.isEmpty(regionPinsData) &&
            getExploreRegion(
                activeButton === 0
                    ? "all"
                    : activeButton === 1
                        ? "books"
                        : activeButton === 2
                            ? "shops"
                            : activeButton === 3
                                ? "events"
                                : "all"
            );
    }, [search]);

    /**
     * Fetches all regions from the server and sets the region list state.
     * @returns None
     */
    const getAllRegion = async () => {
        await AxiosInstance.get(
            searchText
                ? `user/getallregion?search=${encodeURI(searchText)}`
                : "user/getallregion"
        )
            .then((res) => {
                setRegionList(res.payload);
            })
            .catch((e) => { });
    };

    /**
     * Resets the state variables related to the map and search when the route changes.
     * @returns None
     */
    const routeChange = () => {
        setMapShow(false);
        setOpenSidebar(false);
        setMapIndex(null);
        setRegionPinsData(null);
        setSearchText("");
        setActiveButton(0);
    };

    /**
     * useEffect hook that updates the sidebar title based on the active button.
     * @param {number} activeButton - the index of the active button
     * @returns None
     */
    useEffect(() => {
        if (activeButton === 0) {
            setSidebarTitle("All");
        } else if (activeButton === 1) {
            setSidebarTitle("Books");
        } else if (activeButton === 2) {
            setSidebarTitle("Shops");
        } else {
            setSidebarTitle("Events");
        }
    }, [activeButton]);

    /**
     * Retrieves the explore region data from the server based on the given key and region ID.
     * @param {string} key - The key to use for the explore region data. If not provided, 'all' is used.
     * @returns None
     */
    const getExploreRegion = async (key) => {
        await AxiosInstance.get(
            search
                ? `user/exploreregion?key=${key ? key : "all"}&regionId=${regionPinsData._id
                }&search=${search}`
                : `user/exploreregion?key=${key ? key : "all"}&regionId=${regionPinsData._id
                }`
        )
            .then((res) => {
                setRegionPinsLists(res.payload);
                setMainpinsList(res.payload);
            })
            .catch((err) => { });
    };

    /**
     * Sets up the attached object with the given tab type, selected type, and active button.
     * @param {string} tabType - The type of tab to attach the object to.
     * @param {string} type - The selected type.
     * @param {string | null | undefined} active - The active button, if any.
     * @returns None
     */
    const setupAtteched = (tabType, type, active) => {
        const atteched = {
            tab: tabType,
            selected: type,
            activeButoon: active !== null && active !== undefined ? active : null,
        };
        setRedirectTab(atteched);
    };

    /**
     * Renders a list of cards for each item in the regionPinsLists array.
     * @returns A JSX element containing the list of cards.
     */
    const MapAllDataShow = () => {
        let localstorageBooks =
            JSON.parse(localStorage.getItem("bookmark_data")) || [];
        return (
            <>
                {regionPinsLists.length === 0 ? (
                    <div className="no_data">{strings["NODATAFOUND"]}</div>
                ) : (
                    regionPinsLists.map((val, index) => {
                        val["bookmark"] = localstorageBooks?.some(
                            (item) => item._id === val._id
                        );
                        val["isFrom"] = false;
                        if (val.key === "Book") {
                            return (
                                <>
                                    <div
                                        className="books_cards"
                                        key={index}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveButton(1);
                                            setMapDetailsSidebar("Book");
                                            setSelectedItem(val);
                                            onSelectFromFilt(val);
                                            setupAtteched("mapWithList", "all", activeButton);
                                        }}
                                    >
                                        <div className="book_card_area">
                                            <div className="left_side">
                                                <img
                                                    src={
                                                        val?.profileImage
                                                            ? val?.profileImage
                                                            : "https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png'"
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="right_side">
                                                <div className="contain">
                                                    <p className="date">
                                                        Published Date:{" "}
                                                        {moment(val?.publishDate).format("DD MMM YYYY")
                                                            ? moment(val?.publishDate).format("DD MMM YYYY")
                                                            : "-"}
                                                    </p>
                                                    <h3 className="book_name">{val?.Title}</h3>
                                                    <h4 className="novel_name">{val?.SubTitle}</h4>
                                                    <h4 className="book_author">
                                                        by {val?.authorName ? val?.authorName : "-"}
                                                    </h4>
                                                    <h3 className="region_name">{val?.regionName}</h3>
                                                </div>
                                                <div className="bookmark_area">
                                                    {
                                                        <span
                                                            className={
                                                                val?.bookmark === true
                                                                    ? "icon-heart-fill"
                                                                    : "icon-fav"
                                                            }
                                                            onClick={(e) => {
                                                                setSelectedBookMark(val);
                                                                if (
                                                                    val?.bookmark &&
                                                                    val?.bookmark !== undefined
                                                                ) {
                                                                    setIsOpen({
                                                                        open: true,
                                                                        isFrom: "list",
                                                                    });
                                                                    // }
                                                                } else {
                                                                    let bookMarkBookArr = JSON.parse(
                                                                        localStorage.getItem("bookmark_data")
                                                                    );
                                                                    const item = val;
                                                                    item.bookmark = true;
                                                                    if (
                                                                        bookMarkBookArr &&
                                                                        bookMarkBookArr.length > 0
                                                                    ) {
                                                                        bookMarkBookArr.push(item);
                                                                        localStorage.setItem(
                                                                            "bookmark_data",
                                                                            JSON.stringify(bookMarkBookArr)
                                                                        );
                                                                    } else {
                                                                        localStorage.setItem(
                                                                            "bookmark_data",
                                                                            JSON.stringify([item])
                                                                        );
                                                                    }
                                                                }
                                                                e.stopPropagation();
                                                                setBookMark(!bookMark);
                                                            }}
                                                        ></span>
                                                    }
                                                    {/* {bookMark && <span className="icon-heart-fill" onClick={(e) => { e.stopPropagation(); setRemoveBookMark(true) }}></span>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        } else if (val.key === "Event") {
                            return (
                                <>
                                    <div
                                        className="events_card"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveButton(3);
                                            setSelectedItem(val);
                                            setMapDetailsSidebar("Event");
                                            onSelectFromFilt(val);
                                            setupAtteched("mapWithList", "all", activeButton);
                                        }}
                                    >
                                        <div className="event_card_area">
                                            <div className="top_side">
                                                <img
                                                    src={
                                                        val.profileImage
                                                            ? val.profileImage
                                                            : "https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg"
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="down_side">
                                                <div className="name_bookmark">
                                                    <div className="event_name">
                                                        <h4>{val.name}</h4>
                                                    </div>
                                                    <div className="bookmark_events">
                                                        {
                                                            <span
                                                                className={
                                                                    val?.bookmark === true
                                                                        ? "icon-heart-fill"
                                                                        : "icon-fav"
                                                                }
                                                                onClick={(e) => {
                                                                    setSelectedBookMark(val);
                                                                    if (
                                                                        val?.bookmark &&
                                                                        val?.bookmark !== undefined
                                                                    ) {
                                                                        setIsOpen({
                                                                            open: true,
                                                                            isFrom: "list",
                                                                        });
                                                                        // }
                                                                    } else {
                                                                        let bookMarkBookArr = JSON.parse(
                                                                            localStorage.getItem("bookmark_data")
                                                                        );
                                                                        const item = val;
                                                                        item.bookmark = true;
                                                                        if (
                                                                            bookMarkBookArr &&
                                                                            bookMarkBookArr.length > 0
                                                                        ) {
                                                                            bookMarkBookArr.push(item);
                                                                            localStorage.setItem(
                                                                                "bookmark_data",
                                                                                JSON.stringify(bookMarkBookArr)
                                                                            );
                                                                        } else {
                                                                            localStorage.setItem(
                                                                                "bookmark_data",
                                                                                JSON.stringify([item])
                                                                            );
                                                                        }
                                                                    }
                                                                    e.stopPropagation();
                                                                    setBookMark(!bookMark);
                                                                }}
                                                            ></span>
                                                        }
                                                        {/* {bookMark && <span className="icon-heart-fill" onClick={(e) => { e.stopPropagation(); setRemoveBookMark(true) }}></span>} */}
                                                    </div>
                                                </div>
                                                <div className="region_month">
                                                    <div className="region_name">
                                                        <p>{val.regionName}</p>
                                                    </div>
                                                    <div className="month_name">
                                                        <p>{val.month}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <div
                                        className="shop_cards"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveButton(2);
                                            setMapDetailsSidebar("Shop");
                                            setSelectedItem(val);
                                            onSelectFromFilt(val);
                                            setupAtteched("mapWithList", "all", activeButton);
                                        }}
                                    >
                                        <div className="contain_area">
                                            <div className="main_contain">
                                                <div className="shop_img">
                                                    <img
                                                        src={
                                                            val.shopImage
                                                                ? val.shopImage
                                                                : "https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811437.svg"
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="shop_text">
                                                    <div className="time">
                                                        {/* <p>Open - {val.createdAt}</p> */}
                                                    </div>
                                                    <div className="shop_name">
                                                        <p>{val.name}</p>
                                                    </div>
                                                    <div className="region_name">
                                                        <p>{val.regionName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="shop_bookmark">
                                                {
                                                    <span
                                                        className={
                                                            val?.bookmark === true
                                                                ? "icon-heart-fill"
                                                                : "icon-fav"
                                                        }
                                                        onClick={(e) => {
                                                            setSelectedBookMark(val);
                                                            if (
                                                                val?.bookmark &&
                                                                val?.bookmark !== undefined
                                                            ) {
                                                                setIsOpen({
                                                                    open: true,
                                                                    isFrom: "list",
                                                                });
                                                                // }
                                                            } else {
                                                                let bookMarkBookArr = JSON.parse(
                                                                    localStorage.getItem("bookmark_data")
                                                                );
                                                                const item = val;
                                                                item.bookmark = true;
                                                                if (
                                                                    bookMarkBookArr &&
                                                                    bookMarkBookArr.length > 0
                                                                ) {
                                                                    bookMarkBookArr.push(item);
                                                                    localStorage.setItem(
                                                                        "bookmark_data",
                                                                        JSON.stringify(bookMarkBookArr)
                                                                    );
                                                                } else {
                                                                    localStorage.setItem(
                                                                        "bookmark_data",
                                                                        JSON.stringify([item])
                                                                    );
                                                                }
                                                            }
                                                            e.stopPropagation();
                                                            setBookMark(!bookMark);
                                                        }}
                                                    ></span>
                                                }
                                                {/* {bookMark && <span className="icon-heart-fill" onClick={() => setRemoveBookMark(true)}></span>} */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    })
                )}
            </>
        );
    };

    /**
     * Displays the details of a selected book, including the book's image, title, author, price, and description.
     * Allows the user to bookmark the book and purchase it through a link.
     * @returns {JSX.Element} - The JSX code to display the book details.
     */
    const BookDetailsData = () => {
        return (
            <>
                <div className="books_details">
                    <div className="details_section">
                        <div className="book_img">
                            <img
                                src={
                                    selectedItem?.profileImage
                                        ? selectedItem?.profileImage
                                        : "https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png"
                                }
                                alt=""
                            />
                        </div>
                        <div className="text_details_n_bookmark">
                            <div className="text_details">
                                <p className="date">
                                    {strings["PUBLISHED"]}:{" "}
                                    {moment(selectedItem.publishDate).format("DD MMM YYYY")}
                                </p>
                                <p className="region_name">{selectedItem?.regionName}</p>
                                <h3 className="book_name">{selectedItem?.Title}</h3>
                                <h4 className="novel_name">{selectedItem?.SubTitle}</h4>
                                <p className="author_name">
                                    by {selectedItem?.authorName ? selectedItem?.authorName : "-"}
                                </p>
                                <p className="price">£{selectedItem?.DefaultPrice}</p>
                            </div>
                            <div className="bookmark_icon">
                                {
                                    <span
                                        className={
                                            selectedItem?.bookmark === true
                                                ? "icon-heart-fill"
                                                : "icon-fav"
                                        }
                                        onClick={(e) => {
                                            if (
                                                selectedItem.bookmark &&
                                                selectedItem.bookmark !== undefined
                                            ) {
                                                setIsOpen({
                                                    open: true,
                                                    isFrom: "details",
                                                });
                                                // }
                                            } else {
                                                let bookMarkBookArr = JSON.parse(
                                                    localStorage.getItem("bookmark_data")
                                                );
                                                const item = selectedItem;
                                                item.bookmark = true;
                                                setSelectedItem(item);
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(item);
                                                    localStorage.setItem(
                                                        "bookmark_data",
                                                        JSON.stringify(bookMarkBookArr)
                                                    );
                                                } else {
                                                    localStorage.setItem(
                                                        "bookmark_data",
                                                        JSON.stringify([item])
                                                    );
                                                }
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark);
                                        }}
                                    ></span>
                                }
                            </div>
                        </div>
                        <div className="book_tag">
                            <p>
                                <span>{selectedItem.genre}</span>
                            </p>
                            <p>
                                <span>{selectedItem.ProductFormDesc}</span>
                            </p>
                        </div>
                        <div className="books_btn">
                            <a
                                href={selectedItem.purchaseURL}
                                target="_blank"
                                className="buy_btn"
                            >
                                {strings["BUY"]}
                            </a>
                            <a
                                className="right_turn_btn"
                                href={`https://www.google.com/maps/?q=${selectedItem.location.coordinates[1]},${selectedItem.location.coordinates[0]}`}
                                target="_blank"
                            >
                                <i className="icon icon-corner-up-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="about_books_section">
                        <div className="about_book">
                            {selectedItem.description ? (
                                <p className="title">{strings["ABOUTBOOK"]}</p>
                            ) : (
                                ""
                            )}
                            <p className="text">
                                {selectedItem?.description
                                    ? parse(parse(selectedItem?.description))
                                    : ""}
                            </p>
                        </div>
                        {/* <div className="about_book_img">
                            <img src={selectedItem.profileImage} alt="" />
                        </div> */}
                        <div className="about_author">
                            <p className="title">{strings["ABOUT_THE_AUTHOR"]}</p>
                            <p className="text">{selectedItem.aboutAuthor}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    /**
     * A functional component that displays details about a shop, including its name, address, phone number, email, website, and description.
     * @returns A JSX element that displays the shop details.
     */
    const ShopDetailsData = () => {
        return (
            <>
                <MetaTags>
                    <title>Discover Scotland | Scotland By-The-Book - Birlinn Ltd</title>
                    <meta
                        name="description"
                        content="Explore Scotland and learn interesting historical facts about each region, browse popular literary works and learn more about their authors."
                    />
                    <meta
                        property="og:title"
                        content="Discover Scotland | Scotland By-The-Book - Birlinn Ltd"
                    />
                </MetaTags>
                <div className="shop_details">
                    <div className="shop_section">
                        <div className="shop_img">
                            <img
                                src={
                                    selectedItem.shopImage
                                        ? selectedItem.shopImage
                                        : "https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811437.svg"
                                }
                                alt=""
                            />
                        </div>
                        <div className="text_details_n_bookmark">
                            <div className="text_details">
                                <p className="region_name">{selectedItem.regionName}</p>
                                <h3 className="shop_name">{selectedItem.name}</h3>
                                <h4 className="shop_add">{selectedItem.address}</h4>
                            </div>
                            <div className="bookmark_icon">
                                {
                                    <span
                                        className={
                                            selectedItem?.bookmark === true
                                                ? "icon-heart-fill"
                                                : "icon-fav"
                                        }
                                        onClick={(e) => {
                                            setSelectedBookMark(selectedItem);
                                            if (
                                                selectedItem.bookmark &&
                                                selectedItem.bookmark !== undefined
                                            ) {
                                                setIsOpen({
                                                    open: true,
                                                    isFrom: "details",
                                                });
                                            } else {
                                                let bookMarkBookArr = JSON.parse(
                                                    localStorage.getItem("bookmark_data")
                                                );
                                                const item = selectedItem;
                                                item.bookmark = true;
                                                setSelectedItem(item);
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(item);
                                                    localStorage.setItem(
                                                        "bookmark_data",
                                                        JSON.stringify(bookMarkBookArr)
                                                    );
                                                } else {
                                                    localStorage.setItem(
                                                        "bookmark_data",
                                                        JSON.stringify([item])
                                                    );
                                                }
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark);
                                        }}
                                    ></span>
                                }
                            </div>
                        </div>
                        <ul>
                            {selectedItem?.serviceTiming?.length > 1 &&
                                selectedItem?.serviceTiming?.map((l) => {
                                    return (
                                        <li>
                                            {l?.day}   {l.status === false ? "closed" : l?.fullDay && l?.status === true ? "24 hours " : (l?.startTime + ' ' + 'to' + ' ' + l?.endTime)}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="shop_tag">
                            <p>
                                <span>{selectedItem.cafe === true ? "Cafe" : "No Cafe"}</span>
                            </p>
                        </div>
                        <div className="shop_btn">
                            {selectedItem.phone ? (
                                <a href="tel:`${selectedItem.phone}`" className="btn_shop">
                                    <i className="icon icon-phone"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {selectedItem.email ? (
                                <a href="mailto:`${selectedItem.email}`" className="btn_shop">
                                    <i className="icon icon-mail"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            <a
                                href={selectedItem.website ? selectedItem.website : ""}
                                target="_blank"
                                className="btn_shop"
                            >
                                <i className="icon icon-globe"></i>
                            </a>
                            <a
                                className="btn_shop"
                                href={`https://www.google.com/maps/?q=${selectedItem.location.coordinates[1]},${selectedItem.location.coordinates[0]}`}
                                target="_blank"
                            >
                                <i className="icon icon-corner-up-right"></i>
                            </a>
                        </div>
                    </div>
                    {selectedItem.description && (
                        <div className="about_shop_section">
                            <div className="about_shop">
                                <p className="title">About Shop</p>
                                <p className="text">
                                    {selectedItem.description
                                        ? parse(parse(selectedItem.description))
                                        : ""}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    /**
     * Renders the details of an event, including the event image, name, region, date, bookmark icon, and buttons to view more details or book the event.
     * @returns {JSX.Element} - The JSX code to render the event details.
     */
    const EventDetailsData = () => {
        return (
            <>
                <div className="event_details">
                    <div className="event_section">
                        <div className="event_img">
                            <img
                                src={
                                    selectedItem.profileImage
                                        ? selectedItem.profileImage
                                        : "https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg"
                                }
                                alt=""
                            />
                        </div>
                        <div className="text_details_n_bookmark">
                            <div className="text_details">
                                <p className="region_name">{selectedItem.regionName}</p>
                                <h3 className="event_name">{selectedItem.name}</h3>
                                <h4 className="event_add">{selectedItem.month}</h4>
                            </div>
                            <div className="bookmark_icon">
                                {
                                    <span
                                        className={
                                            selectedItem?.bookmark === true
                                                ? "icon-heart-fill"
                                                : "icon-fav"
                                        }
                                        onClick={(e) => {
                                            setSelectedBookMark(selectedItem);
                                            if (
                                                selectedItem.bookmark &&
                                                selectedItem.bookmark !== undefined
                                            ) {
                                                setIsOpen({
                                                    open: true,
                                                    isFrom: "details",
                                                });
                                            } else {
                                                let bookMarkBookArr = JSON.parse(
                                                    localStorage.getItem("bookmark_data")
                                                );
                                                const item = selectedItem;
                                                item.bookmark = true;
                                                setSelectedItem(item);
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(item);
                                                    localStorage.setItem(
                                                        "bookmark_data",
                                                        JSON.stringify(bookMarkBookArr)
                                                    );
                                                } else {
                                                    localStorage.setItem(
                                                        "bookmark_data",
                                                        JSON.stringify([item])
                                                    );
                                                }
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark);
                                        }}
                                    ></span>
                                }
                            </div>
                        </div>
                        <div className="event_btn">
                            <a
                                className="text_btn"
                                href={selectedItem.website}
                                alt=""
                                target="_blank"
                            >
                                More Details
                            </a>
                            <a
                                className="btn_event"
                                href={`https://www.google.com/maps/?q=${selectedItem.location.coordinates[1]},${selectedItem.location.coordinates[0]}`}
                                target="_blank"
                            >
                                <i className="icon icon-corner-up-right"></i>
                            </a>
                        </div>
                    </div>
                    {selectedItem.description && (
                        <div className="about_event_section">
                            <div className="about_event">
                                <p className="title">{strings["ABOUT_EVENT"]}</p>
                                <p className="text">
                                    {selectedItem?.description
                                        ? parse(parse(selectedItem?.description))
                                        : ""}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="discovermain" style={{ height: dimension.height + "px" }}>
                {/* onClick={() =>{ openSidebar && setOpenSidebar(false); mapIndex !== null && setMapIndex(null); }} */}
                <div className={`discoverright ${mapShow ? "dnone" : ""}`}>
                    <div
                        className={`searchbar_area ${searchBarList === true ? "style_list" : ""
                            }`}
                    >
                        <SearchBox
                            placeholder={strings["SEARCH_REGION_BY_NAME"]}
                            value={searchText}
                            onClear={(e) => {
                                e.preventDefault();
                                setSearchText("");
                                setOpenSidebar(false);
                                setMapIndex(null);
                                setSearchBarList(false);
                            }}
                            onSearch={(e) => {
                                setSearchText(e);
                            }}
                            onClick={() => {
                                // setSearchText("");
                                setSearchBarList(true);
                            }}
                            autocomplete={"off"}
                        />
                        <ul className={`${searchBarList === true ? "list_show" : ""}`}>
                            {regionList
                                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                                ?.map((value) => {
                                    return (
                                        <li
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSearchText(value?.name);
                                                setSearchBarList(false);
                                                setOpenSidebar(false);
                                            }}
                                        >
                                            {" "}
                                            {value?.name}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                    <TransformWrapper ref={zoomRef} maxScale={3}>
                        <div
                            className={`${mapIndex >= 0 ? "map_zoom_out-" + mapIndex : ""} ${searchText == false ? "zoom_out_map_scale" : ""
                                }`}
                        >
                            <TransformComponent
                                wrapperClass={
                                    window.localStorage.getItem("byAddingClass") &&
                                    `${window.localStorage.getItem("byAddingClass")}`
                                }
                            >
                                <div
                                    className="map_area"
                                    style={{
                                        height: dimension.height - searchbar_areaHeight + "px",
                                    }}
                                >
                                    <div
                                        className={`map_img ${mapIndex >= 0 ? "pin-" + mapIndex : ""
                                            }`}
                                    >
                                        <Map
                                            style={{
                                                height: dimension.height - searchbar_areaHeight + "px",
                                            }}
                                            colorIndex={mapIndex}
                                        />
                                        {regionList.map((val, index) => {
                                            return (
                                                <a
                                                    key={`val-${index}`}
                                                    className={val.index}
                                                    href="javascript:;"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenSidebar(true);
                                                        setMapIndex(val.index);
                                                        setVideoURL(val.video);
                                                        setRegionPinsData(val);
                                                        setSearchText(val.name);
                                                    }}
                                                >
                                                    <img key={index} src={val.pin} alt="pin" />{" "}
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            </TransformComponent>
                        </div>
                    </TransformWrapper>
                    <button className="info_text" onClick={() => setInfoModalShow(true)}>
                        <i className="icon icon-info"></i>
                    </button>
                </div>
                {mapShow && (
                    <div className={`gmap ${mapShow ? "googlemap_area" : ""}`}>
                        <GoogleMap
                            marker={regionPinsLists}
                            latitude={
                                selectedItem
                                    ? selectedItem?.location.coordinates[1]
                                    : regionPinsData.location.coordinates[1]
                            }
                            longitude={
                                selectedItem
                                    ? selectedItem?.location.coordinates[0]
                                    : regionPinsData.location.coordinates[0]
                            }
                            onMapClick={() => {
                                if (redirectTab != null) {
                                    if (redirectTab.tab === "landDetails") {
                                        setOpenSidebar(false);
                                        setMapShow(false);
                                        setMapDetailsSidebar(false);
                                        setSearchText("");
                                        setMapIndex(null);
                                    } else {
                                        setMapDetailsSidebar("all");
                                        setActiveButton(redirectTab.activeButoon);
                                        // setMapShow(false)
                                        const btn = redirectTab.activeButoon;
                                        const typeSend =
                                            btn === 0
                                                ? "all"
                                                : btn === 1
                                                    ? "books"
                                                    : btn === 2
                                                        ? "shops"
                                                        : "events";
                                        getExploreRegion(typeSend);
                                    }
                                }
                            }}
                            onClickMarker={(item) => {
                                if (item.key == "Book") {
                                    setActiveButton(1);
                                    setMapDetailsSidebar("Book");
                                    setSelectedItem(item);
                                    onSelectFromFilt(item);
                                    setupAtteched("mapWithList", "all", activeButton);
                                } else if (item.key == "Event") {
                                    setActiveButton(3);
                                    setSelectedItem(item);
                                    setMapDetailsSidebar("Event");
                                    onSelectFromFilt(item);
                                    setupAtteched("mapWithList", "all", activeButton);
                                } else if (item.key == "Shop") {
                                    setActiveButton(2);
                                    setMapDetailsSidebar("Shop");
                                    setSelectedItem(item);
                                    onSelectFromFilt(item);
                                    setupAtteched("mapWithList", "all", activeButton);
                                }
                                // setActiveButton(1)
                                // setMapDetailsSidebar('Book')
                                // setSelectedItem(item)
                                // onSelectFromFilt(item)
                                // setupAtteched('mapWithList', 'all', activeButton)
                            }}
                        />
                        {mapDeatilsSidebar === "all" && (
                            <div className="map_top_bar">
                                <div className="btn_searchbar">
                                    <div className="back_region_btn" onClick={routeChange}>
                                        <span className="back_arrow">
                                            <i className="icon icon-chevron-left"></i>
                                        </span>
                                        <div className="text_area">
                                            <p>{regionList[0]?.name}</p>
                                            <p>{strings["CLICK_TO_CHANGE_REGION"]}</p>
                                        </div>
                                    </div>
                                    <div className="search_bar">
                                        <SearchBox
                                            placeholder={`Search by ${activeButton === 0
                                                ? "book, event or shop name"
                                                : activeButton === 1
                                                    ? "Search by book title, author, genre or region"
                                                    : activeButton === 2
                                                        ? "Shop"
                                                        : activeButton === 3
                                                            ? "events"
                                                            : ""
                                                } `}
                                            value={search}
                                            onClear={() => {
                                                setSearch("");
                                            }}
                                            onSearch={(e) => setSearch(e)}
                                        />
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className={`map_filter_btn`}>
                                        <div
                                            onClick={() => {
                                                setActiveButton(0);
                                                getExploreRegion("all");
                                            }}
                                            className={`map_btn ${activeButton === 0 ? "active" : ""
                                                }`}
                                        >
                                            <span>{strings["ALL"]}</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setActiveButton(1);
                                                getExploreRegion("books");
                                            }}
                                            className={`map_btn books ${activeButton === 1 ? "active" : ""
                                                }`}
                                        >
                                            <i className="icon icon-books"></i>
                                            <span>{strings["BOOKS"]}</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setActiveButton(2);
                                                getExploreRegion("shops");
                                            }}
                                            className={`map_btn shops ${activeButton === 2 ? "active" : ""
                                                }`}
                                        >
                                            <i className="icon icon-shop"></i>
                                            <span>{strings["SHOPS"]}</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setActiveButton(3);
                                                getExploreRegion("events");
                                            }}
                                            className={`map_btn events ${activeButton === 3 ? "active" : ""
                                                }`}
                                        >
                                            <i className="icon icon-events"></i>
                                            <span>{strings["EVENTS"]}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(mapDeatilsSidebar === "Book" ||
                            mapDeatilsSidebar === "Shop" ||
                            mapDeatilsSidebar === "Event") && (
                                <div className="map_top_back">
                                    <div
                                        className="back_btn"
                                        onClick={() => {
                                            if (redirectTab !== null) {
                                                if (redirectTab.tab === "landDetails") {
                                                    setOpenSidebar(false);
                                                    setMapShow(false);
                                                    setMapDetailsSidebar(false);
                                                    setSearchText("");
                                                    setMapIndex(null);
                                                } else {
                                                    setMapDetailsSidebar("all");
                                                    setActiveButton(redirectTab.activeButoon);
                                                    // setMapShow(false)
                                                    const btn = redirectTab.activeButoon;
                                                    const typeSend =
                                                        btn === 0
                                                            ? "all"
                                                            : btn === 1
                                                                ? "books"
                                                                : btn === 2
                                                                    ? "shops"
                                                                    : "events";
                                                    getExploreRegion(typeSend);
                                                }
                                            } else {
                                                history(location.state.redirectFrom, {
                                                    state: {
                                                        type: location.state.type,
                                                        detailType: location.state.detailType,
                                                        trageted: location.state.trageted,
                                                        mainTargeted: location.state.mainTargeted,
                                                    },
                                                });
                                            }
                                            // history('/directory', {
                                            //     state: { redirectFrom: location.state?.location }
                                            // })
                                        }}
                                    >
                                        <span>
                                            <i className="icon icon-chevron-left"></i>
                                        </span>
                                        <p>{strings["BACK"]}</p>
                                    </div>
                                </div>
                            )}
                    </div>
                )}
                {openSidebar && (
                    <div
                        className={`regions_details ${openSidebar ? "open" : ""} ${mapShow ? "style_map" : ""
                            }`}
                        style={{ height: dimension.height + "px" }}
                    >
                        {!mapShow && (
                            <RightSideMenu
                                iconClose={() => {
                                    openSidebar && setOpenSidebar(false);
                                    setMapIndex(null);
                                    setSearchText("");
                                    zoomRefContainer();
                                }}
                                modelShow={() => {
                                    setShow(true);
                                }}
                                mapShow={() => {
                                    setMapDetailsSidebar("all");
                                    setMapShow(true);
                                    getExploreRegion();
                                }}
                                pinsData={regionPinsData}
                                bookMark={bookMark}
                                setSidebarBookMark={(value) => {
                                    setBookMark(true);
                                }}
                                setRemoveSidebarBookMark={(value) => {
                                    setIsOpen({ open: true });
                                }}
                                DetailsBook={(val, da) => {
                                    setMapShow(true);
                                    setMapDetailsSidebar(true);
                                    setActiveButton(0);
                                    setSelectedItem(val);
                                    setRegionPinsData(val);
                                    setMapDetailsSidebar("Book");
                                    setSidebarTitle("Books");
                                    setOpenSidebar(true);
                                    onSelectFromFilt(val);
                                    setupAtteched("landDetails", mapIndex);
                                }}
                            />
                        )}
                        {mapShow && (
                            <MapRightSideMenu title={sidebarTitle}>
                                <>
                                    {mapDeatilsSidebar === "all" && (
                                        <div className="alldatashow">
                                            <MapAllDataShow />
                                        </div>
                                    )}
                                    {mapDeatilsSidebar === "Book" && (
                                        <div className="bookdeatilsdata">
                                            <BookDetailsData />
                                        </div>
                                    )}
                                    {mapDeatilsSidebar === "Shop" && (
                                        <div className="shopdetailsdata">
                                            <ShopDetailsData />
                                        </div>
                                    )}
                                    {mapDeatilsSidebar === "Event" && (
                                        <div className="eventdetailsdata">
                                            <EventDetailsData />
                                        </div>
                                    )}
                                </>
                            </MapRightSideMenu>
                        )}
                    </div>
                )}
            </div>

            <ModalPopup
                className="info_modal"
                showModal={infoModalShow}
                closeButton={true}
                onHide={() => {
                    setInfoModalShow(false);
                }}
            >
                <div className="info_text_area">
                    <div className="close_icon">
                        <span
                            className="icon-x"
                            onClick={() => {
                                setInfoModalShow(false);
                            }}
                        ></span>
                    </div>
                    <p>Travel Scotland by book and prepare for a serious case of wanderlust! Explore the country through the pages of your favourite novels, guidebooks, memoirs, histories and more.</p>
                    <p>
                        <strong>How to Use:</strong>
                    </p>
                    <ul>
                        <li>
                            Browse Scotland by region, explore books tied to specific landmarks and find out more about each area.
                        </li>
                        <li>
                            Explore the map to discover book recommendations, bookshops to visit and festivals to attend.
                        </li>
                        <li>
                            Within a region, use the filter buttons to hone your search.
                        </li>
                        <li>
                            Add specific books, shops and events to your favourites so you can return to them any time.
                        </li>
                        <li>
                            Visit the directory to view our full list of books, bookshops, and events.
                        </li>
                        <li>
                            Click on individual pins to find out more information, buy books online, download ebooks and get directions to specific shops and festival venues.
                        </li>
                    </ul>
                    <p>Books are waiting here to inspire and inform the thoughtful explorer, the traveller who wants to scratch beneath the surface– you!</p>
                </div>
            </ModalPopup>

            <ConfirmModal
                leftBtnTitle={strings["ONLYYES"]}
                rightBtnTitle={strings["ONLYNO"]}
                modalTitle={strings["REMOVEBOOKMARK"]}
                modalDescription={strings["SUREREMOVEBOOKMARK"]}
                showModal={isOpen?.open}
                onPressLeft={onPressRemoveBookMark}
                onPressRight={() =>
                    // setRemoveBookMark(false)
                    setIsOpen({ open: false })
                }
                className="remove_bookmark_popup"
            />

            <Toast className="bookmark_tost" show={toastBookMark} delay={3000}>
                <div className="tostcenter">
                    <span className="tost_icon">
                        <i className="icon icon-coolicon"></i>
                    </span>
                    <p className="bookmark_tost_text">
                        {strings["THE_BOOKMARK_REMOVE_SUCCESSFULLY"]}
                    </p>
                </div>
            </Toast>

            <Modal
                show={show}
                onHide={handleClose}
                animation={false}
                className="videopop"
            >
                <Modal.Body>
                    {/* {RegionsPins[mapIndex].VideoURL} */}
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoURL}
                        title="YouTube video player"
                        frameborder="0"
                        target="_blank"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                    <button
                        type="button"
                        className="btn-close btn-close-white"
                        aria-label="Close"
                    ></button>
                </Modal.Body>
            </Modal>
        </>
    );
};
/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
    return {};
};

/**
 * An empty object that maps dispatch functions to props in a React component.
 * @returns An empty object.
 */
const mapDispatchToProps = {};

/**
 * Connects the DiscoverScreen component to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state from the Redux store to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns A higher-order component that has access to the Redux store and multilanguage props.
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(DiscoverScreen));
