import { SHOW_TOAST, HIDE_TOAST } from "../actions/toastAction";

/**
 * The initial state of the application, containing a toast object with show, message, and type properties.
 * @type {Object}
 */
const initState = {
    toast: {
        show: false,
        message: '',
        type: ''
    }

};

/**
 * A reducer function that handles the state of the toast notification.
 * @param {Object} state - The current state of the toast notification.
 * @param {Object} action - The action object that contains the type and payload.
 * @returns The new state of the toast notification.
 */
const toastReducer = (state = initState, action) => {
    if (action.type === SHOW_TOAST) {
        return {
            ...state,
            toast: { show: true, ...action.payload }
        };
    }
    if (action.type === HIDE_TOAST) {
        return {
            ...state,
            toast: initState.toast
        };
    }

    return state;
};

export default toastReducer;
