import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { LayoutRoute } from "./../routes";
import useWindowDimension from "./../hooks/useWindowDimension";
import Header from "./Header/Header";
import Constant from "../state/utils/constant";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import "./index.scss";

/**
 * A layout component that renders the header and routes based on the current location.
 * @param {object} props - The props object containing the properties to pass down to child components.
 * @returns The layout component.
 */
const Layout = (props) => {
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const dimensions = useWindowDimension();

  /**
   * useEffect hook that checks if the user is logged in by checking if an access token
   * is stored in local storage. Sets the state of isLogin accordingly.
   * @returns None
   */
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    setIsLogin(isLoggedIn);
  }, []);


  /**
   * Authenticates the current route by checking if the user is logged in and if the route exists in the LayoutRoute array.
   * If the user is not logged in or the route does not exist, the user is redirected to the home page.
   * @returns {JSX.Element} - The component to render for the authenticated route.
   */
  const authenticateRoute = () => {
    let { pathname } = location;
    let routeIndex = LayoutRoute.findIndex(route => route.path === pathname);
    // let isLoggedIn = localStorage.getItem('access_token') ? true : false;
    if (pathname !== "/" && routeIndex !== -1) {
      const Component = LayoutRoute[routeIndex].component;
      return isLogin ? Component : <Navigate replace to="/" />;
    } else {
      return <PageNotFound />
    }
  }
  return (
    <div className="main" style={{ height: dimensions.height, width: dimensions.width }}    >
      {/* <img src={Constant.IMAGESURL.BANNER} className="logoBack" title="" alt="" /> */}
      <Header {...props} />
      <Routes>
        {LayoutRoute.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={route.component}
            />
          );
        })}
      </Routes>
    </div>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

/**
 * Connects the Layout component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * @param {Function} mapStateToProps - A function that maps the state from the Redux store to props for the Layout component.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch function from the Redux store to props for the Layout component.
 * @returns A higher-order component that wraps the Layout component and connects it to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
