import { useEffect, useState } from 'react';

/**
 * A custom React hook that returns the current dimensions of the window and its orientation.
 * @returns An object containing the height, width, and orientation of the window.
 */
export default function useWindowDimension() {
    const [dimensions, setDimension] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
        orientation: window.matchMedia('(orientation:landscape)').matches
    });

    /**
     * Updates the state with the current dimensions of the window.
     * @returns None
     */
    function handleResize() {
        setDimension({
            height: window.innerHeight,
            width: window.innerWidth,
            orientation: window.matchMedia('(orientation:landscape)').matches
        });
    }

    /**
     * Adds an event listener to the window object that listens for a resize event and calls
     * the handleResize function when the event is triggered. The event listener is removed
     * when the component unmounts.
     * @returns None
     */
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () =>
            window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Adds an event listener to the window object that listens for changes in device orientation.
     * When the orientation changes, the handleResize function is called.
     * @returns None
     */
    useEffect(() => {
        window.addEventListener('orientationchange', handleResize);
        return () =>
            window.removeEventListener('resize', handleResize);
    }, []);

    return dimensions;
}
