import React, { useRef } from 'react';

import './SearchBox.scss';

/**
 * A functional component that renders a search box with a search icon and clear button.
 * @param {Object} props - The props object containing the following properties:
 *   - placeholder: The placeholder text to display in the search box.
 *   - onSearch: A callback function to be called when the user types in the search box.
 *   - onClear: A callback function to be called when the user clicks the clear button.
 *   - value: The current value of the search box.
 *   - onClick: A callback function to be called when the user clicks on the search box.
 *   - autocomplete: A boolean indicating whether or not to enable autocomplete on the search box.
 *   - children: Any child components
 */
const SearchBox = (props) => {
  let { placeholder, onSearch, onClear, value } = props

  return (
    <div className="searchBoxDiv">
      <input type="text" name="focus" onClick={props.onClick} className="searchInput" value={value} placeholder={placeholder}
        onChange={(e) => { onSearch && onSearch(`${e.target.value}`) }} autocomplete={props.autocomplete} />
      {
        (value !== undefined && value !== '') &&
        <button className="icon icon-x" type="reset" onClick={(e) => { onClear && onClear(e); }}></button>
      }
      <div className='search-icon'>
        <i className="icon icon-search" />
      </div>
      {props.children}
    </div >
  );
}

export default SearchBox;
