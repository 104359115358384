import React from 'react';
import Dropzone from 'react-dropzone';

/**
 * A component that allows for file uploads. 
 * @param {Object} props - The props object.
 * @param {boolean} props.multiple - Whether or not multiple files can be uploaded.
 * @param {function} props.onDrop - The function to call when a file is dropped.
 * @param {string} props.error - An error message to display if there is an error.
 * @param {function} props.setRef - A function to set the reference to the dropzone.
 * @param {string} props.accept - The file types that are accepted.
 * @returns A component that allows for file uploads.
 */
const FileUpload = (props) => {
    let { multiple, onDrop, error, setRef, accept } = props;
    return (
        <>
            <>
                <div className={`dropzoneCustom ${error ? 'error' : ''}`}>
                    <Dropzone accept={accept} useFsAccessApi={multiple} onDrop={acceptedFiles => { onDrop && onDrop(acceptedFiles) }} ref={setRef}>
                        {({ getRootProps, getInputProps }) => {
                            return <div className="txtDropzone" {...getRootProps()}>
                                <input {...getInputProps()} multiple={multiple} accept={accept} />
                                {props.children}
                            </div>
                        }}
                    </Dropzone>
                </div>
            </>
            {error ? <div className="errorTxtFile"><span className="joyclub-Info"></span><p>{error}</p></div> : null}
        </>
    )
}

/**
 * Default props for the FileUpload component.
 * @param {boolean} hasLabel - Whether or not the component should have a label.
 * @param {boolean} btnVisible - Whether or not the component's button should be visible.
 */
FileUpload.defaultProps = {
    hasLabel: true,
    btnVsible: false
}

export default FileUpload;