import React, { useState, useEffect } from "react";
import "./CheckBox.scss";

/**
 * A checkbox component that can be used in React applications.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.labelTitle - The title of the checkbox label.
 * @param {string} props.id - The id of the checkbox.
 * @param {function} props.onCheckedChange - A callback function that is called when the checkbox is checked or unchecked.
 * @param {function} props.register - A function that registers the checkbox with a form.
 * @param {string} props.name - The name of the checkbox.
 * @param {boolean} props.disabled - A boolean indicating whether the checkbox is disabled.
 * @param {boolean} props.checked - A boolean indicating
 */
const CheckBox = (props) => {
  let { labelTitle, id, onCheckedChange, register, name, disabled } = props;
  const [checked, setChecked] = useState(!!props.checked);

  /**
   * useEffect hook that updates the state of the checkbox when the checked prop changes.
   * @param {object} props - The props object containing the checked prop.
   * @returns None
   */
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  /**
   * Updates the state of the checkbox and calls the onCheckedChange function if it exists.
   * @param {Event} e - the event object
   * @returns None
   */
  const onChecked = (e) => {
    setChecked(e.target.checked);
    onCheckedChange && onCheckedChange(e.target.checked);
  };

  return (
    <div className="checkboxMain" key={props.keyId ? props.keyId : "checkbox"}>
      <input
        type="checkbox"
        ref={register}
        disabled={disabled}
        name={name}
        value={labelTitle}
        id={id}
        className="checkbox"
        checked={checked}
        onChange={onChecked}
      />
      <label className="" id="checkLabel" htmlFor={id}>
        {!checked ? (
          <i className="icon-square" />
        ) : props.checkedIcon ? (
          <i className={`${props.checkedIcon} checkedIcon`} />
        ) : (
          <i className="icon-check-square-top" />
        )}
        <span>{labelTitle}</span>
      </label>
    </div>
  );
};

/**
 * Default props for the CheckBox component.
 * @param {string} id - The id of the checkbox.
 * @param {boolean} checked - Whether the checkbox is checked or not.
 * @param {string} labelTitle - The title of the label associated with the checkbox.
 */
CheckBox.defaultProps = {
  id: "",
  checked: false,
  labelTitle: "",
};

export default CheckBox;
