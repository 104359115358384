/* eslint-disable no-undef */
import React, { useState } from "react";
import { multilanguage } from "redux-multilanguage";
import Constant from "../../../state/utils/constant";
import { Link, useNavigate } from "react-router-dom";
import HookForm from "../../../assets/components/HookForm/HookForm";
import TextField from "../../../assets/components/UI/TextField/TextField";
import CustomButton from "../../../assets/components/UI/CustomButton/CustomButton";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { showToast } from "../../../redux/actions/toastAction";
import { connect } from "react-redux";
import AxiosInstance from "../../../services/axios";

import "./ResetPassword.scss";

/**
 * A component that renders a form for resetting a user's password.
 * @param {Object} props - The props object.
 * @param {Object} props.strings - An object containing localized strings.
 * @returns The ResetPassword component.
 */
const ResetPassword = (props) => {
  let { strings } = props;
  const dimensions = useWindowDimension();
  let history = useNavigate();

  const [busy, setBusy] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const linkToken = urlParams.get("token");

  /**
   * An object containing the configuration for resetting a password form.
   * The object contains two properties, password and confirmPassword, each with their own
   * validation rules.
   * @property {object} password - An object containing the validation rules for the password field.
   * @property {string} password.name - The name of the password field.
   * @property {object} password.validate - An object containing the validation rules for the password field.
   * @property {object} password.validate.required - An object containing the required validation rule for the password field.
   * @property {boolean} password.validate.required.value - A boolean indicating whether the password field is required.
   * @property {string} password.validate.required.message - The error message to display if the password
   */
  const ResetForm = {
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: strings["ENTER_PASSWORD"],
        },
        validate: {
          length: (value) => (value && value.length >= 8) || "",
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) !== null,
          hasUppercase: (value) =>
            value && value.match(Constant.REGEX.UPPERCASE) !== null,
        },
      },
    },
    confirmPassword: {
      name: "confirmPassword",
      validate: {
        required: {
          value: true,
          message: strings["ENTER_CONFIRM_PASSWORD"],
        },
      },
    },
  };

  const multiErrorFields = [
    { length: strings["MINIMUM8CHARACTERS"] },
    { hasSpecialChar: strings["MINIMUM1SPECIALCHARACTER"] },
    { hasNumbers: strings["MINIMUM1NUMERICCHARACTER"] },
    { hasUppercase: strings["MINIMUM1UPPERCASECHARACTER"] },
  ];

  /**
   * Handles the submission of a form to reset a user's password.
   * @param {Object} data - The data submitted in the form.
   * @returns None
   */
  const onFormSubmit = async (data) => {
    setBusy(true);
    await AxiosInstance.post(
      `/admin/resetpassword/${linkToken.replaceAll(" ", "+")}`,
      {
        password: data.confirmPassword,
      }
    ).then((res) => {
      props.showToast({
        message: res.message,
        type: "success",
      });
    });
    history("/admin/login");
    setBusy(false);
  };
  return (
    <div className="signinMain" style={{ height: dimensions.height + "px" }}>
      <img
        style={{ height: dimensions.height }}
        src={Constant.IMAGESURL.BANNER}
        className="logoBack"
        title=""
        alt=""
      />
      <div className="signinLeft">
        <div className="leftLogo">
          <div>
            <img
              className="titlelogo"
              src={Constant.IMAGESURL.TITLEIMG}
              title=""
              alt=""
            />
            <img
              src={Constant.IMAGESURL.LOGO}
              className="logo"
              title=""
              alt=""
            />
            <img
              src={Constant.IMAGESURL.LASTITLEIMG}
              className="lastlogo"
              title=""
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="signinRight" style={{ height: dimensions.height + "px" }}>
        <div className="whiteBox">
          <img
            className="logo"
            src={Constant.IMAGESURL.KEYIMG}
            title=""
            alt=""
          />
          <p className="alignCenter">
            <Link to="/login">
              <i className="icon icon-arrow-left"></i>
            </Link>
          </p>
          <h1>{strings["RESETPASSWORD"]}</h1>
          <p>{strings["PLEASEPROVIDERESETPASSWORD"]}</p>
          <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
            {(formMethod) => {
              return (
                <>
                  <TextField
                    formMethod={formMethod}
                    rules={ResetForm.password.validate}
                    multiErrorFields={multiErrorFields}
                    name={ResetForm.password.name}
                    errors={formMethod?.formState?.errors}
                    type={showPass ? "text" : "password"}
                    placeholder={strings["PASSWORD"]}
                    iconClass={showPass ? "eye" : "eye-off"}
                    onIconClick={() => setShowPass(!showPass)}
                    onChange={(e) => {
                      let value = e.target.value;
                      e.target.value = value.replaceAll(" ", "");
                      formMethod.setValue(
                        ResetForm.password.name,
                        e.target.value
                      );
                      formMethod.watch(ResetForm.confirmPassword.name) &&
                        formMethod.trigger(ResetForm.confirmPassword.name);
                    }}
                    leftIconName={"lock"}
                  />
                  <TextField
                    formMethod={formMethod}
                    rules={{
                      required: {
                        value: true,
                        message: strings["ENTER_CONFIRM_PASSWORD"],
                      },
                      validate: {
                        matchPassword: (value) =>
                          value === formMethod.watch(ResetForm.password.name) ||
                          formMethod.watch(ResetForm.password.name) === "" ||
                          strings["BOTHTHEPASSWORDSMUSTMATCH"],
                      },
                    }}
                    name={ResetForm.confirmPassword.name}
                    errors={formMethod?.formState?.errors}
                    type={showNewPass ? "text" : "password"}
                    placeholder={strings["CONFIRMPASSWORD"]}
                    iconClass={showNewPass ? "eye" : "eye-off"}
                    onIconClick={() => setShowNewPass(!showNewPass)}
                    onChange={(e) => {
                      let value = e.target.value;
                      e.target.value = value.replaceAll(" ", "");
                      formMethod.setValue(
                        ResetForm.confirmPassword.name,
                        e.target.value
                      );
                    }}
                    leftIconName={"lock"}
                  />
                  <CustomButton
                    type="submit"
                    title={strings["RESET"]}
                    disabled={!formMethod?.formState.isValid}
                    loading={busy}
                    className="custombtnfield"
                  />
                </>
              );
            }}
          </HookForm>
        </div>
      </div>
    </div>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * An object containing the `showToast` action creator function, which can be used to dispatch
 * a `showToast` action to the Redux store.
 * @returns An object with the `showToast` action creator function.
 */
const mapDispatchToProps = { showToast };

/**
 * Connects the ResetPassword component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * Also wraps the component with the multilanguage higher-order component to provide internationalization support.
 * @param {Function} mapStateToProps - A function that maps the state properties to the component props.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch actions to the component props.
 * @returns A new component that is connected to the Redux store and has multilanguage support.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(ResetPassword));
