import React from 'react';
import { Fade, Modal } from 'react-bootstrap';
import './ModalPopup.scss';

/**
 * A reusable modal popup component that can be used to display content in a modal.
 * @param {Object} props - The props object containing the following properties:
 *   @param {boolean} showModal - A boolean indicating whether or not to show the modal.
 *   @param {string} className - A string containing the class names to apply to the modal.
 *   @param {Function} onHide - A function to call when the modal is hidden.
 *   @param {string} modalHeader - A string containing the class names to apply to the modal header.
 *   @param {string} modalTitle - A string containing the title of the modal.
 *   @param {boolean} closeIcon - A boolean indicating whether
 */
const ModalPopup = (props) => {

  return (
    <Modal
      className={'modal ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      <Modal.Header className={props.modalHeader} onHide={props.onHide}>
        {props.modalTitle &&
          <p>{props?.modalTitle}</p>
        }
        {props.closeIcon &&
          <i className="icon icon-x" onClick={() => { props.onClose() }}></i>
        }
      </Modal.Header>
      {props?.children}
    </Modal>
  )
}

ModalPopup.defaultProps = {
  className: 'modal',
}

export default (ModalPopup);