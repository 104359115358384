// import WebService from '../../util/webService';
// import constant from '../../util/constant';
export const SET_LOADER = "SET_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

/**
 * Sets the loader state to the given value.
 * @param {boolean} isValue - The value to set the loader state to.
 * @returns A function that dispatches an action to set the loader state.
 */
export const setLoader = (isValue) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: isValue,
        });
    };
};

/**
 * Dispatches an action to show the loader.
 * @returns A function that dispatches an action to the Redux store.
 */
export const showLoader = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADER,
        });
    };
};

/**
 * Action creator that dispatches an action to hide the loader.
 * @returns A function that takes in a dispatch function and dispatches an action to hide the loader.
 */
export const hideLoader = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_LOADER,
        });
    };
};
