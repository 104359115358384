import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';


/**
 * A functional component that renders a different type of marker on the map based on the mapKey prop.
 * @param {Object} props - The props object containing the mapKey, data, onClickMarker, and any other necessary props.
 * @returns A span element with an image of a marker that can be clicked on to trigger an event.
 */
const AnyReactComponent = (props) => {
  if (props?.mapKey === 'Book')
    return <span
      className="gmap_pins"
      onClick={(e) => {
        if (props.data.isFrom === false) {
          props.onClickMarker(props.data)
        } else {
        }
      }}
    > <img style={{ marginTop: '-50px', marginLeft: '-20px' }} src="https://res.cloudinary.com/zudu/image/upload/v1649915259/Birlinn/App/Project-images/books.svg" /> </span>
  else if (props?.mapKey === 'Shop') {
    return <span
      className="gmap_pins"
      onClick={(e) => {
        if (props.data.isFrom === false) {
          props.onClickMarker(props.data)
        }
      }}>
      <img style={{ marginTop: '-50px', marginLeft: '-20px' }} src="https://res.cloudinary.com/zudu/image/upload/v1649915259/Birlinn/App/Project-images/shops.svg" /> </span>
  } else if (props?.mapKey === 'Event') {
    return <span
      className="gmap_pins"
      onClick={(e) => {
        if (props.data.isFrom === false) {
          props.onClickMarker(props.data)
        }
      }}>
      <img style={{ marginTop: '-50px', marginLeft: '-20px' }} src="https://res.cloudinary.com/zudu/image/upload/v1649915259/Birlinn/App/Project-images/event.svg" /> </span>
  }
}

/**
 * A component that displays a Google Map with markers.
 * @param {Object} props - The props object.
 * @param {number} props.latitude - The latitude of the center of the map.
 * @param {number} props.longitude - The longitude of the center of the map.
 * @param {Array} props.marker - An array of marker objects to display on the map.
 * @param {Function} props.onMapClick - A function to call when the map is clicked.
 * @param {Function} props.onClickMarker - A function to call when a marker is clicked.
 * @returns A React component that displays a Google Map with markers.
 */
export default function GoogleMap(props) {
  const [center, setCenter] = useState({ lat: 0, lng: 0 })

  useEffect(() => {
    setCenter({ lat: props.latitude, lng: props.longitude })
  }, [props.marker])
  return (
    <div style={{ height: '100vh', width: '100%' }} >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDYiRK54loDXDLRIxB9nl5R8fvSozD2PPM&v=3" }}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        defaultZoom={7}
        center={center}
        onClick={() => props.onMapClick()}
      >
        {props.marker.map((item, index) => {
          item['isFrom'] = true
          return (
            <AnyReactComponent
              key={index}
              lat={item.location?.coordinates[1]}
              lng={item.location?.coordinates[0]}
              mapKey={item.key}
              data={item}
              onClickMarker={props.onClickMarker}
            />
          )
        })}
      </GoogleMapReact>
    </div>
  );
}
