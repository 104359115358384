import React from "react";
import birlinnLoader from "././../assets/images/birlinnLoader.gif";
import "./Loader.scss";


/**
 * A functional component that renders a loader if the show prop is true.
 * @param {boolean} show - A boolean value that determines whether or not to show the loader.
 * @returns {JSX.Element | null} - Returns a JSX element of the loader or null if show is false.
 */
const Loader = ({ show }) => {
    return show ? (
        <div className="loaderContainer">
            <div className="innerDiv">
                <img className="spinnerGif" src={birlinnLoader} alt="" />
            </div>
        </div>
    ) : null;
};

Loader.defaultProps = {};

export default Loader;
