import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import rootReducer from './redux/reducers/rootReducer';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Creates a Redux store with the given root reducer, initial state, and middleware.
 * @param {Function} rootReducer - The root reducer function for the store.
 * @param {Object} load - The initial state of the store.
 * @param {Function} composeWithDevTools - A function that composes the store with the Redux DevTools extension.
 * @param {Function} applyMiddleware - A function that applies middleware to the store.
 * @returns The created Redux store.
 */
const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
