import React, { useEffect, useLayoutEffect, useState } from "react";
import Images from "../../../assets/IMGS/Images";
import { multilanguage } from "redux-multilanguage";
import useWindowDimension from "../../../hooks/useWindowDimension";
import MetaTags from 'react-meta-tags';

import './TourScreen.scss';

/**
 * A functional component that renders the TourScreen page.
 * @param {Object} props - The props object that contains the strings to be displayed on the page.
 * @returns The TourScreen page with all its elements.
 */
const TourScreen = (props) => {
    let { strings } = props;

    const dimension = useWindowDimension();
    const [logoareaHeight, setLogoareaHeight] = useState(0);
    const [btn_tourHeight, setBtn_tourHeight] = useState(0);
    const [bottomHeight, setBottomHeight] = useState(0);

    /**
     * A React hook that sets the height of various elements on the page.
     * @returns None
     */
    useEffect(() => {
        setLogoareaHeight(document.getElementsByClassName("logoarea")[0]?.offsetHeight);
        setBtn_tourHeight(document.getElementsByClassName("btn_tour")[0]?.offsetHeight);
        setBottomHeight(document.getElementsByClassName("bottom")[0]?.offsetHeight);
    }, []);




    return (
        <>
            <MetaTags>
                <title>Scotland By-The-Book - Birlinn Ltd</title>
                <meta name="description" content="Explore Scotland's regions through the creative works of renowned authors & use our interactive map to discover books, events, festivals, and more." />
                <meta property="og:title" content="Scotland By-The-Book - Birlinn Ltd" />
            </MetaTags>
            <div className="tourscreen_area">
                <div className="contain_area" style={{ height: dimension.height + "px" }}>
                    <div className="logoarea">
                        <img className="logo img-fluid" src={Images.IMAGES.LOGO} alt="" />
                    </div>
                    <div style={{ height: dimension.height - logoareaHeight - btn_tourHeight - bottomHeight + "px" }} className="textarea">
                        <p>Travel Scotland by book and prepare for a serious case of wanderlust! Explore the country through the pages of your favourite novels, guidebooks, memoirs, histories and more.</p>
                        <p>How to Use:</p>
                        <ul>
                            <li style={{ color: 'white' }}>Browse Scotland by region, explore books tied to specific landmarks and find out more about each area.</li>
                            <li style={{ color: 'white' }}>Explore the map to discover book recommendations, bookshops to visit and festivals to attend.</li>
                            <li style={{ color: 'white' }}>Within a region, use the filter buttons to hone your search.</li>
                            <li style={{ color: 'white' }}>Add specific books, shops and events to your favourites so you can return to them any time.</li>
                            <li style={{ color: 'white' }}>Visit the directory to view our full list of books, bookshops, and events.</li>
                            <li style={{ color: 'white' }}>Click on individual pins to find out more information, buy books online, download ebooks and get directions to specific shops and festival venues.</li>
                        </ul>
                        <p>Books are waiting here to inspire and inform the thoughtful explorer, the traveller who wants to scratch beneath the surface– you!</p>
                    </div>
                    <div className="d-grid gap-2">
                        <a className="btn_tour" href="/discover" >{strings["LETS_GO"]}</a>
                    </div>
                    <div className="bottom">
                        <img className="logo img-fluid" src={Images.IMAGES.BIRLINNLIMITED} alt="" />
                    </div>
                    <img className="ship1 img-fluid" src={Images.VECTOR.SHIP1} alt="" />
                    <img className="tower img-fluid" src={Images.VECTOR.TOWER} alt="" />
                    <img className="fish img-fluid" src={Images.VECTOR.FISH} alt="" />
                    <img className="ship2 img-fluid" src={Images.VECTOR.SHIP2} alt="" />
                    <img className="castle img-fluid" src={Images.VECTOR.CASTLE} alt="" />
                </div>
            </div>
        </>
    );
};

export default (multilanguage(TourScreen));