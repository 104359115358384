import React, { useState, useEffect, useRef, useReducer, useMemo } from "react";
import { multilanguage } from "redux-multilanguage";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import moment from "moment";
import ReactQuill from "react-quill";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import parse from 'html-react-parser';
import { DropdownButton } from "react-bootstrap";
import _, { get } from "lodash";

import HookForm from "../../assets/components/HookForm/HookForm";
import SearchBox from "../../assets/components/UI/SearchBox/SearchBox";
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../assets/components/UI/CustomDropdown/CustomDropdown";
import ModalPopup from "../../assets/components/UI/ModalPopup/ModalPopup";
import TextField from "../../assets/components/UI/TextField/TextField";
import Label from "../../assets/components/UI/Label/Label";
import CheckBox from "../../assets/components/UI/CheckBox/CheckBox";
import FileUpload from "../../assets/components/UI/FileUpload/FileUpload";
import RadioButton from "../../assets/components/UI/RadioButton/RadioButton";
import ConfirmModal from "../../assets/components/UI/ConfirmModal/ConfirmModal";
import GooglePlaceDropDown from "../../assets/components/GooglePlaceDropDown/GooglePlaceDropDown";

import AxiosInstance from "../../services/axios";
import Constant from "../../state/utils/constant";
import useWindowDimension from "../../hooks/useWindowDimension";
import { showLoader, hideLoader } from "../../redux/actions/loaderActions";
import { showToast } from "../../redux/actions/toastAction";
import { uploadFIle } from "../../services/helper.service";
import MetaTags from 'react-meta-tags';

import "./Book.scss";

const Book = (props) => {
  const dimensions = useWindowDimension();
  let { strings, showToast } = props;
  let history = useNavigate();
  const scrollBar = useRef();
  const [searchText, setsearchText] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [changeshow, setChangeShow] = useState(false);
  const [showlistDetails, setShowListDetails] = useState(false);
  const [portLocation, setPortLocation] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);
  const [businessCategoryList, setBusinessCategoryList] = useState([]);
  const [bookType, setBookType] = useState(null);
  const [bookCategoryList, setBookCategoryList] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [stepForm, setStepform] = useState();
  const [stepImportForm, setStepImportForm] = useState();
  const [profileImage, setProfileImage] = useState(null);
  const [confirmType, setConfirmType] = useState(null);
  const [addBookRegion, setAddBookRegion] = useState([]);
  const [filteredBook, setFilteredBook] = useState('Title:asc');
  const [selectedBookRegionId, setSelectedBookRegionId] = useState(null);
  const [expandCard, setExpandCard] = useState("");
  const [bookCount, setBookCount] = useState(0);
  const [showAll, setShowAll] = useState({ id: null, show: false });
  const [textEditor, setTextEditor] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [books, setBooks] = useState([]);
  const [location, setLocation] = useState([])
  const [bookDetails, setBookDetails] = useState();
  const [bookRegion, setBookRegion] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEnableDisableModal, setShowEnableDisableModal] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [bookPublishDate, setBookPublishDate] = useState(null);
  const [selectedBookRegion, setSelectedBookRegion] = useState([])
  const [checkEdit, setCheckEdit] = useState(false);
  const [editBookDetails, setEditBookDetails] = useState([]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [stepform, setStepForm] = useState();
  const [bookGenre, setBookGenre] = useState([])
  const [regionType, setRegionType] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [csvFile, setCSVFile] = useState(null);
  const [csvFileName, setCSVFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [bookImportImage, setBookImportImage] = useState(null);
  const [body, setBody] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);
  // const [clearRegion, setClearRegion] = useState(false)
  const [textEditorDelta, setTextEditorDelta] = useState('')

  const reactQuillRef = React.useRef();

  /**
   * The initial state for a pagination component. 
   * @type {Object}
   * @property {number} page - The current page number.
   * @property {boolean} emptyList - A flag indicating whether the list is empty or not.
   */
  const initialState = {
    page: 1,
    emptyList: false,
  }

  /**
   * A reducer function that takes in the current state and an action and returns a new state.
   * @param {object} state - The current state of the application.
   * @param {object} action - The action to be performed on the state.
   * @param {string} action.type - The type of action to be performed.
   * @returns {object} - The new state of the application.
   * @throws {Error} - If the action type is not recognized.
   */
  function reducer(state, action) {
    if (action.type === 'PAGE_INCREMENT') {
      return { ...state, page: state.page + 1 }
    } if (action.type === 'RESET_PAGE') {
      return { ...state, page: state.page = 1, emptyList: false }
    } if (action.type === 'EMPTY_LIST') {
      return { ...state, emptyList: true }
    }
    throw new Error(' no action type')
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  /**
   * Handles the change event when a CSV file is selected.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onCSVFileChange = async (acceptedFiles) => {

    setCSVFile(acceptedFiles[0]);
    setCSVFileName(acceptedFiles[0].name);

    setFileSize(acceptedFiles[0].size / 1024);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
    });

  };
  /**
   * Handles the event when a user changes their profile image.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onProfileImageChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setProfileImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };
  // const stepForm = { 

  /**
   * Handles the change event when a user selects an image file for book import.
   * Reads the file and sets the book import image state to the resulting data URL.
   * @param {File[]} acceptedFiles - An array of accepted image files.
   * @returns None
   */
  const onBookImportImageChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setBookImportImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };

  /**
   * A component that displays a limited amount of text content and provides a "View more" button
   * to expand the content.
   * @param {Object} props - The props object.
   * @param {string} props.content - The text content to display.
   * @param {number} props.limit - The character limit for the text content.
   * @param {string} props.name - The name of the content.
   * @returns A React component that displays the text content and a "View more" button.
   */
  const ReadMoreText = ({ content, limit, name }) => {
    const toShow = parse(content?.substring(0, limit));
    if (content?.length <= limit) {
      return <p className="areaSubTitle table-scroll">{parse(content)}</p>;
    } else {
      if (showAll.id === name) {
        return (
          <>
            <p className="areaSubTitle table-scroll">
              {parse(content)}
            </p>
            <div className="more_btn">
              <span
                onClick={(e) => {
                  setShowAll({ id: null, show: false });
                  // showLess();
                  e.stopPropagation();
                  setExpandCard("");
                }}
              >
                View less
              </span>
            </div>
          </>
        );
      } else if (showAll.id !== name) {
        return (
          <>
            <p className="areaSubTitle table-scroll">
              {toShow}
            </p>
            <div className="more_btn">
              <span
                onClick={(e) => {
                  // showMore();
                  setExpandCard(name);
                  e.stopPropagation();
                  setShowAll({ id: name, show: true });
                }}
              >
                View more
              </span>
            </div>
          </>
        );
      }
    }
  };

  /**
   * Scrolls the window to the top of the page with a smooth animation.
   * @returns None
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  /**
   * An object representing the form fields for a dropdown button.
   * @property {object} businessCategory - An object representing the business category dropdown field.
   * @property {string} businessCategory.name - The name of the business category field.
   * @property {object} businessCategory.validate - An object containing validation rules for the business category field.
   * @property {object} businessCategory.validate.required - An object containing the required validation rule for the business category field.
   * @property {boolean} businessCategory.validate.required.value - A boolean indicating whether the business category field is required or not.
   */
  const dropdownBtnform = {
    businessCategory: {
      name: "businessCategory",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  /**
   * Retrieves a list of books from the server based on the given search, sorting, and filtering parameters.
   * @param {string} search - The search query to filter the books by.
   * @param {string} sorting - The sorting method to use for the books.
   * @param {string} filtering - The filtering method to use for the books.
   * @returns None
   */
  const getBooks = async (search, sorting, filtering) => {
    props.showLoader();
    let params = `?page=${state.page}&limit=20`;
    if (search) {
      params += `&search=${search}`
    }
    if (sorting) {
      params += `&sortBy=${sorting}`
    }
    if (filtering) {
      params += `&regionIds=${filtering}`
    }
    try {
      const { payload } = await AxiosInstance.get(`admin/book/get${params}`)
      props.hideLoader();
      if (payload.length > 0)
        setBookCount(payload[0].countNumber)
      else
        setBookCount(0)
      if (state.page === 1) {
        setBooks([])
        scrollToTop();
        setBooks(payload)
      } else {
        if (payload.length === 0) {
          dispatch({ type: 'EMPTY_LIST' })
        }
        if (payload.length > 0) {
          setBooks([...books, ...payload]);
        } else {
          if (state.page === 1) {
            setBooks([])
          }
        }
      }
    } catch (err) {
      props.hideLoader();
    }
  }
  /**
   * Retrieves the book region from the server and sets the state of the component with the response.
   * @returns None
   */
  const getBookRegion = async () => {
    await AxiosInstance.get('admin/region/getfilter').then((response) => {
      setBookRegion(response.payload);
      const addBookRegion = response.payload.map((item) => {
        return { id: item?.id, value: item?.name, title: item?.name }
      })
      setBookCategoryList(addBookRegion);
    })
  }

  /**
   * Retrieves the book genre from the server using AxiosInstance.
   * @returns None
   */
  const getBookType = async () => {
    await AxiosInstance.get('admin/getgenre').then((response) => {
      const genre = response?.payload.map((item) => {
        return { id: item?.genre, value: item?.genre, title: item?.genre }
      })
      setBookGenre(genre)

    })
  }

  /**
   * Handles the confirmation of a user action.
   * @param {any} data - The data associated with the confirmation.
   * @returns None
   */
  const onPressConfirm = (data) => {
    setShowLogoutModal(false);
    setConfirm(true);
  };

  const bookCategories = [
    { id: "b01", title: "Action and Adventure", value: "Action and Adventure" },
    { id: "b02", title: "Classics", value: "Classics" },
    {
      id: "b03",
      title: "Comic Book or Graphic Novel",
      value: "Comic Book or Graphic Novel",
    },
    {
      id: "b04",
      title: "Detective and Mystery",
      value: "Detective and Mystery",
    },
    { id: "b05", title: "Fantasy", value: "Fantasy" },
    { id: "b06", title: "Historical Fiction", value: "Historical Fiction" },
    { id: "b06", title: "Horror", value: "Horror" },
    { id: "b07", title: "Literary Fiction", value: "Literary Fiction" },
  ];
  ;
  ;

  /**
   * Runs once when the component mounts and sets the heading height, business category list,
   * book region, and book type.
   * @returns None
   */
  useEffect(() => {
    setHeadingHeight(
      document.getElementsByClassName("headingHeight")[0]?.offsetHeight
    );
    setBusinessCategoryList(businessCategoryData);
    getBookRegion();
    getBookType();
  }, []);

  useEffect(() => {
    getBooks(searchText ? searchText : '', filteredBook ? filteredBook : '', selectedBookRegionId ? selectedBookRegionId : '');
  }, [selectedBookRegionId, searchText, state.page, filteredBook])

  /**
   * Handles the change event for a radio button group. Sets the confirm type to the value of the selected radio button.
   * @param {Event} event - The change event triggered by selecting a radio button.
   * @returns None
   */
  const radioChangeHandler = (event) => {
    setConfirmType(event.target.value);

  };

  const businessCategoryData = [
    {
      id: "1",
      title: "ALL",
      value: "ALL",
      isSelected: true,
    },
    {
      id: "2",
      title: "Edinburgh",
      value: "Edinburgh",
      isSelected: false,
    },
    {
      id: "3",
      title: "Highlands",
      value: "Highlands",
      isSelected: false,
    },
    {
      id: "4",
      title: "Aberdeenshire",
      value: "Aberdeenshire",
      isSelected: true,
    },
    {
      id: "5",
      title: "Argyll and Bute",
      value: "Argyll and Bute",
      isSelected: false,
    },
    {
      id: "6",
      title: "Argyll and Bute",
      value: "Argyll and Bute",
      isSelected: false,
    },
  ];
  const bookCategoryData = [
    {
      id: "1",
      title: "Ascending (A-Z)",
      value: "Ascending (A-Z)",
      sortValue: "Title:asc"
    },
    {
      id: "2",
      title: "Descending (Z-A)",
      value: "Descending (Z-A)",
      sortValue: "Title:desc"
    },
    {
      id: "3",
      title: "Publication Date",
      value: "Publication Date",
      sortValue: "publishDate:asc"
    },
  ];

  const containerStyle = {
    width: '100%',
    height: '70%'
  }
  /**
   * A functional component that renders a button with a custom date input.
   * @param {object} props - The props object.
   * @param {string} props.value - The value of the date input.
   * @param {function} props.onClick - The function to call when the button is clicked.
   * @returns A button with a custom date input.
   */
  const BookPublishDate = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-date-input" onClick={onClick}>
        <span>{value ? value : <h6>DD/MM/YYYY</h6>} <i className="icon icon-calendar" /></span>
      </button>
    );
  };

  /**
   * Updates the state of the book publish date when the user selects a new date.
   * @param {Array<Date>} dates - An array of dates representing the new publish date.
   * @returns None
   */
  const onChangeBookPublishDate = (dates) => {
    setBookPublishDate(dates);
  };

  /**
   * An object containing properties for adding a book form. Each property represents a form field
   * and contains a name and validation object.
   * @property {Object} fullName - The full name of the person submitting the form.
   * @property {string} fullName.name - The name of the form field.
   * @property {Object} fullName.validate - The validation object for the form field.
   * @property {Object} fullName.validate.required - The required validation for the form field.
   * @property {boolean} fullName.validate.required.value - The required value for the form field.
   * @property {Object} fullName.validate.pattern - The pattern validation for the form field.
   * @property {string} fullName.validate.pattern.value - The pattern value for the
   */
  const addBookform = {
    fullName: {
      name: "Name",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: strings["ONLY_ALPHABETICAL_CHARACTERS"],
        },
      },
    },
    setTitle: {
      name: "setTitle",
      validate: {
        required: {
          value: true,
          message: "Book title is required.",
        },
      },
    },
    subTitle: {
      name: "subTitle",
      validate: {
        required: {
          value: true,
          message: "Book subtitle is required.",
        },
        pattern: {

        }
      },
    },
    setUrlName: {
      name: "setUrlName",
      validate: {
        required: {
          value: true,
          message: "Purchase URL is required.",
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: "Please enter valid purchase URL",
        },
      },
    },
    setPriceName: {
      name: "setPriceName",
      validate: {
        required: {
          value: true,
          message: "Price is required",
        },
        pattern: {
          value: Constant.REGEX.ONLYNUMBER,
          message: strings["PRICEVAL"],
        },
      },
    },
    setGenreName: {
      name: "setGenreName",
      validate: {
        required: {
          value: true,
        },
      },
    },
    setRegionType: {
      name: 'setRegionType',
      validate: {
        required: {
          value: true
        },
      },
    },
    PublishDate: {
      name: "PublishDate",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.NUMBER,
          message: strings["ONLY_NUMERIC_VALUES"],
        },
      },
    },
    authorName: {
      name: "authorName",
      validate: {
        required: {
          value: true,
          message: "Author name is required",
        },
      },
    },
    aboutName: {
      name: "aboutName",
      validate: {
        required: {
          value: true,
          message: "Author description is required.",
        },
      },
    },
    businessCategory: {
      name: "businessCategory",
      validate: {
        required: {
          value: true,
        },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: true,
        },
      },
    },

    portLocation: {
      name: "portLocation",
      validate: {
        required: {
          value: true,
        },
      },
    },

    description: {
      name: "description",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };
  /**
   * Loads the Google Maps JavaScript API and checks if it has been loaded.
   * @param {string} id - The ID of the script tag to load the API.
   * @param {string} googleMapsApiKey - The API key for the Google Maps JavaScript API.
   * @returns An object with a boolean value indicating whether the API has been loaded.
   */
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyDYiRK54loDXDLRIxB9nl5R8fvSozD2PPM"
  // })
  const [map, setMap] = React.useState(null)
  /**
   * A callback function that is called when the Google Maps API is loaded.
   * It sets the initial bounds of the map and sets the map state.
   * @param {Object} map - The Google Maps object.
   * @returns None
   */
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: -3.745,
      lng: -38.523
    });
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  /**
   * A callback function that sets the map state to null when the component unmounts.
   * @param {Object} map - The map object to be set to null.
   * @returns None
   */
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  /**
   * Sets the state of checkEdit to true, indicating that the component is in edit mode.
   * @returns None
   */
  const handleEdit = () => {
    setCheckEdit(true);
  }

  /**
   * Sets the state of `showListDetails` to true, which will cause the books view to display
   * additional details about each book in the list.
   * @returns None
   */
  const editBooksView = () => {
    setShowListDetails(true);
  }
  /**
   * Handles the deletion of a book by updating its status to "Deleted" in the database.
   * @param {string} deletedId - the ID of the book to be deleted
   * @returns None
   */
  const onPressDeleteBook = async (deletedId) => {
    setShowDeleteModal(false);
    await AxiosInstance.put(`admin/book/updatestatus?bookId=${deletedId}`, {
      status: 'Deleted',
    }).then((response) => {
      props.showToast({
        message: "The book has been deleted successfully.",
        type: "success",
      });
      dispatch({ type: 'RESET_PAGE' })
      getBooks(searchText ? searchText : '', filteredBook ? filteredBook : '', selectedBookRegionId ? selectedBookRegionId : '');
    }).catch((error) => {
    })
    setShowListDetails(showlistDetails ? false : showlistDetails);
  };

  /**
   * Handles the cancel confirm button press event.
   * Hides the cancel confirmation modal and sets the change show state to false.
   * @returns None
   */
  const onPressCancelConfirm = () => {
    setShowCancelConfirm(false);
    setChangeShow(false);
  };

  /**
   * Handles the enabling or disabling of a book.
   * @param {string} disabledId - The ID of the book to enable or disable.
   * @param {string} bookStatus - The current status of the book.
   * @returns None
   */
  const onPressEnableDisable = async (disabledId, bookStatus) => {
    setShowEnableDisableModal(false);
    setShowListDetails(false);
    let params = {}
    if (bookStatus === "Enabled") {
      params.status = "Disabled"
    } else {
      params.status = "Enabled"
    }
    await AxiosInstance.put(`admin/book/updatestatus?bookId=${disabledId}`, params).then((response) => {
      setTimeout(() => {
        props.showToast({
          message: bookDetails?.status === "Enabled"
            ? "The book has been disabled successfully."
            : "The book has been enabled successfully.",
          type: "success",
        });
        getBooks(searchText ? searchText : '', filteredBook ? filteredBook : '', selectedBookRegionId ? selectedBookRegionId : '');
      }, 1000);
    })
  };
  /**
   * Handles the import button press event. Sends a POST request to the server with the
   * selected CSV file and displays a success message if the import was successful.
   * @returns None
   */
  const onPressImport = async () => {
    var bodyFormData = new FormData();
    const url = 'auth/bookupload'
    bodyFormData.append('avatar', csvFile)
    await AxiosInstance({
      method: "post",
      url: url,
      data: bodyFormData,
      headers: { "Accept": "multipart/form-data" }
    }).then((response) => {
      props.showToast({
        message: response.message,
        type: "success",
      });
      getBooks(searchText ? searchText : '', filteredBook ? filteredBook : '', selectedBookRegionId ? selectedBookRegionId : '');

    })
    setShowImportModal(false);
    setConfirm(true);

  };

  /**
   * Handles the key press event and checks if the key pressed is the Enter key.
   * @param {Event} e - The key press event object.
   * @returns None
   */
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
    }
  }

  /**
   * Handles the update of a book by sending a PUT request to the server with the updated book data.
   * @returns None
   */
  const onPressUpdateBook = async () => {
    let editBookDate = bookPublishDate;

    let editBookData = stepform.watch();
    console.log(editBookData)
    let params = {
      Title: editBookData?.setTitle,
      SubTitle: editBookData?.subTitle,
      // description: textEditorDelta,
      description: editBookData.description,
      ProductFormDesc: confirmType,
      DefaultPrice: editBookData?.setPriceName,
      purchaseURL: editBookData?.setUrlName,
      genre: editBookData?.setGenreName?.title,
      authorName: editBookData?.authorName,
      aboutAuthor: editBookData?.aboutName,
      regionName: editBookData?.setRegionType?.title,
      region: editBookData?.setRegionType?.id,
      locationName: editBookData?.portLocation?.address,
      publishDate: editBookDate,
      LONG: editBookData?.portLocation?.location?.long,
      LAT: editBookData?.portLocation?.location?.lat,
    }

    if (profileImage) {
      params.profileImage = await uploadFIle(profileImage)
    }
    await AxiosInstance.put(`admin/book/edit?bookId=${editBookDetails._id}`, params).then((response) => {
      props.showToast({
        message: "The book has been updated successfully.",
        type: "success",
      });
      getBooks(searchText ? searchText : '', filteredBook ? filteredBook : '', selectedBookRegionId ? selectedBookRegionId : '');
    })
    setShowUpdateModal(false);
    setShowListDetails(showlistDetails ? false : showlistDetails);
  }

  /**
   * Sets the state of the update modal to true, which triggers the modal to be displayed.
   * @returns None
   */
  const onEditBookSubmit = async () => {
    setShowUpdateModal(true);
  }
  /**
   * Handles the submission of a book form by sending a POST request to the server with the form data.
   * @returns None
   */
  const onBookSubmit = async () => {
    setIsLoading(true)
    let addBookDate = bookPublishDate;
    let bookData = stepform.watch();
    let params = {
      Title: bookData?.setTitle,
      SubTitle: bookData?.subTitle,
      // description: textEditorDelta,
      description: bookData.description,
      //  JSON.stringify(textEditorDelta),
      ProductFormDesc: confirmType,
      DefaultPrice: bookData?.setPriceName,
      purchaseURL: bookData?.setUrlName,
      genre: bookData?.setGenreName?.title,
      authorName: bookData?.authorName,
      aboutAuthor: bookData?.aboutName,
      regionName: bookData?.setRegionType?.title,
      region: bookData?.setRegionType?.id,
      locationName: bookData?.portLocation?.address,
      publishDate: addBookDate,
      LONG: bookData?.portLocation?.location?.long,
      LAT: bookData?.portLocation?.location?.lat,
    }
    /**
     * If a profile image is provided, upload the file and set the URL to the params object.
     * If no profile image is provided, set the profileImage property to null.
     * @param {File} profileImage - The profile image file to upload.
     * @returns None
     */
    if (profileImage) {
      params.profileImage = await uploadFIle(profileImage)
    } else {
      params.profileImage = null;
    }
    await AxiosInstance.post('admin/book/add', params).then((response) => {
      getBooks(searchText ? searchText : '', filteredBook ? filteredBook : '', selectedBookRegionId ? selectedBookRegionId : '');
      setIsLoading(false)

    }).catch((error => {
    }))
    setChangeShow(false);
    setTimeout(() => {
      /**
       * Resets the values of the form fields to their initial state.
       * @param {object} values - An object containing the initial values of the form fields.
       * @param {object} options - An object containing options for resetting the form.
       * @param {boolean} options.keepErrors - Whether to keep the errors of the form fields.
       * @param {boolean} options.keepDirty - Whether to keep the dirty state of the form fields.
       * @param {boolean} options.keepIsSubmitted - Whether to keep the isSubmitted state of the form fields.
       * @param {boolean} options.keepTouched - Whether to keep the touched state of the form fields.
       * @param {boolean} options.keepIsValid - Whether to keep the
       */
      stepform.reset(
        {
          aboutName: "",
          authorName: "",
          setPriceName: "",
          absetTitleoutName: "",
          setUrlName: "",
          subTitle: "",
          updateimage: null,
          tabDropdown: "",
        },
        {
          keepErrors: false,
          keepDirty: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
          keepValues: false,
        }
      );
      setBody(null);
      setProfileImage(null);
      setBookPublishDate(null);
    }, 1000);
  };

  /**
   * Inserts an image into the Quill editor at the current cursor position.
   * @param {Event} e - The event object.
   * @returns None
   */
  const insertImage = (e) => {
    const editor = reactQuillRef.current?.getEditor();
    if (editor) {
      const cursorPosition = editor.getSelection().index;
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async function () {
        const uploadImage = await uploadFIle(input.files[0]);
        const link = uploadImage;
        editor.insertEmbed(cursorPosition, 'image', link);
        editor.setSelection(cursorPosition + 1);
      }
    }
  }


  /**
   * Inserts a video into the Quill editor at the current cursor position.
   * @param {Event} e - The event object.
   * @returns None
   */
  const insertVideo = (e) => {
    const editor = reactQuillRef.current?.getEditor();
    if (editor) {
      const cursorPosition = editor.getSelection().index;
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/mp4');
      input.click();
      input.onchange = async function () {
        const uploadImage = await uploadFIle(input.files[0]);
        const link = uploadImage;

        editor.insertEmbed(cursorPosition, 'video', link, 'user');
      }
    }
  }

  /**
   * Returns an object containing modules for the Quill editor. The modules include a toolbar
   * and clipboard configuration.
   * @returns An object containing modules for the Quill editor.
   */
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [
          { align: "right" },
          { align: "center" },
        ],
        [{ list: "ordered" }],
        ["link", "image", "video"],
      ],
      handlers: {
        image: insertImage,
        video: insertVideo
      }
    },
    clipboard: {
      matchVisual: false,
    }
  }), [])

  return (
    <>
      <MetaTags>
        <title>Manage Books | Scotland By-The-Book Admin - Birlinn Ltd</title>
        <meta name="description" content="Add individual books, bulk-upload books, edit existing listing information, temporarily disable listings, or delete listings that are no longer required." />
        <meta property="og:title" content="Manage Books | Scotland By-The-Book Admin - Birlinn Ltd" />
      </MetaTags>

      <div className="bookMain">
        <HookForm
          defaultForm={{}}>
          {(formMethod) => {
            return (
              <>
                <div className="headingHeight">
                  <div className="bookMainPage">
                    <h1 className="title">{strings["MANAGEBOOK"]}</h1>
                    <div className="eventBtn">
                      <CustomButton
                        type="button"
                        title={strings["ADDBOOK"]}
                        className="customAddEvent"
                        onClick={() => {
                          setChangeShow({ type: "add", show: true })
                          setEditBookDetails([])
                          setConfirmType(null);
                          setRegionType(null);
                          setTextEditor(null);
                          setProfileImage(null)
                          setBookPublishDate(null);
                          setBookType(null)
                          setPortLocation(null)
                          // setBookType(null);
                        }
                        }
                      ></CustomButton>
                      <CustomButton
                        type="button"
                        title={strings["IMPORT"]}
                        className="customImport"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowImportModal(true);
                        }}
                      ></CustomButton>
                    </div>
                  </div>
                  <div className="setSearch">
                    <div className="tabButtonSearch">
                      <SearchBox
                        placeholder={strings["SEARCHBYBOOKS"]}
                        value={searchText}
                        onSearch={(value) => {
                          if (value.length !== 0) {
                            setsearchText(value.slice(0, 200));
                            if (value?.length >= 3) {
                              dispatch({ type: 'RESET_PAGE' })
                            }
                          } else {
                            setsearchText("");
                            dispatch({ type: 'RESET_PAGE' })

                          }
                          setPageLoad(true);


                        }}
                        onClear={() => {
                          setsearchText("");
                          dispatch({ type: 'RESET_PAGE' })
                        }}
                      />
                    </div>
                    <div className="tabDropdown">
                      <Dropdown className="d-inline mx-2 divider" autoClose="outside" placement="bottom">
                        <Dropdown.Toggle id="dropdown-autoclose-outside"
                          onClick={() => {
                            setDropdownShow(!dropdownShow);
                          }} >
                          {strings['REGIONS']} ({selectedBookRegion.length})
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={"end"} placement="end" show={dropdownShow} className={`${dropdownShow === false ? 'dropdown-menu showClose dropdown-menu-end' : 'dropdown-menu show dropdown-menu-end'} `}>
                          <div className="drop_list">
                            {bookRegion?.map((value) => {
                              const checkedBox = () => {
                                let item = false;
                                selectedBookRegion.length && selectedBookRegion.forEach((i) => {
                                  if (value._id === i.id) {
                                    item = true;
                                  }
                                })
                                return item;
                              }
                              return (
                                <CheckBox
                                  key={value._id}
                                  labelTitle={value.name}
                                  id={value._id}
                                  checked={checkedBox}
                                  checkedIcon="icon-check-square-top"
                                  onCheckedChange={(checked) => {
                                    let bookRegionList = [...selectedBookRegion];
                                    if (checked !== true) {
                                      const remove = selectedBookRegion.filter((l) => l.id !== value._id)
                                      bookRegionList = [...remove]
                                    } else {
                                      bookRegionList.push({ id: value._id, name: value.name })
                                    }
                                    setSelectedBookRegion([...bookRegionList]);

                                  }}
                                />
                              );
                            })}
                          </div>
                          <div className="drop_btns">
                            <CustomButton
                              type="button"
                              title={strings["APPLY"]}
                              className="titleApply"
                              onClick={() => {
                                dispatch({ type: 'RESET_PAGE' })
                                const filteredBookId = selectedBookRegion.map((l) => {
                                  return l.id;
                                })
                                setSelectedBookRegionId(filteredBookId)
                                setDropdownShow(false);
                              }}
                            />
                            <CustomButton
                              type="button"
                              title={strings["CLEAR"]}
                              className="titleApply"
                              onClick={() => {
                                dispatch({ type: 'RESET_PAGE' })
                                setSelectedBookRegion([]);
                                setSelectedBookRegionId(null);
                                setDropdownShow(false);
                              }}
                            />
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                  </div>
                  <div className="setPara">
                    <p className="bookLine"> {books.length > 0 ? `${strings['SHOWING']} ${books[0].countNumber} ${strings['BOOKS']}` : 'No data found'}</p>
                    <div className="tabDropdownSecond">
                      <Controller
                        rules={addBookform.businessCategory.validate}
                        defaultValue={formMethod.watch(
                          addBookform.businessCategory.name
                        )}
                        name="tabDropdown"
                        control={formMethod.control}
                        render={({ field: { onChange } }) => (
                          <CustomDropdown
                            isReqired={true}
                            placeholder={strings["SORT_BY"]}
                            type="text"
                            autoClose={"outside"}
                            dropDownItems={bookCategoryData}
                            align={"end"}
                            selectedValue={businessCategory}
                            onSelect={(evt) => {
                              dispatch({ type: 'RESET_PAGE' })
                              setFilteredBook(evt.sortValue);
                              onChange(evt);
                              setBusinessCategory(evt);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </HookForm>
        <div
          className="mainPage"
          style={{ height: dimensions.height - headingHeight - 10 + "px" }}
          ref={scrollBar}
          onScroll={(e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom && state.emptyList === false) {
              // setPage(page + 1)
              dispatch({ type: 'PAGE_INCREMENT' })
            }
          }}
        >
          <ul>
            {books?.map((childItem, index) => {
              let item = childItem.data
              return (
                <li
                  key={item._id + index}
                  className={
                    item.status === "Enabled" ? "mainIndex" : "disabled mainIndex"
                  }
                  onClick={() => {
                    setShowListDetails(true);
                    setBookDetails(item);
                    setLocation(item?.location?.coordinates);
                  }}
                >
                  <div className="mainIndexButton">
                    <CustomButton
                      type="button"

                      className="customEditbtn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteModal(true);
                        setBookDetails(item);
                      }}
                    >
                      <i className="icon icon-trash-2" />
                    </CustomButton>
                    <CustomButton type="button" onClick={(e) => {
                      e.stopPropagation();
                      handleEdit();
                      setEditBookDetails(item);
                      setConfirmType(item.ProductFormDesc)
                      setRegionType({ id: item.region, name: item.regionName });
                      setTextEditor(parse(item?.description));
                      setProfileImage(item?.profileImage ? item?.profileImage : 'https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png')
                      setBookType({ id: item.genre, name: item?.genre })
                      setBookPublishDate(new Date(item?.publishDate))
                      setPortLocation({
                        portLocation: {
                          latitude: item?.location?.coordinates[1],
                          longitude: item?.location?.coordinates[0],
                          address: item?.locationName,
                        }
                      })
                      setChangeShow({ type: "edit", show: true });
                    }} className="customEditbtn1">
                      <i className="icon icon-edit-2" />
                    </CustomButton>
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="disableDropdown"
                      onClick={(e) => {
                        e.stopPropagation();
                        // setShowEnableDisableModal(true);
                      }}
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowEnableDisableModal(true);
                          setBookDetails(item);
                          setCheckEdit(false)
                        }}
                      >
                        {item.status === "Enabled"
                          ? strings["DISABLE"]
                          : strings["ENABLE"]}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div >
                  {<img src={item.profileImage
                    ? item.profileImage
                    : 'https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png'} className="mainImage" />}
                  <div className="mainTitle">
                    <div className="TitleWithTag">
                      <h5 className="title">{item?.Title}</h5>
                      <span>{item?.RegionName}</span>
                    </div>
                    <p className="metaTitle">{item?.SubTitle}</p>
                    <p className="author">{strings['BY']} {item?.authorName}</p>
                    <p className="price">£{item?.DefaultPrice}</p>
                    <ReadMoreText
                      content={item?.description ? parse((item?.description)) : ''}
                      limit={185}
                      name={item._id}
                    />                </div>
                </li >
              );
            })}
          </ul >
        </div >

        <Offcanvas
          show={showlistDetails}
          onHide={() => setShowListDetails(false)}
          {...props}
          placement="end"
          className="bookOffcanvas"
        >
          <Offcanvas.Header>
            <p className="alignCenters">
              <i
                className="icon icon-arrow-left"
                onClick={() => setShowListDetails(false)}
              ></i>
            </p>
            {/* Scottish Form Start */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="scottishCard">
              <img
                src={bookDetails?.profileImage ? bookDetails?.profileImage : ' https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png'}
                alt=""
                className="scottishCardLeft"
              />
              <div className="scottishCardRight">
                <p className="title">{strings['Published']}  {moment(bookDetails?.publishDate).format('Do MMMM  YYYY')}</p>
                < p className="metaTitle" > {bookDetails?.Title}</p>
                <p className="discription">{bookDetails?.SubTitle}</p>
                <p className="author"> {strings['BY']} {bookDetails?.authorName}</p>
                <p className="price">£{bookDetails?.DefaultPrice}</p>
                <div className="scottishCardMain">
                  <p className="scottishCardMainInner">{bookDetails?.RegionName}</p>
                  <p className="scottishCardMainInner">{bookDetails?.genre}</p>
                  <p className="scottishCardMainInner">{bookDetails?.ProductFormDesc}</p>
                </div>
              </div>
            </div>
            <div className="scottishCardButton">
              <button
                className="scottishButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                  setEditBookDetails(bookDetails);
                  setConfirmType(bookDetails?.ProductFormDesc)
                  setRegionType({ id: bookDetails?.region, name: bookDetails?.regionName });
                  setTextEditor(parse(bookDetails?.description));
                  setBookType({ id: bookDetails.genre, name: bookDetails?.genre })
                  setProfileImage(bookDetails?.profileImage !== null ? bookDetails?.profileImage : ' https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png')
                  setBookPublishDate(new Date(bookDetails?.publishDate))
                  setPortLocation({
                    portLocation: {
                      latitude: bookDetails?.location?.coordinates[1],
                      longitude: bookDetails?.location?.coordinates[0],
                      address: bookDetails?.locationName,
                    }
                  })
                  setChangeShow({ type: "edit", show: true });
                }}
              >
                <i className="icon icon-edit-2" />
                {strings["EDIT"]}
              </button>

              <button
                className="scottishButton"
                onClick={() => {
                  setShowEnableDisableModal(true);
                }}
              >
                <i className="icon icon-minus-circle" />
                {bookDetails?.status === "Enabled" ? strings["DISABLE"] : strings["ENABLE"]}
              </button>

              <button
                className="scottishButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }}
              >
                <i className="icon icon-trash-2" />
                {strings["DELETE"]}
              </button>
            </div>

            <div className="bookTitle">{strings['ABOUT_BOOK']}</div>
            <div className="aboutPart">
              {/* <div className="booktext">
              {bookDetails.description}
            </div> */}
            </div>
            <div className="booktextContent">
              <span>{bookDetails?.description && parse(parse(`${bookDetails?.description}`))}</span>
            </div>
            <div className="aboutAuthorTitle">{strings['ABOUT_AUTHOR']}</div>
            <div className="aboutAuthortext">
              {bookDetails?.aboutAuthor}
            </div>
            <div className="map_view">
              <iframe
                title="map"
                className="map"
                src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                  }&q=${get(
                    location,
                    "[1]",
                    1
                  )},${get(location, "[0]", 1)}`}
                width="100%"
                height="330px"
                frameborder="0"
                allowFullScreen="false"
                aria-hidden="false"
                tabindex="0"
              />
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          className="AddbookOffcanvas"
          show={changeshow}
          onHide={() => setChangeShow(false)}
          placement="end"
          {...props}
        >
          {" "}
          <Offcanvas.Header>
            <p
              className="alignCenters"
              onClick={(e) => {
                // e.stopPropagation();
                setShowCancelConfirm(false);
                setChangeShow(false);
                setShowListDetails(false)
                // setShowCancelConfirm(true);
              }}
            >
              <i className="icon icon-arrow-left"></i>
            </p>
            <div className="Addbtn">
              <Label
                className="Addtitle"
                title={
                  changeshow.type === "add"
                    ? "Add Book"
                    : changeshow.type === "edit" && "Edit Book"
                }
              />
              <div
                className=""
              // onClick={handleShow}
              >
                <CustomButton
                  defaultForm={{}}
                  type="button"
                  title={changeshow.type === "edit" ? "Update" : changeshow.type === "add" && "Add"}
                  className={
                    changeshow.type === "edit"
                      ? "customUpdatebtn"
                      : changeshow.type === "add" && "customAddbtn"
                  }
                  disabled={
                    changeshow.type === "add" &&
                    !(
                      !stepForm?.formState.isValid &&
                      profileImage &&
                      bookType &&
                      confirmType &&
                      portLocation
                    )
                  }
                  loading={isLoading && true}
                  onClick={(e) => {
                    e.preventDefault();
                    changeshow.type === "add" ? onBookSubmit() : onEditBookSubmit();
                  }}
                />
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ height: dimensions.height - 100 }}>
            <HookForm defaultForm={{}} init={(form) => setStepForm(form)}>
              {(formMethod) => {
                return (
                  <>
                    <div className="addForm">
                      <div className="addImage">
                        <div className="setImage">
                          <p>{strings['BOOK_IMAGE']}</p>
                        </div>

                        <div className="leftBox">
                          <div className="updateSectionaudio">
                            <Controller
                              // defaultValue={setCheckEdit === true ? editBookDetails.profileImage : null}
                              name="updateimage"
                              control={formMethod.control}
                              render={({ field: { onChange, value } }) => (
                                <FileUpload
                                  onDrop={(acceptedFiles) => {
                                    onProfileImageChange(acceptedFiles);
                                    onChange(acceptedFiles);
                                  }}
                                  accept='image/jpeg,image/jpg,image/png, image/tiff'
                                >
                                  <div className="imgBoxEmpty">
                                    {profileImage || bookDetails?.profileImage ? (
                                      <div className="imageShow">
                                        <img
                                          src={(profileImage || bookDetails?.profileImage) ? (profileImage || bookDetails?.profileImage) : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811436.svg'}

                                          alt="logo"
                                          className="audioRegion"
                                        />
                                        <div className="importImg">
                                          <i className="icon icon-upload"></i>
                                          <p className="fileImported">{strings['UPLOAD_IMG']}</p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="uploadedImg">
                                        <div className="importImg">
                                          <i className="icon icon-upload-1"></i>
                                          <p>{strings['UPLOAD_IMG']}</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </FileUpload >
                              )}
                            />
                          </div >
                        </div >
                      </div >
                      <div className="fieldPartsTitle">
                        <div className="setTitle">
                          <p>{strings["FULL_TITLE"]}</p>
                        </div>
                        <TextField
                          formMethod={formMethod}
                          defaultValue={checkEdit === true ? editBookDetails.Title : null}
                          rules={addBookform.setTitle.validate}
                          name={addBookform.setTitle.name}
                          errors={formMethod?.formState?.errors}
                          autoFocus={true}
                          type="text"
                          placeholder={strings["BOOK_TITLE_NAME"]}
                          isReqired={true}
                        />
                      </div>

                      <div className="fieldPartsubName">
                        <div className="setsubName">
                          <p>{strings["BOOK_TITLE"]}</p>
                        </div>
                        <TextField
                          formMethod={formMethod}
                          rules={addBookform.subTitle.validate}
                          defaultValue={checkEdit === true ? editBookDetails.SubTitle : null}
                          name={addBookform.subTitle.name}
                          errors={formMethod?.formState?.errors}
                          autoFocus={true}
                          type="text"
                          placeholder={strings["BOOK_SUB_TITLE"]}
                          isReqired={true}
                        />
                      </div>
                      <div className="fieldPartstexteditor">
                        <div className="settexteditor">
                          <p>{strings["DESCRIPTION"]}</p>
                        </div>
                        <div className="updateSectioneditor">

                          <Controller
                            defaultValue={checkEdit === true ? editBookDetails?.description && parse(editBookDetails?.description) : ''}
                            control={formMethod.control}
                            name={addBookform.description.name}
                            rules={addBookform.description.validate}
                            render={({ field: { onChange, value } }) => (
                              <ReactQuill
                                placeholder="Write here"
                                ref={reactQuillRef}
                                // value={textEditor} modules={modules}
                                value={value} modules={modules}
                                formats={[
                                  "header",
                                  "font",
                                  "size",
                                  "bold",
                                  "italic",
                                  "align",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                  "list",
                                  "bullet",
                                  "indent",
                                  "link",
                                  "image",
                                  "video",
                                  "color"
                                ]}
                                onChange={(content, delta, source, editor) => {
                                  // let textContent = editor.getText(content);
                                  // // let parsedElement = textEditor?.replace(/(<([^>]+)>)/ig, '');
                                  // setTextEditor(content);
                                  // setTextEditorDelta(editor.getHTML());
                                  // var quill = reactQuillRef?.current?.getEditor();
                                  onChange(content)

                                  // quill?.on('text-change', function (delta, old, source) {
                                  //   if (quill?.getLength() > 5000) {
                                  //     quill?.deleteText(5000, quill?.getLength());
                                  //   }
                                  // });
                                  // setDescriptionLength(textContent?.length);
                                }} />
                            )}
                          />



                        </div>
                      </div>
                      <div className="fieldPartsradio">
                        <div className="setRadio">
                          <p>{strings["FULL_FORM_TITLE"]}</p>
                        </div>
                        <div className="radio_row">
                          <RadioButton
                            changed={radioChangeHandler}
                            id="PaperworkRadio"
                            isSelected={confirmType === "Paperback"}
                            label="Paperback"
                            value="Paperback"
                          />
                          <RadioButton
                            changed={radioChangeHandler}
                            id="HandbookRadio"
                            isSelected={confirmType === "Hardback"}
                            label="Hardback"
                            value="Hardback"
                          />
                          <RadioButton
                            changed={radioChangeHandler}
                            id="JigsawRadio"
                            style={{ marginLeft: '20px' }}
                            isSelected={confirmType === "Jigsaw"}
                            label="Jigsaw"
                            value="Jigsaw"
                          />
                          <RadioButton
                            changed={radioChangeHandler}
                            id="CalendarRadio"
                            style={{ marginLeft: '20px' }}
                            isSelected={confirmType === "Calendar"}
                            label="Calendar"
                            value="Calendar"
                          />

                        </div>
                      </div>

                      <div className="fieldPartsPrice">
                        <div className="setPrice">
                          <p>{strings["FULL_FORM_PRICE"]}</p>
                        </div>
                        <TextField
                          formMethod={formMethod}
                          rules={addBookform.setPriceName.validate}
                          defaultValue={checkEdit === true ? editBookDetails.DefaultPrice : null}
                          name={addBookform.setPriceName.name}
                          errors={formMethod?.formState?.errors}
                          autoFocus={true}
                          maxLength={5}
                          // type="number"
                          onKeyDown={(e) => {
                            if (e.keyCode === 69) {
                              e.preventDefault();
                            }
                          }}
                          placeholder={strings["ENTER_PRICE_TEXT"]}
                          isReqired={true}
                        />
                      </div>

                      <div className="fieldPartSurl">
                        <div className="setUrlName">
                          <p>{strings["BOOK_URL"]}</p>
                        </div>
                        <TextField
                          formMethod={formMethod}
                          rules={addBookform.setUrlName.validate}
                          defaultValue={checkEdit === true ? editBookDetails.purchaseURL : null}
                          name={addBookform.setUrlName.name}
                          errors={formMethod?.formState?.errors}
                          autoFocus={true}
                          type="text"
                          placeholder={strings["BOOK_URL_TEXT"]}
                          isReqired={true}
                        />
                      </div>

                      <div className="fieldPartsGenre">
                        <div className="setGenre">
                          <p>{strings["BOOK_GENRE"]}</p>
                        </div>

                        <div className="BooktabDropdown">
                          <Controller
                            control={formMethod.control}
                            name={addBookform.setGenreName.name}
                            rules={addBookform.setGenreName.validate}
                            render={({ field: { onChange } }) => (
                              <CustomDropdown
                                isReqired={true}
                                placeholder={strings["REGION_DROPDOWN"]}
                                type="text"
                                autoClose={"outside"}
                                dropDownItems={bookGenre}
                                align={"end"}
                                selectedValue={bookType}
                                onSelect={(evt) => {
                                  onChange(evt);
                                  setBookType({ id: evt.id, name: evt.value });
                                }}
                              />
                            )}
                          />
                        </div>
                      </div >

                      <div className="fieldPartsDate">
                        <div className="PublishDate">
                          <p>{strings["BOOK_PUBLISH"]}</p>
                        </div>
                        <DatePicker
                          selected={bookPublishDate}
                          type="date"
                          onChange={(e) => {
                            onChangeBookPublishDate(e);
                          }}
                          customInput={<BookPublishDate />}
                          popperClassName="dateFilter"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="scroll"
                          dateFormat="dd/MM/yyyy"
                          placeholderText={'Please select a date'}
                          isClearable={false}
                        />
                      </div>

                      <div className="fieldPartsAuthor">
                        <div className="authorName">
                          <p>{strings["BOOK_AUTHOR"]}</p>
                        </div>
                        <TextField
                          formMethod={formMethod}
                          rules={addBookform.authorName.validate}
                          defaultValue={checkEdit === true ? editBookDetails.authorName : null}
                          name={addBookform.authorName.name}
                          errors={formMethod?.formState?.errors}
                          autoFocus={true}
                          type="text"
                          placeholder={strings["BOOK_AUTHOR_TEXT"]}
                          isReqired={true}
                        />
                      </div>

                      <div className="fieldaboutAuthor">
                        <div className="aboutName">
                          <p>{strings["BOOK_ABOUT"]}</p>
                        </div>
                        <TextField
                          formMethod={formMethod}
                          rules={addBookform.aboutName.validate}
                          defaultValue={checkEdit === true ? editBookDetails?.aboutAuthor : null}
                          name={addBookform.aboutName.name}
                          errors={formMethod?.formState?.errors}
                          autoFocus={true}
                          rows={3}
                          maxLength={1000}
                          textarea={"textarea"}
                          placeholder={strings["BOOK_ABOUT_TEXT"]}
                          isReqired={true}
                        />
                      </div>
                      <div className="fieldPartsRegion">
                        <div className="setRegion">
                          <p>{strings["BOOK_REGION"]}</p>
                        </div>
                        <div className="BooktabRegion">
                          <Controller
                            defaultValue=""
                            name={addBookform.setRegionType.name}
                            rules={addBookform.setRegionType.validate}
                            control={formMethod.control}
                            render={({ field: { onChange } }) => (
                              <CustomDropdown
                                isReqired={true}
                                placeholder={strings["BOOK_DROPDOWN"]}
                                type="text"
                                autoClose={"outside"}
                                dropDownItems={bookCategoryList}
                                align={"end"}
                                selectedValue={regionType}
                                onSelect={(evt) => {
                                  onChange(evt);
                                  setRegionType({ id: evt.id, name: evt.value });
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="location_part">
                        <div className="setRegion">
                          <p>{strings["LOCATION"]}</p>
                        </div>
                        <div className="locationRightPart">
                          <div className="locationDropdown">
                            <Controller
                              defaultValue=""
                              control={formMethod?.control}
                              name={addBookform.portLocation.name}
                              rules={addBookform.portLocation.validate}
                              render={({ field: { onChange } }) => (
                                <GooglePlaceDropDown
                                  getLocation={(place) => {
                                    place === null
                                      ? setPortLocation(null)
                                      : setPortLocation({
                                        portLocation: {
                                          latitude: place?.location?.lat,
                                          longitude: place?.location?.long,
                                          address: place?.address
                                        },
                                      });
                                    onChange(place);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="map_view">
                            <iframe
                              title="map"
                              className="map"
                              src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                }&q=${get(
                                  portLocation,
                                  "portLocation.latitude",
                                  1
                                )},${get(
                                  portLocation,
                                  "portLocation.longitude",
                                  1
                                )}`}
                              width="100%"
                              height="330px"
                              frameborder="0"
                              allowFullScreen="false"
                              aria-hidden="false"
                              tabindex="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div >
                  </>
                );
              }}
            </HookForm >
          </Offcanvas.Body >
        </Offcanvas >

        <ConfirmModal
          leftBtnTitle={strings["YES"]}
          rightBtnTitle={strings["NO"]}
          modalTitle={strings["UPDATE"]}
          modalDescription={strings["SUREUPDATE"]}
          showModal={showUpdateModal}
          onPressLeft={() => {
            onPressUpdateBook(bookDetails?._id)
            setChangeShow(false);
            setShowListDetails(false);

          }}
          onPressRight={() => setShowUpdateModal(false)}
        />

        <ConfirmModal
          leftBtnTitle={strings["YES"]}
          rightBtnTitle={strings["NO"]}
          modalTitle={strings["DELETE"]}
          modalDescription={strings["SUREDELETE"]}
          showModal={showDeleteModal}
          onPressLeft={() => {
            onPressDeleteBook(bookDetails?._id);

          }}
          onPressRight={() => setShowDeleteModal(false)}
        />

        <ConfirmModal
          className="bookConfirmModal"
          leftBtnTitle={strings["YES"]}
          rightBtnTitle={strings["NO"]}
          modalTitle={strings["CANCEL"]}
          modalDescription={strings["SURE_CANCEL_CHANGES"]}
          showModal={showCancelConfirm}
          onPressLeft={() => {
            onPressCancelConfirm();
            setEditBookDetails([]);
            setShowListDetails(false);
            setCheckEdit(false);
          }}
          onPressRight={() => setShowCancelConfirm(false)}
        />

        <ConfirmModal
          leftBtnTitle={strings["YES"]}
          rightBtnTitle={strings["NO"]}
          modalTitle={
            bookDetails?.status === "Enabled" ? strings["DISABLE"] : strings["ENABLE"]
          }
          modalDescription={
            bookDetails?.status === "Enabled"
              ? strings["SURE_DISABLE_BOOK"]
              : strings["SURE_ENABLE_BOOK"]
          }
          showModal={showEnableDisableModal}
          onPressLeft={() => {
            onPressEnableDisable(bookDetails?._id, bookDetails?.status);
          }}
          onPressRight={() => {
            setShowEnableDisableModal(false);
          }}
        />
        <ModalPopup
          className="bookImportModal"
          showModal={showImportModal}
          closeButton={true}
          onHide={() => {
            setShowImportModal(false);
          }}
        >
          <HookForm defaultForm={{}} init={(form) => setStepImportForm(form)}>
            {(formMethod) => {

              return (
                <>
                  <div className="updateSection">
                    <Controller
                      name="uploadCSV"
                      control={formMethod.control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <input
                            type="file"
                            id={"csvFileUploader"}
                            accept=".xlsx, .xls, .csv"
                            onChange={(file) => {
                              onChange(file);
                              onCSVFileChange(file.target.files);
                            }}
                          />
                          <div className="importFileIcon">
                            {csvFileName === null ? (
                              <label
                                htmlFor="csvFileUploader"
                                className="csfileUploader"
                              >
                                <i className="icon icon-log-in" />
                                {strings["IMPORT_FILE"]}
                              </label>
                            ) : (
                              <label
                                htmlFor="csvFileUploader"
                                className="csfileUploader"
                              >
                                <i className="icon icon-file-plus" />
                                <p className="fileName">{csvFileName}</p>
                                <span className="fileSize">{`${fileSize}${fileSize >= 2048 ? 'MB' : 'KB'}`}</span>
                              </label>
                            )}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="btnInline">
                    <CustomButton
                      type="button"
                      title={strings["YES"]}
                      onClick={() => {
                        onPressImport();
                      }}
                      className="titleYes"
                      disabled={csvFile === null ? true : false}
                    />
                    <CustomButton
                      type="button"
                      title={strings["NO"]}
                      onClick={() => {
                        setShowImportModal(false);
                      }}
                      className="titleNo"
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </ModalPopup>

      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
}

/**
 * A function that maps dispatch actions to props. 
 * @param {function} showToast - A function that dispatches an action to show a toast notification.
 * @param {function} showLoader - A function that dispatches an action to show a loading spinner.
 * @param {function} hideLoader - A function that dispatches an action to hide a loading spinner.
 * @returns An object containing the mapped dispatch actions.
 */
const mapDispatchToProps = { showToast, showLoader, hideLoader };

/**
 * Connects the Book component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * @param {Function} mapStateToProps - A function that maps the state properties to the component props.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch actions to the component props.
 * @returns A higher-order component that wraps the Book component and connects it to the Redux store.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Book));
