import React, { useEffect, useState } from "react";
import { multilanguage } from "redux-multilanguage";
import Constant from "../../../state/utils/constant";
import { Link, useNavigate } from "react-router-dom";
import HookForm from "../../../assets/components/HookForm/HookForm";
import TextField from "../../../assets/components/UI/TextField/TextField";
import CustomButton from "../../../assets/components/UI/CustomButton/CustomButton";
import CheckBox from "../../../assets/components/UI/CheckBox/CheckBox";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { showToast } from "../../../redux/actions/toastAction";
import { connect } from "react-redux";
import AxiosInstance from "../../../services/axios";
import jwtService from "../../../services/jwt.service";
import MetaTags from 'react-meta-tags';

import "./Login.scss";

/**
 * A functional component that renders a login form for the admin panel.
 * @param {Object} props - The props object.
 * @param {Object} props.strings - An object containing localized strings for the component.
 * @returns The login form component.
 */
const Login = (props) => {
  let { strings } = props;
  const dimensions = useWindowDimension();
  let history = useNavigate();
  const [loginForm, setLoginForm] = useState();
  const [showPass, setShowPass] = useState(false);
  const [busy, setBusy] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  /**
   * An object representing the login form fields and their validation rules.
   * @typedef {Object} Login
   * @property {Object} email - The email field object.
   * @property {string} email.name - The name of the email field.
   * @property {Object} email.validate - The validation rules for the email field.
   * @property {Object} email.validate.required - The required validation rule for the email field.
   * @property {boolean} email.validate.required.value - The value of the required validation rule for the email field.
   * @property {string} email.validate.required.message - The error message to display if the required validation rule fails for the email field.
   * @property {Object} email.validate.pattern - The pattern validation
   */
  const login = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: strings["ENTEREMAILADDRESS"],
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: strings["INVALID_EMAIL"],
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: strings["ENTER_PASSWORD"],
        },
      },
    },
  };

  /**
   * Handles the submission of the login form by sending a POST request to the server with the user's
   * email and password. If the request is successful, the user's tokens and information are saved to
   * local storage and the user is redirected to the regions page. If the request fails, an error message
   * is displayed to the user.
   * @param {Object} data - An object containing the user's email and password.
   * @returns None
   */
  const onFormSubmit = async (data) => {
    setBusy(true);
    await AxiosInstance.post('/admin/login', {
      email: data.email.toLowerCase(),
      password: data.password
    }).then((response) => {
      const tokens = response.payload.tokens;
      jwtService.saveToken(tokens?.access?.token, tokens?.refresh?.token)
      props.showToast({
        message: response.message,
        type: "success",
      });
      localStorage.setItem('user', JSON.stringify(response.payload.user));
      if (rememberMe) {
        localStorage.setItem('birlinn_admin_email', data.email)
        localStorage.setItem('birlinn_admin_password', data.password)
        localStorage.setItem('birlinn_admin_rememberMe', true)

      } else {
        localStorage.removeItem('birlinn_admin_email')
        localStorage.removeItem('birlinn_admin_password')
        localStorage.removeItem('birlinn_admin_rememberMe')
      }
      history("/admin/regions");
    }).catch((err) => {
      props.showToast({
        message: "Sorry! Incorrect email address or password, Please try again.",
        type: 'error',
      })
    })
    setBusy(false);
  };

  /**
   * useEffect hook that retrieves the 'birlinn_admin_email' value from localStorage.
   * This hook runs once when the component mounts.
   * @returns None
   */
  useEffect(() => {
    localStorage.getItem('birlinn_admin_email')
  })

  return (
    <>
      <MetaTags>
        <title>Admin Login | Scotland By-The-Book - Birlinn Ltd</title>
        <meta name="description" content="Manage all regions, books, events, and shop listings within the Scotland By-The-Book apps: edit existing listings, add or delete listings, and update their details." />
        <meta property="og:title" content="Admin Login | Scotland By-The-Book - Birlinn Ltd" />
      </MetaTags>
      <div className="loginMain" style={{ height: dimensions.height + "px" }}>
        <img
          style={{ height: dimensions.height }}
          src={Constant.IMAGESURL.BANNER}
          className="logoBack"
          title=""
          alt=""
        />
        <div className="loginLeft">
          <div className="leftLogo">
            <div>
              <img
                className="titlelogo"
                src={Constant.IMAGESURL.TITLEIMG}
                title=""
                alt=""
              />
              <img src={Constant.IMAGESURL.LOGO} className="logo" title="" alt="" />
              <img
                src={Constant.IMAGESURL.LASTITLEIMG}
                className="lastlogo"
                title=""
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="loginRight" style={{ height: dimensions.height + "px" }}>
          <div className="whiteBox">
            <h1>{strings["LOGIN"]}</h1>
            <p>{strings["SIGNINPLATFORM"]}</p>

            <HookForm
              defaultForm={{
                email: localStorage.getItem('birlinn_admin_email'),
                password: localStorage.getItem('birlinn_admin_password'),
                rememberMe: localStorage.getItem('birlinn_admin_rememberMe'),
              }}
              init={(form) => setLoginForm(form)}
              onSubmit={(e) => onFormSubmit(e)}
            >
              {(formMethod) => {
                return (
                  <>
                    <TextField
                      formMethod={formMethod}
                      rules={login.email.validate}
                      name={login.email.name}
                      errors={formMethod?.formState?.errors}
                      autoFocus={true}
                      type="text"
                      placeholder={strings["EMAILADDRESS"]}
                      leftIconName={"user"}
                    />
                    <TextField
                      formMethod={formMethod}
                      rules={login.password.validate}
                      name={login.password.name}
                      errors={formMethod?.formState?.errors}
                      type={showPass ? "text" : "password"}
                      placeholder={strings["PASSWORD"]}
                      iconClass={showPass ? "eye" : "eye-off"}
                      onIconClick={() => setShowPass(!showPass)}
                      iconRightShow={true}
                      leftIconName={"lock"}
                    />
                    <div className="checkBoxInline">
                      <CheckBox
                        id="remember"
                        labelTitle={strings["REMEMBERME"]}
                        checked={rememberMe}
                        onCheckedChange={(checked) => {
                          setRememberMe(checked);
                        }}
                      />
                      <Link to="/admin/forgotpassword" onClick={() => {
                        localStorage.removeItem('birlinn_admin_email')
                        localStorage.removeItem('birlinn_admin_password')
                        localStorage.removeItem('birlinn_admin_rememberMe')
                      }}>
                        {strings["FORGOTPASSWORD"]}
                      </Link>
                    </div>
                    <CustomButton
                      type="submit"
                      title={strings["LOGIN"]}
                      disabled={!formMethod?.formState.isValid}
                      loading={busy}
                      className="custombtnfield"
                    />
                  </>
                );
              }}
            </HookForm>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * An object containing the `showToast` action creator function, which can be used to dispatch
 * a `showToast` action to the Redux store.
 * @returns An object with the `showToast` action creator function.
 */
const mapDispatchToProps = { showToast };

/**
 * Connects the Login component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * @param {Function} mapStateToProps - A function that maps the state properties to the component props.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch actions to the component props.
 * @returns A higher-order component that wraps the Login component and connects it to the Redux store.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Login));
