import React, { useState, useEffect } from "react";
import './CustomToggleButton.scss';

/**
 * A custom toggle button component that can be used to toggle a boolean value.
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean} props.checked - The initial checked state of the toggle button.
 * @param {string} props.id - The id of the toggle button.
 * @param {function} props.onCheckChange - A callback function that is called when the toggle button is checked or unchecked.
 * @returns A toggle button component that can be used to toggle a boolean value.
 */
const CustomToggleButton = (props) => {
    let { onCheckChange } = props
    const [checked, setChecked] = useState(props.checked)
    let { id } = props

    /**
     * useEffect hook that updates the state of the checkbox when the checked prop changes.
     * @param {object} props - The props object containing the checked prop.
     * @returns None
     */
    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    /**
     * Handles the event when the toggle button is checked or unchecked.
     * @param {Event} e - The event object.
     * @returns The result of the onCheckChange function with the checked value of the toggle button.
     */
    const onToggleButtonChecked = (e) => {
        return (
            onCheckChange && onCheckChange(e.target.checked)
        )
    }

    return (
        <label className="switch">
            <input type="checkbox" id={id} checked={checked} onChange={onToggleButtonChecked} />
            <span className="slider round"></span>
        </label>
    )
}

/**
 * Default props for the CustomToggleButton component.
 * @param {string} id - The id of the toggle button.
 * @param {boolean} checked - Whether the toggle button is checked or not.
 * @returns None
 */
CustomToggleButton.defaultProps = {
    id: '',
    checked: false
}

export default CustomToggleButton;