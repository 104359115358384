export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

/**
 * Dispatches an action to show a toast notification.
 * @param {Object} payload - The payload object containing the message and type of the toast.
 * @returns A function that dispatches the SHOW_TOAST action with the given payload.
 */
export const showToast = (payload) => {
    return dispatch => {
        dispatch({
            type: SHOW_TOAST,
            payload
        });
    };
};
/**
 * Hides the toast notification.
 * @param {object} payload - The payload object.
 * @returns A function that dispatches an action to hide the toast notification.
 */
export const hideToast = (payload) => {
    return dispatch => {
        dispatch({
            type: HIDE_TOAST
        });
    };
};