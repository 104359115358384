import React, { useState, useEffect } from "react";
import { Nav, Navbar, Offcanvas, Dropdown } from "react-bootstrap";
import { multilanguage } from "redux-multilanguage";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";

import { showToast } from "../../redux/actions/toastAction";
import Constant from "../../state/utils/constant";
import AxiosInstance from "../../services/axios";
import jwtService from "../../services/jwt.service";

import ConfirmModal from "../../assets/components/UI/ConfirmModal/ConfirmModal";
import HookForm from "../../assets/components/HookForm/HookForm";
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import FileUpload from "../../assets/components/UI/FileUpload/FileUpload";
import TextField from "../../assets/components/UI/TextField/TextField";
import { uploadFIle } from "../../services/helper.service";

import "./Header.scss";

/**
 * A functional component that renders the header of the admin panel.
 * @param {Object} props - The props object containing the strings to be displayed in the header.
 * @returns The header component.
 */
const Header = (props) => {
  let { strings } = props;
  let history = useNavigate();

  const { pathname } = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [changePasswordshow, setChangePassowordShow] = useState(false);
  const changePasswordhandleClose = () => setChangePassowordShow(false);
  const chnagePasswordhandleShow = () => setChangePassowordShow(true);

  const [showlistDetails, setShowListDetails] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const [showOldPass, setShowOldPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [busy, setBusy] = useState(false);
  const [user, setUser] = useState({})

  const [profileImage, setProfileImage] = useState(null);

  /**
   * Handles the logout button press event for the book list page.
   * Sets the showLogoutModal state to false, toggles the showListDetails state,
   * destroys the JWT token, and redirects the user to the login page.
   * @returns None
   */
  const onPressLogoutBook = () => {
    setShowLogoutModal(false);
    setShowListDetails(showlistDetails ? false : showlistDetails);
    jwtService.destroyToken();
    history("/admin/login");
  };

  let activeMenu = "";

  /**
   * Determines the active menu based on the current pathname.
   * @param {string} pathname - the current pathname
   * @returns {string} the name of the active menu
   */
  if (pathname.indexOf("/admin/regions") !== -1) { activeMenu = "regions"; } if (pathname.indexOf("/admin/book") !== -1) { activeMenu = "book"; }
  if (pathname.indexOf("/admin/events") !== -1) { activeMenu = "events"; } if (pathname.indexOf("/admin/shop") !== -1) { activeMenu = "shop"; }


  /**
   * Sends a PUT request to the server to update the user's password.
   * @param {Object} data - An object containing the old password and the new password.
   * @returns None
   */
  const onChangeSubmit = async (data) => {
    await AxiosInstance.put('admin/changePassword', {
      cPassword: data.oldpassword,
      nPassword: data.password
    }).then((response) => {
      props.showToast({
        message: "Password has been updated successfully.",
        type: "success",
      });
      setTimeout(() => {
        setBusy(false);
        history("/admin/login");
      });
    }).catch((error) => {
      props.showToast({
        message: error.response.data.message,
        type: 'error',
      })
    })
    setBusy(true);
  };

  /**
   * An object representing the form fields for editing a user's profile.
   * @property {object} firstName - An object representing the first name field.
   * @property {string} firstName.name - The name of the first name field.
   * @property {object} firstName.validate - An object containing validation rules for the first name field.
   * @property {object} firstName.validate.required - An object containing the required validation rule for the first name field.
   * @property {boolean} firstName.validate.required.value - A boolean indicating whether the first name field is required.
   * @property {string} firstName.validate.required.message - A string containing the error message to display if the first name field is not filled out.
   * @property {object} firstName.validate
   */
  const formEditProfile = {
    firstName: {
      name: "firstname",
      validate: {
        required: {
          value: true,
          message: strings["ENTERFIRSTNAME"],
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: strings["INVALID_NAME"],
        },
      },
    },
    lastName: {
      name: "lastname",
      validate: {
        required: {
          value: true,
          message: strings["ENTERLASTNAME"],
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: strings["INVALID_NAME"],
        },
      },
    },
  };

  /**
   * Handles the event when a user selects a new profile image.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onProfileImageChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setProfileImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };
  /**
   * Retrieves user data from the server using AxiosInstance and sets the user state.
   * @returns None
   */
  const getUser = async () => {
    await AxiosInstance.get('admin/userdata').then((response) => {
      setUser(response.payload)
    })
  }
  /**
   * Handles the submission of a form to update a user's profile information.
   * @param {Object} data - An object containing the user's updated profile information.
   * @returns None
   */
  const onFormSubmit = async (data) => {
    let params = {
      firstName: data.firstname,
      lastName: data.lastname,
    }
    if (profileImage) {
      params.profileImageUrl = await uploadFIle(profileImage);
    }
    await AxiosInstance.put('admin/updateprofile', params).then((response) => {
      props.showToast({
        message: "Profile has been updated successfully",
        type: "success",
      });
    }).catch((error) => {
    })
    getUser();
    handleClose();
  }
  /**
   * Removes the user's profile photo by sending a PUT request to the server with a null profileImageUrl.
   * @returns None
   */
  const removePhoto = async () => {
    let params = {
      profileImageUrl: null,
    }
    await AxiosInstance.put('admin/updateprofile', params).then((response) => {
    }).catch((error) => {
    })
    getUser();
  }
  /**
   * Runs the getUser function once when the component mounts.
   * @returns None
   */
  useEffect(() => {
    getUser();
  }, [])
  /**
   * An object representing the fields of a change password form, along with their validation rules.
   * @property {object} oldpassword - The old password field.
   * @property {string} oldpassword.name - The name of the old password field.
   * @property {object} oldpassword.validate - The validation rules for the old password field.
   * @property {object} oldpassword.validate.required - The required validation rule for the old password field.
   * @property {boolean} oldpassword.validate.required.value - The value of the required validation rule for the old password field.
   * @property {string} oldpassword.validate.required.message - The error message to display if the required validation rule fails for the old password field.
   * @property {object
   */
  const changePasswordForm = {
    oldpassword: {
      name: "oldpassword",
      validate: {
        required: {
          value: true,
          message: strings["ENTER_PASSWORD"],
        }
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: strings["NEW_PASSWORD_REQUIRED"],
        },
        validate: {
          hasUppercase: (value) =>
            value && value.match(Constant.REGEX.LOWERCASEUPPERCASE) !== null,
          // hasLowerCase: (value) =>
          //   (value && value.match(Constant.REGEX.LOWERCASE)) !== null,
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) !== null,
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
          length: (value) =>
            (value && value.length >= 8 && value.length <= 16) || "",
        },

      },
    },
    confirmPassword: {
      name: "C",
      validate: {
        required: {
          value: true,
          message: strings["NEW_PASSWORD_ENTER"],
        },
      },
    },
  };

  const multiErrorFields = [
    { length: strings["MINIMUM8CHARACTERS"] },
    { hasSpecialChar: strings["MINIMUM1SPECIALCHARACTER"] },
    { hasNumbers: strings["MINIMUM1NUMERICCHARACTER"] },
    { hasUppercase: strings["MINIMUM1UPPERCASECHARACTER"] },
  ];

  return (
    <>
      <div className="mainHeader">
        <div className="leftHeader">
          <Navbar>
            <Navbar.Brand href="/admin/regions" className="navbar-logo">
              <img className="titlelogo" src={Constant.IMAGESURL.TITLEIMG} title="" alt="" />
            </Navbar.Brand>
            <Navbar.Collapse>
              <Nav activeKey={activeMenu} className="flex-column">
                <Nav.Link eventKey="regions" as={Link} to="/admin/regions"><i className="icon icon-pin"></i><span>{strings['REGION_TITLE']}</span></Nav.Link>
                <Nav.Link eventKey="book" as={Link} to="/admin/book"><i className="icon icon-books"></i> <span>{strings['MANAGE_BOOK']}</span></Nav.Link>
                <Nav.Link eventKey="events" as={Link} to="/admin/events"><i className="icon icon-events"></i> <span>{strings['MANAGE_EVENTS']}</span></Nav.Link>
                <Nav.Link eventKey="shop" as={Link} to="/admin/shop"><i className="icon icon-shop"></i> <span>{strings['MANAGE_SHOPS']}</span></Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Nav className="nav" onClick={handleShow}>
            <p className="alignCenter">
              <i className="icon icon-chevron-left"></i>
            </p>
            <div className="profileMain">
              <h6 className="titleName">{user?.firstName}</h6>
              <p className="titleEmail">{user?.email}</p>
            </div>
            <div className="imgArea">
              {profileImage || user?.profileImageUrl ? (
                <img src={profileImage || user?.profileImageUrl} alt="logo" />
              ) : (
                <div className="blankProfile">{user?.firstName?.charAt(0).toUpperCase() +
                  " " +
                  user?.lastName?.
                    charAt(user?.lastName?.lastIndexOf(" ") + 1)
                }</div>
              )}
            </div>
          </Nav>

          <Offcanvas show={show} onHide={handleClose} {...props} placement="end" className="loginOffcanvas" >
            <Offcanvas.Header>
              <p className="alignCenter">
                <i
                  className="icon icon-arrow-left"
                  onClick={() => {
                    handleClose(true);
                    setProfileImage(null)
                  }}
                ></i>
              </p>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="whiteBox">
                <HookForm defaultForm={{}} onSubmit={(e) => onFormSubmit(e)}>
                  {(formMethod) => {
                    return (
                      <>
                        <div className="profilesection">
                          <div className="imgBoxEmpty">
                            <div className="uploadedImg">
                              {profileImage || user?.profileImageUrl ? (
                                <img src={profileImage || user?.profileImageUrl} alt="logo" />
                              ) : (
                                <div className="blankProfile">{user?.firstName?.charAt(0).toUpperCase() +
                                  " " +
                                  user?.lastName?.
                                    charAt(user?.lastName?.lastIndexOf(" ") + 1)
                                }</div>
                              )}
                              <span className="change_Img">
                                <i className="icon-Camera1"></i>
                              </span>
                            </div>
                          </div>
                          <Dropdown
                            onToggle={(isOpen) => setIsOpen(isOpen)}
                            className="photoDropdown"
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="" >
                                <Controller
                                  defaultValue=""
                                  name="image"
                                  control={formMethod.control}
                                  render={({ field: { onChange, value } }) => (
                                    <FileUpload
                                      type="file"
                                      // files=".jpg, .jpeg, .png"
                                      accept="image/*"
                                      onDrop={(acceptedFiles) => {
                                        onProfileImageChange(acceptedFiles);
                                        onChange(acceptedFiles);
                                      }}
                                    >
                                      {strings["UPLOAD"]}
                                    </FileUpload>
                                  )}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item href="" onClick={() => {
                                removePhoto();
                                setProfileImage(null)
                              }}>
                                {strings["REMOVE"]}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <p className="userName">{user?.firstName}</p>
                        <p className="userEmail">{user?.email}</p>
                        <TextField
                          formMethod={formMethod}
                          rules={formEditProfile.firstName.validate}
                          defaultValue={user?.firstName ? user?.firstName : null}
                          name={formEditProfile.firstName.name}
                          errors={formMethod?.formState?.errors}
                          maxLength={50}
                          type={"text"}
                          placeholder={strings["FIRSTNAME"]}
                          onChange={(e) => {
                            let value = e.target.value;
                            formMethod.setValue(
                              formEditProfile.firstName.name,
                              e.target.value
                            );
                          }}
                          leftIconName={"user"}
                        />
                        <TextField
                          formMethod={formMethod}
                          rules={formEditProfile.lastName.validate}
                          defaultValue={user?.lastName ? user?.lastName : null}
                          name={formEditProfile.lastName.name}
                          errors={formMethod?.formState?.errors}
                          maxLength={50}
                          type={"text"}
                          placeholder={strings["LASTNAME"]}
                          onChange={(e) => {
                            let value = e.target.value;
                            formMethod.setValue(
                              formEditProfile.lastName.name,
                              e.target.value
                            );
                          }}
                          leftIconName={"user"}
                        />
                        <div>
                          <CustomButton
                            type="submit"
                            title={strings["UPDATE"]}
                            disabled={!formMethod?.formState.isValid}
                            className="custombtnfieldupdate"

                          />
                        </div>
                      </>
                    );
                  }}
                </HookForm>
              </div>
            </Offcanvas.Body>
            <div className="passwordInline">
              <div onClick={setChangePassowordShow} className="changePassword">
                <p>{strings["CHANGEPASSWORD"]}</p>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowLogoutModal(true);
                }}
                className="changeLogout"
              >
                <p>{strings["LOGOUT"]}</p>
              </div>
            </div>
          </Offcanvas>

          <Offcanvas
            show={changePasswordshow}
            onHide={changePasswordhandleClose}
            {...props}
            placement="end"
            className="changeOffcanvas"
          >
            <Offcanvas.Header>
              <p className="alignCenter">
                <i
                  className="icon icon-arrow-left"
                  onClick={() => {
                    changePasswordhandleClose(true);
                  }}
                ></i>
              </p>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="blackBox">
                <h1>{strings["CHANGEPASSWORD"]}</h1>
                <p>{strings["MENTIONEDCHANGEPASSWORD"]}</p>
                <HookForm defaultForm={{}} onSubmit={(e) => onChangeSubmit(e)}>
                  {(formMethod) => {
                    return (
                      <>
                        <TextField
                          formMethod={formMethod}
                          rules={changePasswordForm.oldpassword.validate}
                          name={changePasswordForm.oldpassword.name}
                          errors={formMethod?.formState?.errors}
                          type={showOldPass ? "text" : "password"}
                          placeholder={strings["OLDPASSWORD"]}
                          iconClass={showOldPass ? "eye" : "eye-off"}
                          onIconClick={() => setShowOldPass(!showOldPass)}
                          iconRightShow={true}
                          leftIconName={"lock"}
                        />
                        <TextField
                          formMethod={formMethod}
                          rules={changePasswordForm.password.validate}
                          multiErrorFields={multiErrorFields}
                          name={changePasswordForm.password.name}
                          errors={formMethod?.formState?.errors}
                          type={showPass ? "text" : "password"}
                          placeholder={strings["NEWPASSWORD"]}
                          iconClass={showPass ? "eye" : "eye-off"}
                          onIconClick={() => setShowPass(!showPass)}
                          onChange={(e) => {
                            let value = e.target.value;
                            e.target.value = value.replaceAll(" ", "");
                            formMethod.setValue(
                              changePasswordForm.password.name,
                              e.target.value
                            );
                            formMethod.watch(
                              changePasswordForm.confirmPassword.name
                            ) &&
                              formMethod.trigger(
                                changePasswordForm.confirmPassword.name
                              );
                          }}
                          leftIconName={"lock"}
                        />
                        <TextField
                          formMethod={formMethod}
                          rules={{
                            required: {
                              value: true,
                              message: strings["CONFIRM_NEWPASSWORD"],
                            },
                            validate: {
                              matchPassword: (value) =>
                                value ===
                                formMethod.watch(
                                  changePasswordForm.password.name
                                ) ||
                                formMethod.watch(
                                  changePasswordForm.password.name
                                ) === "" ||
                                strings["BOTHTHEPASSWORDSMUSTMATCH"],
                            },
                          }}
                          name={changePasswordForm.confirmPassword.name}
                          errors={formMethod?.formState?.errors}
                          type={showNewPass ? "text" : "password"}
                          placeholder={strings["CONFIRMPASSWORD"]}
                          iconClass={showNewPass ? "eye" : "eye-off"}
                          onIconClick={() => setShowNewPass(!showNewPass)}
                          onChange={(e) => {
                            let value = e.target.value;
                            e.target.value = value.replaceAll(" ", "");
                            formMethod.setValue(
                              changePasswordForm.confirmPassword.name,
                              e.target.value
                            );
                          }}
                          leftIconName={"lock"}
                        />
                        <div>
                          <CustomButton
                            className="custombtnfield"
                            type="submit"
                            title={strings["SUBMIT"]}
                            disabled={!formMethod?.formState.isValid}
                          />
                        </div>
                      </>
                    );
                  }}
                </HookForm>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>

      <ConfirmModal
        className="bookConfirmModal"
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["LOGOUT"]}
        modalDescription={strings["SURELOGOUT"]}
        showModal={showLogoutModal}
        onPressLeft={onPressLogoutBook}
        onPressRight={() => setShowLogoutModal(false)}
      />
    </>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { showToast };

/**
 * Connects the Header component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * Also wraps the component with the multilanguage higher-order component to provide internationalization support.
 * @param {Function} mapStateToProps - A function that maps the state properties to the component props.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch actions to the component props.
 * @returns The connected and wrapped Header component.
 */
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(Header));
