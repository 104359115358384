import React, { useEffect, useState } from "react";
import "./RadioButton.scss";

/**
 * A component that renders a radio button with a label and optional children.
 * @param {Object} props - The props object containing the following properties:
 *   @param {string} props.id - The id of the radio button.
 *   @param {string} props.value - The value of the radio button.
 *   @param {string} props.label - The label to display next to the radio button.
 *   @param {boolean} props.isSelected - Whether or not the radio button is selected.
 *   @param {boolean} props.disabled - Whether or not the radio button is disabled.
 *   @param {function} props.changed - The function to call when the radio button is changed.
 *   @param {
 */
const RadioButton = (props) => {
    let { children } = props;
    const [checked, setChecked] = useState(props.checked)
    /**
     * useEffect hook that sets the checked state to the value of isSelected prop when it changes.
     * @param {object} props - The props object containing the isSelected prop.
     * @returns None
     */
    useEffect(() => {
        props.isSelected && setChecked(props.isSelected)
    }, [])
    /**
     * Calls the 'changed' function passed in as a prop with the value of the target of the event.
     * @param {Event} e - the event object
     * @returns None
     */
    const checkChange = (e) => {
        props.changed(e.target.value)
    }
    return (
        <div className="RadioButton">
            <input id={props.id} onChange={props.changed} value={props.value} type="radio" checked={props.isSelected} disabled={props.disabled} />
            <label htmlFor={props.id}>{props.label}</label>
            {props.children}
        </div>

    );
}

export default RadioButton;