import React, { useState } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";

import Constant from "../../../state/utils/constant";
import useWindowDimension from "../../../hooks/useWindowDimension";

import "./UserHeader.scss";

/**
 * A functional component that renders the user header with navigation links.
 * @param {Object} props - The props object containing the strings to display.
 * @returns The rendered user header component.
 */
const UserHeader = (props) => {

  let { strings } = props
  let history = useNavigate();
  const { pathname } = useLocation();
  const dimension = useWindowDimension();
  const [searchbar_areaHeight, setSearchbar_areaHeight] = useState(0);
  let activeMenu = '';

  /**
   * Determines the active menu based on the current pathname.
   * @param {string} pathname - The current pathname of the page.
   * @returns {string} The name of the active menu.
   */
  if (pathname.indexOf("/discover") !== -1) { activeMenu = "discover"; }
  if (pathname.indexOf("/directory") !== -1) { activeMenu = "directory"; }
  if (pathname.indexOf("/bookmark") !== -1) { activeMenu = "bookmark"; }

  return (
    <>
      <Navbar style={{ height: dimension.height - searchbar_areaHeight + "px" }}>
        <Navbar.Brand href="/discover" className="navbar-logo">
          <img className="titlelogo" src={Constant.IMAGESURL.TITLEIMG} title="" alt="" />
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav activeKey={activeMenu} className="flex-column">
            <Nav.Link eventKey="discover" target="_self" as={Link} to="/discover" onClick={() => history.push("/discover")} ><i className="icon icon-pin"></i><span>{strings["DISCOVER"]}</span></Nav.Link>
            <Nav.Link eventKey="directory" target="_self" as={Link} to="/directory"><i className="icon icon-books"></i><span>{strings["DIRECTORY"]}</span></Nav.Link>
            <Nav.Link eventKey="bookmark" target="_self" as={Link} to="/bookmark"><i className="icon icon-fav"></i><span>{strings["BOOKMARK"]}</span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

/**
 * Connects the UserHeader component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * The resulting connected component is wrapped in the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state properties to the component props.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch actions to the component props.
 * @returns A connected and multilanguage-wrapped UserHeader component.
 */
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(UserHeader));