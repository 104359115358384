
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import authReducer from './authReducer';
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import mainLoaderReducer from "./mainLoaderReducer";

/**
 * Combines multiple reducers into a single root reducer using the combineReducers function from Redux.
 * @returns {Function} - A Redux reducer function that combines all the specified reducers.
 */
const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  loading: loaderReducer,
  toastReducer,
  authReducer,
  mainLoaderReducer
});

export default rootReducer;
