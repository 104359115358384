const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

/**
 * Retrieves the access token from local storage.
 * @returns {string | null} The access token if it exists, otherwise null.
 */
export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

/**
 * Retrieves the refresh token from local storage.
 * @returns {string | null} The refresh token if it exists, otherwise null.
 */
export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

/**
 * Saves the access and refresh tokens to local storage.
 * @param {string} access - The access token to save.
 * @param {string} refresh - The refresh token to save.
 * @returns None
 */
export const saveToken = (access, refresh) => {
  console.log('accessrefresh', access, refresh);
  window.localStorage.setItem(ACCESS_TOKEN_KEY, access);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
};

/**
 * Removes the access token and refresh token from local storage.
 * @returns None
 */
export const destroyToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
/**
 * A module that exports functions for managing authentication tokens.
 * @module auth
 * @see getToken
 * @see saveToken
 * @see destroyToken
 * @see getRefreshToken
 */
export default { getToken, saveToken, destroyToken, getRefreshToken };

