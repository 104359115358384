import React from "react";
import PropTypes from "prop-types";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "./CustomDropdown.scss";

/**
 * A custom dropdown component that displays a list of items and allows the user to select one.
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean} isRequired - Whether or not the dropdown is required.
 * @param {Object} customStyle - An object containing custom styles to apply to the dropdown.
 * @param {Object} selectedValue - The currently selected value in the dropdown.
 * @param {Array} dropDownItems - An array of objects representing the items in the dropdown.
 * @param {string} placeholder - The placeholder text to display when no item is selected.
 * @param {function} onSelect - A callback function to be called when an item is selected.
 */
const CustomDropdown = (props) => {
  let {
    isReqired,
    customeStyle,
    selectedValue,
    dropDownItems = [],
    placeholder,
    onSelect,
  } = props;

  /**
   * Returns the title of the currently selected item in the dropdown menu, or the placeholder
   * if no item is selected.
   * @returns {string} - The title of the selected item or the placeholder if no item is selected.
   */
  const getTitle = () => {
    if (selectedValue && selectedValue.id) {
      const item = dropDownItems.find(i => i.id === selectedValue.id)
      return (item && item.value) || placeholder;
    } else {
      return placeholder;
    }
  };

  return (
    <div className="customDropdown">
      <DropdownButton
        key={selectedValue?.id}
        id={`dropdown-variants-${selectedValue?.id}`}
        title={getTitle()}
        onSelect={(evt) => onSelect(dropDownItems.find((i) => i.id === evt))}
        className={selectedValue ? "selected" : ""}
      >
        <div className="dropdownData">
          {dropDownItems.map((item, index) => (
            <Dropdown.Item key={index.toString()} eventKey={item.id}>
              {item.value}
            </Dropdown.Item>
          ))}
        </div>
      </DropdownButton>
    </div>
  );
};

/**
 * PropTypes for CustomDropdown component.
 * @param {function} onClick - The function to be called when the dropdown is clicked.
 * @returns None
 */
CustomDropdown.propTypes = {
  onClick: PropTypes.func,
};

export default CustomDropdown;
