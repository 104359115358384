import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { UserLayoutRoute } from "./../../routes";

import useWindowDimension from "./../../hooks/useWindowDimension";

import UserHeader from "./UserHeader/UserHeader";
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import Curvedarrow from './../../assets/images/curved-arrow-icon.png';

/**
 * A functional component that renders the main layout for the user interface.
 * @param {object} props - The props passed to the component.
 * @returns The rendered component.
 */
const UserLayout = (props) => {
  const [isOverlayShow, setIsOverlayShow] = useState(window.localStorage.getItem('tutorialComplete') == null ? true : false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const dimension = useWindowDimension();

  useEffect(() => {
  }, []);

  return (
    <div className="usermain">
      {isOverlayShow &&
        <div className="overlay">
          <div className={stepOne ? 'steps firstStep active' : 'steps firstStep'}>
            <div className="box">
              <div className="activeNav">
                <i className="icon icon-pin"></i>
                <span>Discover</span>
              </div>
              <div className="titleWithArrow">
                <img src={Curvedarrow} />
                <p className="title">Explore the map here</p>
              </div>
            </div>
            <div className="buttonBottm">
              <CustomButton
                type="button"
                title='Next'
                className="next"
                onClick={() => {
                  setStepOne(false);
                  setStepTwo(true)
                }}
              />
              <CustomButton
                type="button"
                title='Skip'
                className="skip"
                onClick={() => {
                  setStepOne(false);
                  setIsOverlayShow(false);
                  window.localStorage.setItem('tutorialComplete', 'true')
                }}
              />
            </div>
          </div>
          <div className={stepTwo ? 'steps secondStep active' : 'steps secondStep'}>
            <div className="box">
              <div className="activeNav">
                <i className="icon icon-books"></i>
                <span>Directory</span>
              </div>
              <div className="titleWithArrow">
                <img src={Curvedarrow} />
                <p className="title">See all books, events<br /> and shops here</p>
              </div>
            </div>
            <div className="buttonBottm">
              <CustomButton
                type="button"
                title='Next'
                className="next"
                onClick={() => {
                  setStepTwo(false);
                  setStepThree(true)
                }}
              />
              <CustomButton
                type="button"
                title='Skip'
                className="skip"
                onClick={() => {
                  setStepTwo(false);
                  setIsOverlayShow(false);
                  window.localStorage.setItem('tutorialComplete', 'true')
                }}
              />
            </div>
          </div>
          <div className={stepThree ? 'steps thirdStep active' : 'steps thirdStep'}>
            <div className="box">
              <div className="activeNav">
                <i className="icon icon-fav"></i>
                <span>Bookmarks</span>
              </div>
              <div className="titleWithArrow">
                <img src={Curvedarrow} />
                <p className="title">Browse your favourites</p>
              </div>
            </div>
            <div className="buttonBottm">
              <CustomButton
                type="button"
                title='Next'
                className="next"
                onClick={() => {
                  setStepThree(false);
                  setStepFour(true);
                  window.localStorage.setItem('byAddingClass', 'mapPosition')
                }}
              />
              <CustomButton
                type="button"
                title='Skip'
                className="skip"
                onClick={() => {
                  setStepThree(false);
                  setIsOverlayShow(false);
                  window.localStorage.setItem('tutorialComplete', 'true')
                }}
              />
            </div>
          </div>

          <div className={stepFour ? 'steps fourthStep active' : 'steps fourthStep'}>
            <div className="box">
              <div className="titleWithArrow">
                <p className="title">Explore each region using the <br />pin icons</p>
                <img src={Curvedarrow} />
              </div>
            </div>
            <div className="buttonBottm">
              <CustomButton
                type="button"
                title='Finish'
                className="next"
                onClick={() => {
                  setStepFour(false);
                  setIsOverlayShow(false);
                  window.localStorage.removeItem('byAddingClass')
                  window.localStorage.setItem('tutorialComplete', 'true')
                }}
              />
            </div>
          </div>
        </div>
      }
      <UserHeader {...props} />
      <Routes>
        {UserLayoutRoute.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              // element={authenticateRoute()}
              element={route.component}
            />
          );
        })}
      </Routes>
    </div>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
      * @returns An object containing the props to be passed to the component.
      */
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

/**
 * Connects the UserLayout component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * @param {Function} mapStateToProps - A function that maps the state from the Redux store to props for the component.
      * @param {Function} mapDispatchToProps - A function that maps the dispatch function from the Redux store to props for the component.
      * @returns A higher-order component that is connected to the Redux store.
      */
export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
