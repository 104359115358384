/* eslint-disable */
import React from "react";
import { Form } from "react-bootstrap";
import "./TextField.scss";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

/**
 * A reusable component for a text field input with error handling and icons.
 * @param {Object} props - The props object containing the following properties:
 * @param {Object} errors - An object containing any errors for the field.
 * @param {string} placeholder - The placeholder text for the field.
 * @param {Array} multiErrorFields - An array of objects containing multiple error fields.
 * @param {boolean} disabled - Whether or not the field is disabled.
 * @param {string} type - The type of input field.
 * @param {boolean} textarea - Whether or not the field is a textarea.
 * @param {string} name - The name of the field.
 * @param {boolean}
 */
const TextField = (props) => {
  let {
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    iconClass,
    maxLength,
    onIconClick,
    onKeyDown,
    formMethod,
    rows,
    defaultValue,
    rules,
    errorIcon,
    successIcon,
    readOnly,
    leftIconName,
    InputLabelProps
  } = props;

  let values = formMethod?.getValues();
  let hasError = errors[name] !== undefined;

  return (
    <div className="textField">
      <>
        <Controller
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <>
              <Form.Control
                style={{ paddingRight: iconClass ? "40px" : "20px" }}
                className={hasError && "error"}
                name={name}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                autoComplete="off"
                type={type}
                InputLabelProps={InputLabelProps}
                as={textarea}
                readOnly={readOnly}
                rows={rows}
                onBlur={() => handleBlur}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
                onFocus={(e) => {
                  handleFocus && handleFocus(e);
                }}
                onChange={(e) => {
                  onChange && onChange(e.target.value.trimLeft());
                  props.onChange && props.onChange(e);
                }}
                value={formMethod.watch(name) || value ? value : ""}
              />
            </>
          )}
          name={name}
          control={formMethod?.control}
          rules={rules}
        />
        {leftIconName && (
          <div className="iconLeft">
            <i className={"icon icon-" + leftIconName} />
          </div>
        )}
        {iconClass && (
          <div className="iconSection">
            <i
              className={"icon icon-" + iconClass}
              onClick={() =>
                values[name] && values[name] !== "" && onIconClick()
              }
            ></i>
          </div>
        )}
      </>
      {multiErrorFields.length > 0 ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) => {
            if (messages) {
              let isMultipleError = Object.keys(messages).every(
                (errKey) =>
                  multiErrorFields.filter((m) => m[errKey] !== undefined)
                    .length > 0
              );
              if (isMultipleError) {
                let arr = [];
                for (const fieldError of multiErrorFields) {
                  let key = Object.keys(fieldError)[0];
                  let success = Object.keys(messages).includes(key);
                  arr.push(
                    <div className={success ? "red" : "green"} key={key}>
                      <span key={key}>
                        <i className={success ? errorIcon : successIcon} />
                        {fieldError[key]}
                      </span>
                    </div>
                  );
                }
                return (
                  <div className="errorMsg show passwordcustom">{arr}</div>
                );
              } else {
                return (
                  <div className="errorMsg show">
                    <span>
                      <i className={errorIcon} />
                      {errors[name]?.message}
                    </span>
                  </div>
                );
              }
            } else {
              return <div className="errorMsg"></div>;
            }
          }}
        />
      ) : hasError ? (
        <div className="errorMsg show">
          <span>
            <i className={errorIcon} />
            {errors[name]?.message}
          </span>
        </div>
      ) : (
        <div className="errorMsg"></div>
      )}
    </div>
  );
};

/**
 * Default props for the `extField` component.
 * @property {boolean} autoFocus - Whether or not the field should be auto-focused.
 * @property {string} value - The default value for the field.
 * @property {object} errors - An object containing any errors associated with the field.
 * @property {Array} multiErrorFields - An array of fields that can have multiple errors.
 * @property {boolean} noNegative - Whether or not negative values are allowed.
 * @property {string} errorIcon - The icon to display for errors.
 * @property {string} successIcon - The icon to display for success.
 * @property {boolean} readOnly - Whether or not the field is read-only.
 */
TextField.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
  noNegative: false,
  errorIcon: "icon-info1",
  successIcon: "icon-check-circle",
  readOnly: false,
};

export default TextField;
