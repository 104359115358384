import React from "react";
import "./PageNotFound.scss";
import { multilanguage } from "redux-multilanguage";

/**
 * A functional component that displays a "Page Not Found" message and a button to go back to the previous page.
 * @param {Object} strings - An object containing localized strings for the component.
 * @returns A JSX element that displays the "Page Not Found" message and a button to go back to the previous page.
 */
const PageNotFound = ({ strings }) => {
  return (
    <div className="goBackMainPage">
      <div className="goBackLogo">
        <img src="https://res.cloudinary.com/zudu/image/upload/v1658127570/Birlinn/Web/Project-Images/goBackLogo.png" />
      </div>
      <div className="goBackHeading">{strings["Opps"]}</div>
      <div className="goBackText">{strings["PAGE"]}</div>
      <div className="goBackButton">
        <button
          className="goBackButton-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          {strings["GO_BACK"]}
        </button>
      </div>
    </div>
  );
};

/**
 * A higher-order component that wraps the PageNotFound component and provides it with
 * multilingual support.
 * @param {PageNotFound} PageNotFound - The PageNotFound component to wrap.
 * @returns The wrapped PageNotFound component with multilingual support.
 */
export default multilanguage(PageNotFound);
