import { USER_INFO } from "../types.js";

/**
 * The initial state for the login component.
 * @type {{isLoading: boolean, logininfo: {}}}
 */
const initState = {
    isLoading: false,
    logininfo: {},
};

/**
 * Reducer function for handling authentication related actions.
 * @param {Object} state - The current state of the application.
 * @param {Object} action - The action object that contains a type and payload.
 * @returns The updated state of the application.
 */
const authReducer = (state = initState, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                userinfo: action.payload
            }
        default:
    }
    return state;
};

export default authReducer