/**
 * Checks if a value is defined and not null or an empty string. If the value is defined,
 * it is appended to the given params string.
 * @param {string} params - the string of parameters to append the value to
 * @param {*} value - the value to check and append to the params string
 * @returns {string} - the updated params string with the value appended, or an empty string if the value is undefined, null, or an empty string.
 */
export function isCheckValueAndSetParams(params, value) {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return ''
  }
  return params + value
}

/**
 * Downloads a file from the given link.
 * @param {string} link - The URL of the file to download.
 * @returns None
 */
export const downloadFile = (link) => {
  fetch(link)
    .then(response => {
      let filename = response.url.split('/')
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename[filename.length - 1];
        a.click();
      });
    });
}
/**
 * Capitalizes the first letter of a given string and makes the rest of the letters lowercase.
 * @param {string} string - The string to capitalize.
 * @returns The capitalized string.
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
/**
 * Uploads a file to Cloudinary using the Cloudinary API.
 * @param {File} file - The file to upload.
 * @returns {string} The URL of the uploaded file.
 * @throws {Error} If the upload fails.
 */
export const uploadFIle = async (file) => {
  var formdata = new FormData();
  formdata.append("file", file);
  formdata.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
  formdata.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
  let res = await fetch(
    "https://api.cloudinary.com/v1_1/scotlandbythebook/upload",
    {
      method: "post",
      mode: "cors",
      body: formdata
    }
  );

  let json = await res.json();
  const imageURL = json.secure_url
  return imageURL;
}

