import { SET_LOADER } from '../actions/loaderActions';

/**
 * The initial state for a component that uses isLoading to track whether it is currently loading.
 * @type {Object}
 * @property {boolean} isLoading - Whether the component is currently loading.
 */
const initState = {
    isLoading: false
};

/**
 * Reducer function for the loader state.
 * @param {Object} state - The current state of the loader.
 * @param {Object} action - The action object that contains the type and payload.
 * @param {string} action.type - The type of action being performed.
 * @param {boolean} action.payload - The payload of the action, which is a boolean indicating whether the loader is active or not.
 * @returns The new state of the loader.
 */
const loaderReducer = (state = initState, action) => {
    if (action.type === SET_LOADER) {
        return {
            ...state,
            isLoading: action.payload
        };
    }

    return state;
};

export default loaderReducer;


