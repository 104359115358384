import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './routes';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect } from 'react-redux';
// import ReactGA from 'react-ga';
import ToastComponent from './assets/components/UI/ToastComponent/ToastComponent';
import { hideToast } from "../src/redux/actions/toastAction";
import { useSelector } from 'react-redux';
import Loader from './Loader/Loader';

function App(props) {
    const state = useSelector((state) => state);
    /**
     * Runs an effect that loads the English language translations into the Redux store.
     * @param {object} props - The props object containing the dispatch function.
     * @returns None
     */
    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require('./translations/english.json')
                }
            })
        );
    }, []);

    // useEffect(() => {
    //     ReactGA.initialize('G-9LJNVTMRG2');
    // }, []);

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }, []);

    // const handleButtonClick = () => {
    //     ReactGA.event({
    //         category: 'User',
    //         action: 'Logged in',
    //         label: 'Google'
    //     });
    // };

    /**
     * Sets an event listener for the window's onbeforeunload event. When the event is triggered,
     * the hideToast function from props is called.
     * @param {Event} e - The event object.
     * @returns None
     */
    window.onbeforeunload = (e) => {
        props.hideToast();
    };

    return (
        <div className="App">
            <Suspense>
                <ToastComponent />
                <Loader show={state?.mainLoaderReducer?.showLoader} />
                <BrowserRouter>
                    <Routes>
                        {routes.map((route, index) => {
                            return <Route
                                key={index}
                                exact={route.exact}
                                path={route.path}
                                element={route.component}
                            />
                        })}
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
    return {}
};

/**
 * Maps the hideToast action creator to the props of the component.
 * @returns An object containing the hideToast action creator.
 */
const mapDispatchToProps = {
    hideToast
};

/**
 * Connects the App component to the Redux store using the mapStateToProps and mapDispatchToProps functions.
 * The multilanguage higher-order component is also applied to the App component.
 * @param {Function} mapStateToProps - A function that maps the state properties to the App component's props.
 * @param {Function} mapDispatchToProps - A function that maps the dispatch actions to the App component's props.
 * @returns A connected and multilanguage-wrapped App component.
 */
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));