import React, { useState, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import useWindowDimension from "../../../hooks/useWindowDimension";
import ConfirmModal from "../../../assets/components/UI/ConfirmModal/ConfirmModal";
import MetaTags from 'react-meta-tags';

import "./BookmarkScreen.scss";

/**
 * A functional component that displays a list of bookmarked books, shops, and events.
 * @param {Object} props - The props object containing the strings to be displayed.
 * @returns A JSX element that displays the bookmarked books, shops, and events.
 */
const BookmarkScreen = (props) => {
    let { strings } = props;
    let history = useNavigate();
    const dimension = useWindowDimension();

    const [removeBookMark, setRemoveBookMark] = useState(false);
    const [toastBookMark, setToastBookMark] = useState(false);
    const [seeMoreBook, setSeeMoreBook] = useState(false);
    const [seeMoreShop, setSeeMoreShop] = useState(false);
    const [seeMoreEvent, setSeeMoreEvent] = useState(false);
    // const [eventList, setEventList] = useState([])
    const [selectedBookmark, setSelectedBookmark] = useState()

    const [bookSize, setBookSize] = useState(0);
    const [shopSize, setShopSize] = useState(0);

    /**
     * Sets the height of the shop cards container using the offsetHeight property of the first
     * element with the class name "shop_cards".
     * @returns None
     */
    useEffect(() => {
        setShopSize(document.getElementsByClassName("shop_cards")[0]?.offsetHeight);
    }, []);

    /**
     * Sets the height of the book container element to the height of the first element with the class "recent_books_cards".
     * @returns None
     */
    useEffect(() => {
        setBookSize(document.getElementsByClassName("recent_books_cards")[0]?.offsetHeight);
    }, []);

    /**
     * A hook that returns a stateful value of the current screen size and a function to update it.
     * @returns An array containing the current screen size and a function to update it.
     * The current screen size is an object with a dynamicHeight property.
     */
    const [screenSize, getDimension] = useState({
        dynamicHeight: 0
    });

    const bookMarkBookList = JSON.parse(localStorage.getItem('bookmark_data'));
    const bookmarkBookData = bookMarkBookList?.filter((item) => item.key === "Book")
    const bookmarkShopData = bookMarkBookList?.filter((item) => item.key === "Shop")
    const bookmarkEventData = bookMarkBookList?.filter((item) => item.key === "Event")

    /**
     * Sets the dynamic height of the Recent_events_card element and calls the getDimension function
     * after a 50ms timeout.
     * @returns None
     */
    const setDimension = () => {
        setTimeout(() => getDimension({
            dynamicHeight: (document.getElementsByClassName("Recent_events_card")[0]?.offsetHeight)
        }), 50);
    }

    /**
     * Runs the setDimension function when the seeMoreEvent state changes.
     * @returns None
     */
    useEffect(() => {
        setDimension()
    }, [seeMoreEvent])

    /**
     * Adds an event listener to the window object that listens for a resize event and calls the setDimension function.
     * Removes the event listener when the component unmounts.
     * @returns None
     */
    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    /**
     * Removes the selected bookmark from the bookmark list and updates the local storage.
     * Also sets the state of 'removeBookmark' to false and 'toastBookmark' to true to display a toast message.
     * @returns None
     */
    /**
     * Removes the selected bookmark from the bookmark list and updates the local storage.
     * Also sets the state of 'removeBookmark' to false and 'toastBookmark' to true to display a toast message.
     * @returns None
     */
    const onPressRemoveBookMark = () => {
        const new_bookmark_book = bookMarkBookList?.filter((item) => item._id !== selectedBookmark._id)
        const newbookmark_bookData = localStorage.setItem('bookmark_data', JSON.stringify(new_bookmark_book));
        setRemoveBookMark(false);
        setToastBookMark(true);
        setTimeout(() => {
            setToastBookMark(false)
        }, 1500);
    };

    /**
     * Renders a list of bookmarked books. If there is no data, a message is displayed.
     * Otherwise, a list of book cards is rendered, each containing information about a bookmarked book.
     * @returns {JSX.Element} - A list of bookmarked books.
     */
    const BookmarkBooks = () => {
        return (
            (bookmarkBookData == undefined || bookmarkBookData?.length === 0) ?
                <div className="no_data">
                    {strings["NODATAFOUND"]}
                </div>
                :
                bookmarkBookData?.map((val, index) => {
                    return <div className="recent_books_cards" key={index} onClick={() => history('/discover', {
                        state: { val: val, type: 'Books', detailType: 'Book', redirectFrom: '/bookmark', trageted: 'detail' }
                    })}>
                        <div className="book_card">
                            <div className="left_side">
                                <img src={val?.profileImage ? val.profileImage : "https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png'"} alt="" />
                            </div>
                            <div className="right_side">
                                <div className="contain">
                                    <p className="date">Published Date: {moment(val.publishDate).format('DD MMM YYYY') ? moment(val.publishDate).format('DD MMM YYYY') : '-'}</p>
                                    <h3 className="book_name">{val.Title}</h3>
                                    <h4 className="novel_name">{val.SubTitle}</h4>
                                    <h4 className="book_author">by {val.authorName ? val.authorName : "-"}</h4>
                                    <h3 className="region_name">{val.regionName}</h3>
                                </div>
                                <div className="bookmark_events">
                                    <span className="icon-heart-fill" onClick={(e) => { setSelectedBookmark(val); e.stopPropagation(); setRemoveBookMark(true) }}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                })
        );
    };

    /**
     * Renders a list of bookmarked shops. If there is no data, a message is displayed.
     * @returns {JSX.Element} - A list of bookmarked shops.
     */
    const BookmarkShops = () => {
        return (
            (bookmarkShopData == undefined || bookmarkShopData?.length === 0) ?
                <div className="no_data">
                    {strings["NODATAFOUND"]}
                </div>
                :
                bookmarkShopData?.map((val, index) => {
                    return <div className="shop_cards" key={index} onClick={() => history('/discover', {
                        state: { val: val, type: 'Shops', detailType: 'Shop', redirectFrom: '/bookmark', trageted: 'detail' }
                    })}>
                        <div className="contain_area">
                            <div className="main_contain">
                                <div className="shop_img">
                                    <img src={val.shopImage ? val.shopImage : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811437.svg'} alt="" />
                                </div>
                                <div className="shop_text">
                                    <div className="time">
                                        {/* <p>{val.time}</p> */}
                                    </div>
                                    <div className="shop_name">
                                        <p>{val.name}</p>
                                    </div>
                                    <div className="region_name">
                                        <p>{val.regionName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bookmark_events">
                                <span className="icon-heart-fill" onClick={(e) => { setSelectedBookmark(val); e.stopPropagation(); setRemoveBookMark(true) }}></span>
                            </div>
                        </div>
                    </div>
                })
        );
    };

    /**
     * Renders a list of bookmarked events. If there is no data to display, a message is shown.
     * @returns {JSX.Element} - A list of bookmarked events or a message if there is no data.
     */
    const BookmarkEvents = () => {
        return (
            (bookmarkEventData == undefined || bookmarkEventData?.length === 0) ?
                <div div className="no_data" >
                    {strings["NODATAFOUND"]}
                </div>
                :
                bookmarkEventData?.map((val, index) => {
                    return <div className="Recent_events_card" key={index} onClick={() => history('/discover', {
                        state: { val: val, type: 'Events', detailType: 'Event', redirectFrom: '/bookmark', trageted: 'detail' }
                    })}>
                        <div className="top_side">
                            <img src={val.profileImage ? val.profileImage : 'https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg'} alt="" />
                        </div>
                        <div className="down_side">
                            <div className="name_bookmark">
                                <div className="event_name">
                                    <h4>{val.name}</h4>
                                </div>
                                <div className="bookmark_events">
                                    <span className="icon-heart-fill" onClick={(e) => { setSelectedBookmark(val); e.stopPropagation(); setRemoveBookMark(true) }}></span>
                                </div>
                            </div>
                            <div className="region_month">
                                <div className="region_name">
                                    <p>{val.regionName}</p>
                                </div>
                                <div className="month_name">
                                    <p>{val.month}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                })
        );
    };

    /**
     * Renders the Bookmarks page, which displays the user's book wishlist, bookmarked events, and mapped out bookshops.
     * @returns {JSX.Element} - The Bookmarks page component.
     */
    return (
        <>
            <MetaTags>
                <title>Bookmarks | Scotland By-The-Book - Birlinn Ltd</title>
                <meta name="description" content="See your books wishlist, bookmark upcoming events you'd like to attend, and map out local and regional bookshops you'd like to visit." />
                <meta property="og:title" content="Bookmarks | Scotland By-The-Book - Birlinn Ltd" />
            </MetaTags>
            <div className="bookmark_main" style={{ height: dimension.height + "px" }}>
                <div className="books">
                    <p className="title">{strings["BOOKS"]}</p>
                    <div className={seeMoreBook === false ? 'selected_books' : 'selected_books' + ' show_More'} style={{ height: bookSize === 0 ? '150' + 'px' : bookSize + 5 + 'px' }}>
                        <BookmarkBooks />
                    </div>
                    {bookmarkBookData?.length > 4 && <div className="more_less">
                        <button className="more_less_book_btn"
                            onClick={() => {
                                setSeeMoreBook(!seeMoreBook)
                            }}>
                            {seeMoreBook === false ? 'See More' : 'Show Less'}
                        </button>
                    </div>}
                </div>
                <div className="shops">
                    <p className="title">{strings["SHOPS"]}</p>
                    <div className={seeMoreShop === false ? 'selected_shops' : 'selected_shops' + ' show_More'} style={{ height: shopSize === 0 ? '150' + 'px' : shopSize + 5 + 'px' }} >
                        <BookmarkShops />
                    </div>
                    {bookmarkShopData?.length > 4 && <div className="more_less">
                        <button className="more_less_shop_btn"
                            onClick={() => {
                                setSeeMoreShop(!seeMoreShop)
                            }}>
                            {seeMoreShop === false ? 'See More' : 'Show Less'}
                        </button>
                    </div>}
                </div>
                <div className="events">
                    <p className="title">{strings["EVENTS"]}</p>
                    <div className={seeMoreEvent === false ? 'selected_events' : 'selected_events' + ' show_More'}
                        style={{ height: screenSize.dynamicHeight === 0 ? '150' + 'px' : screenSize.dynamicHeight + 5 + 'px' }}
                    >
                        <BookmarkEvents />
                    </div>
                    {bookmarkEventData?.length > 4 && <div className="more_less">
                        <button className="more_less_event_btn"
                            onClick={() => {
                                setSeeMoreEvent(!seeMoreEvent)
                            }}>
                            {seeMoreEvent === false ? 'See More' : 'Show Less'}
                        </button>
                    </div>}
                </div>
            </div>

            <ConfirmModal
                leftBtnTitle={strings["ONLYYES"]}
                rightBtnTitle={strings["ONLYNO"]}
                modalTitle={strings["REMOVEBOOKMARK"]}
                modalDescription={strings["SUREREMOVEBOOKMARK"]}
                showModal={removeBookMark}
                onPressLeft={onPressRemoveBookMark}
                onPressRight={() => setRemoveBookMark(false)}
                className="remove_bookmark_popup"
            />

            <Toast className="bookmark_tost" show={toastBookMark} delay={3000} autohide>
                <div className="tostcenter">
                    <span className="tost_icon"><i className="icon icon-coolicon"></i></span>
                    <p className="bookmark_tost_text">{strings["THE_BOOKMARK_REMOVE_SUCCESSFULLY"]}</p>
                </div>
            </Toast>
        </>
    );
};

/**
 * Exports the BookmarkScreen component wrapped in the multilanguage higher-order component.
 * @returns The BookmarkScreen component with multilanguage support.
 */
export default (multilanguage(BookmarkScreen));