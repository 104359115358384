import React, { useEffect, useState } from "react";
import useWindowDimension from "../../../hooks/useWindowDimension";

import "./MapRightSideMenu.scss";

/**
 * A component that renders a right side menu for a map view. 
 * @param {Object} props - The props object for the component.
 * @param {string} props.title - The title of the menu.
 * @param {ReactNode} props.children - The children to render inside the menu.
 * @returns A React component that renders a right side menu for a map view.
 */
const MapRightSideMenu = (props) => {
    const dimension = useWindowDimension();
    const [titlebarHeight, setTitlebarHeight] = useState(0);

    /**
     * Sets the height of the titlebar element and updates the state variable.
     * @returns None
     */
    useEffect(() => {
        setTitlebarHeight(document.getElementsByClassName("titlebar")[0]?.offsetHeight);
    }, []);

    return (
        <>
            <div className={`mainarea_mapsidebar ${'map_sidedata'}`} style={{ height: dimension.height + "px" }}>
                <div className="titlebar">
                    <h3 className="title_text">{props.title}</h3>
                </div>
                <div className="main_contain_data" style={{ height: dimension.height - titlebarHeight + "px" }}>
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default MapRightSideMenu;