/* eslint-disable no-undef */
import React, { useState } from "react";
import { multilanguage } from "redux-multilanguage";
import Constant from "../../../state/utils/constant";
import { Link, useNavigate } from "react-router-dom";
import HookForm from "../../../assets/components/HookForm/HookForm";
import TextField from "../../../assets/components/UI/TextField/TextField";
import CustomButton from "../../../assets/components/UI/CustomButton/CustomButton";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { showToast } from "../../../redux/actions/toastAction";
import { connect } from "react-redux";
import AxiosInstance from "../../../services/axios";
import "./ForgotPassword.scss";

/**
 * A component that renders a form for resetting a user's password.
 * @param {Object} props - The props object containing the strings to be displayed.
 * @returns The ForgotPassword component.
 */
const ForgotPassword = (props) => {
  let { strings } = props;
  const dimensions = useWindowDimension();
  let history = useNavigate();
  const [busy, setBusy] = useState(false);

  /**
   * An object representing a forgot password form field for email.
   * @typedef {Object} ForgotEmailField
   * @property {string} name - The name of the field.
   * @property {Object} validate - An object containing validation rules for the field.
   * @property {Object} validate.required - A required validation rule object.
   * @property {boolean} validate.required.value - A boolean indicating if the field is required.
   * @property {string} validate.required.message - A message to display if the field is required.
   * @property {Object} validate.pattern - A pattern validation rule object.
   * @property {string} validate.pattern.value - A regular expression pattern to validate the field against.
   * @property {string} validate
   */
  const forgot = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: strings["ENTEREMAILADDRESS"],
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: strings["INVALID_EMAIL"],
        },
      },
    },
  };

  /**
   * Handles the submission of a form to reset a user's password.
   * @param {Object} data - An object containing the email address of the user.
   * @returns None
   */
  const onFormSubmit = async (data) => {
    setBusy(true);
    await AxiosInstance.post('/admin/forgotpassword', {
      email: data.email.toLowerCase()
    }).then((response) => {
      props.showToast({
        message:
          response.message,
        type: "success",
      });
      history("/admin/login");
    }).catch((err) => {
      props.showToast({
        message:
          err?.response?.data?.message,
        type: "error",
      });
      setBusy(false);
    })
  };

  return (
    <div className="forgotMain" style={{ height: dimensions.height + "px" }}>
      <img
        style={{ height: dimensions.height }}
        src={Constant.IMAGESURL.BANNER}
        className="logoBack"
        title=""
        alt=""
      />
      <div className="forgotLeft">
        <div className="leftLogo">
          <div>
            <img
              className="titlelogo"
              src={Constant.IMAGESURL.TITLEIMG}
              title=""
              alt=""
            />
            <img
              src={Constant.IMAGESURL.LOGO}
              className="logo"
              title=""
              alt=""
            />
            <img
              src={Constant.IMAGESURL.LASTITLEIMG}
              className="lastlogo"
              title=""
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="forgotRight" style={{ height: dimensions.height + "px" }}>
        <div className="whiteBox">
          <img
            className="logo"
            src={Constant.IMAGESURL.INFOIMG}
            title=""
            alt=""
          />
          <p className="alignCenter">
            <Link to="/admin/login">
              <i className="icon icon-arrow-left"></i>
            </Link>
          </p>
          <h1>{strings["FORGOTPASSWORDTITLE"]}</h1>
          <p>{strings["PASSWORDRESETLINK"]}</p>
          <HookForm
            defaultForm={{}}
            onSubmit={(e) => onFormSubmit(e)}
          >
            {(formMethod) => {
              return (
                <>
                  <TextField
                    formMethod={formMethod}
                    rules={forgot.email.validate}
                    name={forgot.email.name}
                    errors={formMethod?.formState?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder={strings["EMAILADDRESS"]}
                    leftIconName={"user"}
                  />
                  <div className="custombtnfield">
                    <CustomButton
                      type="submit"
                      title={strings["SUBMIT"]}
                      disabled={!formMethod?.formState.isValid}
                      className="custombtnfield"
                    />
                  </div>
                </>
              );
            }}
          </HookForm>
        </div>
      </div>
    </div>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * An object containing the `showToast` action creator function, which can be used to dispatch
 * a `showToast` action to the Redux store.
 * @returns An object with the `showToast` action creator function.
 */
const mapDispatchToProps = { showToast };

/**
 * Connects the ForgotPassword component to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps state from the Redux store to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns A connected component that has access to the Redux store and multilanguage props.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(ForgotPassword));
