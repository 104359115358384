import axios from "axios";
import { get } from "lodash";
import JwtService from "./jwt.service";

/**
 * Creates an Axios instance with the base URL set to the value of the REACT_APP_BASE_URL
 * environment variable.
 * @returns {AxiosInstance} - An instance of Axios with the base URL set.
 */
const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});
let isRefreshing = false;
let failedQueue = [];

// Process all failed request 
/**
 * Processes the failedQueue array by either rejecting or resolving each promise in the array.
 * If an error is provided, all promises in the array will be rejected with that error. Otherwise,
 * all promises will be resolved with the provided token.
 * @param {Error} error - The error to reject the promises with. If null, the promises will be resolved.
 * @param {any} [token=null] - The token to resolve the promises with. If null, the promises will be rejected.
 * @returns None
 */
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error)
            prom.reject(error);
        else
            prom.resolve(token);
    });
    failedQueue = [];
};

/**
 * Axios interceptor that adds the JWT token to the request headers if it exists.
 * @param {Object} config - The Axios request configuration object.
 * @returns The modified Axios request configuration object with the JWT token added to the headers.
 */
AxiosInstance.interceptors.request.use(config => {
    if (JwtService.getToken())
        config.headers['Authorization'] = JwtService.getToken();
    return config;
});

/**
 * Intercepts Axios responses and handles 401 errors by refreshing the access token and retrying the request.
 * @param {Object} response - The Axios response object.
 * @param {Object} error - The Axios error object.
 * @returns The response data or a rejected promise.
 */
AxiosInstance.interceptors.response.use(
    response => response.data,
    error => {
        const originalRequest = error.config;
        const responseStatus = get(error, "response.status", "")

        // If refresh token fails
        if (responseStatus === 401 && error.config.url.indexOf("refresh-token") !== -1) {
            processQueue(error, null);
            isRefreshing = false;
            return Promise.reject(error);
        }

        if (responseStatus === 401 && error.config.url.indexOf("login") !== -1) {
            return Promise.reject(error);
        }

        // Check if original request 
        if (responseStatus === 401 && !originalRequest._retry) {
            // Push all the failed request due to expired token in queue
            if (isRefreshing) {
                return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(token => {
                        originalRequest.headers["Authorization"] = "Token " + token;
                        return AxiosInstance(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // Try to refresh token
            return new Promise((resolve, reject) => {
                AxiosInstance
                    .post(`${process.env.REACT_APP_BASE_URL}/admin/refresh-tokens`, { refreshToken: JwtService.getRefreshToken() })
                    .then(response => {
                        JwtService.saveToken(response.payload.tokens);
                        axios.defaults.headers.common["Authorization"] = response.payload.tokens.access.token;
                        originalRequest.headers["Authorization"] = response.payload.tokens.access.token;
                        processQueue(null, response.payload.tokens.access.token);
                        resolve(axios(originalRequest));
                    })
                    /* 
                        On error proccess old failed request with token value null
                        and redirect to respective authentication page
                    */
                    .catch(err => {
                        JwtService.destroyToken();
                        processQueue(err, null);
                        reject(err);
                        window.location.href = '/admin/login'
                        // router.push({ name: "signin" });
                    })
                    /* 
                        Finally set isRefreshing token to false in either success or failure
                    */
                    .finally(() => isRefreshing = false);
            });
        }

        return Promise.reject(error);
    }
);

/**
 * Set the default HTTP request headers
 */
// const setHeader = () => {
//     AxiosInstance.defaults.headers.common["Authorization"] = `Token ${JwtService.getToken()}`;
// }

export default AxiosInstance