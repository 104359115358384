const Constant = {
  REGEX: {
    //eslint-disable-next-line
    EMAIL:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //eslint-disable-next-line
    SPECIALCHARACTERS: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    UPPERCASE: /.*[A-Z]|[A-Z]/,
    LOWERCASE: /.*[a-z]|[a-z]/,
    NUMBER: /[0-9]/,
    NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
    ONLYNUMBER: /^-?[0-9]*$/,
    NECNUMBER: /^[0-9]{10}TMP$/,
    priceNumber: /^\d{0,4}(\.\d{1,4})?$/,
    URL: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
    changePassword: / ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$/,
    c: /^\d{1,9}$/
  },
  IMAGESURL: {
    BANNER:
      "https://res.cloudinary.com/zudu/image/upload/v1654764712/Birlinn/Web/Project-Images/background.svg",
    LOGO: "https://res.cloudinary.com/zudu/image/upload/v1654664375/Birlinn/Web/Project-Images/Map_-_Image.svg",
    TITLEIMG:
      "https://res.cloudinary.com/zudu/image/upload/v1654582151/Birlinn/Web/Project-Images/scotland-book.svg",
    LASTITLEIMG:
      "https://res.cloudinary.com/zudu/image/upload/v1654582153/Birlinn/Web/Project-Images/birlinn-logo.svg",
    // KEYIMG: 'https://res.cloudinary.com/zudu/image/upload/v1651740325/Cloud9/Cloud9-Web/Project-images/key.png',
  },
  GOOGLEMAPAPI: {
    APIKEY: "AIzaSyDYiRK54loDXDLRIxB9nl5R8fvSozD2PPM", //from zududigital acccount project zudu
  },
  // GOOGLEMAPAPI: {
  //   APIKEY: "AIzaSyDS7ikYinMzgjvPlfNon2bFlcjwofR4hFs"
  // },
};
export default Constant;
