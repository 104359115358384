import moment from "moment";
import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { multilanguage } from "redux-multilanguage";
import ConfirmModal from "../../../assets/components/UI/ConfirmModal/ConfirmModal";
// import Images from "../../../assets/IMGS/Images";
import useWindowDimension from "../../../hooks/useWindowDimension";
import parse from 'html-react-parser';


import "./RightSideMenu.scss";


/**
 * A component that displays a right side menu with information about a location, including a map, media, and a list of books.
 * @param {Object} props - The props object containing the following properties:
 *   @param {Object} strings - An object containing localized strings.
 *   @param {Object} pinsData - An object containing information about the location, including a profile image, audio, video, and a list of books.
 *   @param {Function} iconClose - A function to close the menu.
 *   @param {Function} modelShow - A function to show a model.
 *   @param {Function} mapShow - A function to show a map.
 *   @param {Function}
 */
const RightSideMenu = (props) => {
    let { strings } = props;
    const dimension = useWindowDimension();
    const [titlebarHeight, setTitlebarHeight] = useState(0);
    const [selectedItem, setSelectedItem] = useState();
    const [toastBookMark, setToastBookMark] = useState(false);
    const [removeBookMark, setRemoveBookMark] = useState(false);
    const [bookMark, setBookMark] = useState(false);
    const [readText, setReadText] = useState(false);
    /**
     * Removes a bookmark from local storage and updates the state of the bookmark and remove bookmark buttons.
     * @param {Event} e - The event object.
     * @returns None
     */
    const onPressRemoveBookMark = (e) => {
        const getbookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
        if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
            const newData = getbookMarkBookArr.filter((item) => selectedItem._id !== item._id)
            localStorage.setItem('bookmark_data', JSON.stringify(newData))
        }
        setBookMark(false);
        setRemoveBookMark(false);
        setToastBookMark(true);
        setTimeout(() => {
            setToastBookMark(false)
        }, 1500);
    };


    /**
     * Closes the icon.
     * @param {Event} e - The event object.
     * @returns None
     */
    const Close = (e) => {
        e.preventDefault();
        props.iconClose()
    };

    /**
     * A function that triggers the model to show.
     * @param {Event} e - The event object.
     * @returns None
     */
    const Show = (e) => {
        e.preventDefault();
        props.modelShow()
    };

    /**
     * Event handler function that prevents the default behavior of the event and calls the
     * `mapShow` function passed in as a prop.
     * @param {Event} e - The event object.
     * @returns None
     */
    const Map = (e) => {
        e.preventDefault();
        props.mapShow()
    };

    /**
     * Calls the DetailsBook function with the given value and a key of "Book".
     * @param {Event} e - The event object.
     * @param {Object} val - The value to pass to the DetailsBook function.
     * @returns None
     */
    const Book = (e, val) => {
        e.preventDefault();
        val['key'] = "Book"
        props.DetailsBook(val)
    };

    /**
     * Renders a list of books with their respective details and bookmark status.
     * @returns {JSX.Element} - A list of book cards with their details and bookmark status.
     */
    const BooksData = () => {
        let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];
        return (
            props.pinsData.BooksData.map((val, index) => {
                val['bookmark'] = localstorageBooks?.some((item) => item._id === val._id)
                val['key'] = 'Book'

                return <>
                    <div className="recent_books_cards"
                        key={index}
                        onClick={(e) => {
                            Book(e, val)
                        }}>
                        <div className="book_card">
                            <div className="left_side">
                                <img src={val.profileImage ? val.profileImage : "https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png'"} alt="" />
                            </div>
                            <div className="right_side">
                                <div className="contain">
                                    <p className="date">{strings["PUBLISHED_DATE"]}:{moment(val.publishDate).format('DD MMM YYYY') ? moment(val.publishDate).format('DD MMM YYYY') : '-'}</p>
                                    <h3 className="book_name">{val.Title}</h3>
                                    <h4 className="novel_name">{val.SubTitle ? val.SubTitle : ''}</h4>
                                    <h4 className="book_author">by {val.authorName ? val.authorName : '-'}</h4>
                                    <h3 className="region_name">{val.regionName}</h3>
                                </div>
                                <div className="bookmark_area">
                                    {<span className={val?.bookmark ? "icon-heart-fill" : "icon-fav"}
                                        onClick={(e) => {
                                            setSelectedItem(val)
                                            if (val?.bookmark && val?.bookmark !== undefined) {
                                                setRemoveBookMark(true)
                                            } else {
                                                let bookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                                                const item = val
                                                item.bookmark = true
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(item)
                                                    localStorage.setItem('bookmark_data', JSON.stringify(bookMarkBookArr));
                                                } else {
                                                    localStorage.setItem('bookmark_data', JSON.stringify([item]));
                                                }
                                            }
                                            e.stopPropagation();
                                            props.setSidebarBookMark(!props.bookMark)
                                        }}></span>}
                                    {/* {props.bookMark && <span className="icon-heart-fill"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.setRemoveSidebarBookMark(props.bookMark);
                                        }}></span>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })
        );
    };

    /**
     * A React hook that sets the height of the titlebar element.
     * @returns None
     */
    useEffect(() => {
        setTitlebarHeight(document.getElementsByClassName("titlebar")[0]?.offsetHeight);
    }, []);

    return (
        <>
            <div className="mainarea" style={{ height: dimension.height + "px" }}>
                <div className="titlebar">
                    <a className="back_arrow" onClick={(e) => { Close(e) }} href="/"><i className="icon icon-chevron-left"></i></a>
                    <h3 className="title_text">{props.pinsData.name}</h3>
                </div>
                <div className="main_contain" style={{ height: dimension.height - titlebarHeight + "px" }}>
                    <div className="regions_map">
                        <div className="regions_img">
                            <img className="image" alt="/" src={props.pinsData.profileImage} ></img>
                        </div>
                    </div>
                    <div className="buttons">
                        {props.pinsData.audio ? <a href={props.pinsData.audio} target="_blank" rel="noreferrer"><i className="icon icon-headphones"></i></a> : ''}
                        <a href="/"
                            onClick={(e) => { Map(e) }}
                        >
                            {strings["BOOKS"]}
                        </a>
                    </div>
                    <div className="text_body" >
                        <p className={`description ${readText ? "show" : ''}`}>
                            {/* <ReadMore>
                                {parse(parse(props.pinsData.about))}
                            </ReadMore> */}
                            {parse(parse(props.pinsData.about))}
                        </p>
                        <span onClick={() => setReadText(!readText)} className="Read_or_hide_btn">
                            {!readText ? "....Read More" : "Show Less"}
                        </span>
                    </div>
                    <div className="right_side_books_list">
                        <p className="books_list_highlight_title">Recently published</p>
                        <BooksData />
                    </div>
                    {props.pinsData.video ? <div className="media">
                        {props.pinsData.video ? <p>Media</p> : ''}
                        <div className="video">
                            <iframe width="100%" height="auto" src={props.pinsData.video}
                                // src={`${process.env.REACT_APP_CLOUDINARY_URL}${profileImage}`}
                                title="YouTube video player"
                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>
                            <div className="popup" onClick={(e) => { Show(e) }}>

                            </div>
                        </div>
                    </div> : ''}
                </div>
            </div>

            <ConfirmModal
                leftBtnTitle={strings["ONLYYES"]}
                rightBtnTitle={strings["ONLYNO"]}
                modalTitle={strings["REMOVEBOOKMARK"]}
                modalDescription={strings["SUREREMOVEBOOKMARK"]}
                showModal={removeBookMark}
                onPressLeft={onPressRemoveBookMark}
                onPressRight={() => setRemoveBookMark(false)}
                className="remove_bookmark_popup"
            />

            <Toast className="bookmark_tost" show={toastBookMark} delay={3000} autohide>
                <div className="tostcenter">
                    <span className="tost_icon"><i className="icon icon-coolicon"></i></span>
                    <p className="bookmark_tost_text">{strings["THE_BOOKMARK_REMOVE_SUCCESSFULLY"]}</p>
                </div>
            </Toast>

        </>
    );
};

export default (multilanguage(RightSideMenu));


