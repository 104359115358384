import React from 'react';
import { Button } from 'react-bootstrap';
import Loader from '../../../../assets/images/giphy.gif';
import './CustomButton.scss';

/**
 * A custom button component that can be used in place of the standard HTML button.
 * @param {Object} props - The props object containing the button's properties.
 * @param {string} props.title - The title of the button.
 * @param {ReactNode} props.children - The child elements of the button.
 * @param {boolean} props.disabled - Whether or not the button is disabled.
 * @param {string} props.type - The type of the button.
 * @param {boolean} props.loading - Whether or not the button is in a loading state.
 * @param {string} props.className - The class name of the button.
 * @param {Function} props.onKeyDown - The function to call when
 */
const CustomButton = (props) => {
    const { title, children, disabled, type, loading, className } = props;

    return (
        <Button
            className={loading ? 'custombtn btn-loading ' + className : 'custombtn ' + className}
            type={type} onKeyDown={props.onKeyDown} disabled={disabled || loading} onClick={props.onClick} >
            {children}
            {loading ? <div className="btn-loader"><img src={Loader} alt="loader" height={20} width={20} /></div > : title}
        </Button>
    );
};

/**
 * Default props for the CustomButton component.
 * @param {string} className - The class name for the button.
 * @param {boolean} loading - Whether or not the button is in a loading state.
 * @returns An object containing the default props for the CustomButton component.
 */
CustomButton.defaultProps = {
    className: '',
    loading: false
};

export default CustomButton;
