const Images = {
    IMAGES: {
        LOGO: "https://res.cloudinary.com/zudu/image/upload/v1649225810/Birlinn/App/Project-images/logo.svg",
        BIRLINNLIMITED: "https://res.cloudinary.com/zudu/image/upload/v1656320427/Birlinn/App/Project-images/BirlinnLimited.svg",
    },
    VECTOR: {
        SHIP1: "https://res.cloudinary.com/zudu/image/upload/v1656320756/Birlinn/App/Project-images/ship-1.svg",
        SHIP2: "https://res.cloudinary.com/zudu/image/upload/v1656320756/Birlinn/App/Project-images/ship-2.svg",
        TOWER: "https://res.cloudinary.com/zudu/image/upload/v1649065503/Birlinn/App/Project-images/frame.svg",
        CASTLE: "https://res.cloudinary.com/zudu/image/upload/v1656320756/Birlinn/App/Project-images/castle.svg",
        FISH: "https://res.cloudinary.com/zudu/image/upload/v1656320756/Birlinn/App/Project-images/fish.svg"
    },

    DISCOVER: {
        MAP: "https://res.cloudinary.com/zudu/image/upload/v1656500054/Birlinn/Web/Map.svg"
    },

    REGION: {
        EDINBURGHCITY: "https://res.cloudinary.com/zudu/image/upload/v1649676793/Birlinn/App/Project-images/Region/EdinburghCity.png"
    },

    DIRECTORY: {
        // BOOKSCARD: 'https://res.cloudinary.com/scotlandbythebook/image/upload/w_350,h_150/v1655978805/Birlinn/Discovery%20Page%20Images/Books_j8dk46.jpg',
        // EVENTSCARD: 'https://res.cloudinary.com/scotlandbythebook/image/upload/w_350,h_150/v1655978798/Birlinn/Discovery%20Page%20Images/Events_lhpfmd.jpg',
        // SHOPCARD: 'https://res.cloudinary.com/scotlandbythebook/image/upload/w_350,h_150/v1655978804/Birlinn/Discovery%20Page%20Images/Bookshops_nlevqr.jpg'
        BOOKSCARD: 'https://res.cloudinary.com/scotlandbythebook/image/upload/v1655978805/Birlinn/Discovery%20Page%20Images/Books_j8dk46.jpg',
        EVENTSCARD: 'https://res.cloudinary.com/scotlandbythebook/image/upload/v1655978798/Birlinn/Discovery%20Page%20Images/Events_lhpfmd.jpg',
        SHOPCARD: 'https://res.cloudinary.com/scotlandbythebook/image/upload/v1655978804/Birlinn/Discovery%20Page%20Images/Bookshops_nlevqr.jpg'
    }
};

export default Images;