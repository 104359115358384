import { SHOW_LOADER, HIDE_LOADER } from "../actions/loaderActions";
/**
 * The initial state for the loader component.
 */
const initState = {
    showLoader: false,
};

/**
 * Reducer function for the main loader state.
 * @param {Object} state - The current state of the loader.
 * @param {Object} action - The action object that contains the type of action to perform.
 * @returns The new state of the loader.
 */
const mainLoaderReducer = (state = initState, action) => {
    if (action.type === SHOW_LOADER) {
        return {
            ...state,
            showLoader: true,
        };
    }
    if (action.type === HIDE_LOADER) {
        return {
            ...state,
            showLoader: false,
        };
    }

    return state;
};

export default mainLoaderReducer;
