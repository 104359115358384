import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { hideToast } from '../../../../redux/actions/toastAction';
import { Toast } from 'react-bootstrap';

import './ToastComponent.scss';

const ToastComponent = (props) => {

    const { toast: { type, message, show } } = props;
    const [showToast, setShowToast] = useState(false);

    /**
     * useEffect hook that updates the state of the toast component when the 'show' prop changes.
     * @param {boolean} show - The boolean value that determines whether or not to show the toast component.
     * @returns None
     */
    useEffect(() => {
        setShowToast(show)
    }, [show])

    /**
     * Determines the appropriate CSS class for the given toast type.
     * @param {string} type - The type of toast (success, error, warning, or default).
     * @returns {string} The CSS class to apply to the toast.
     */
    let toastClass;
    if (type === 'success') {
        toastClass = 'success-txt';
    } else if (type === 'error') {
        toastClass = 'error-txt';
    } else if (type === 'warning') {
        toastClass = 'warning-txt';
    } else {
        toastClass = '';
    }
    return (
        <Toast onClose={() => props.hideToast()} className={toastClass} show={showToast} delay={3000} autohide>
            <div className="tostcenter">
                {type !== '' ? type === 'success' ? <i className='icon icon-check-circle' /> : <i className='icon icon-info1' /> : <></>}
                <p className={type === 'success' ? 'successTag' : 'errorTag'}>{message}</p>
            </div>
        </Toast>
    )
}

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns {Object} An object containing the toast state from the Redux store.
 */
const mapStateToProps = (state) => {
    return {
        toast: state.toastReducer.toast
    }
};
/**
 * An object containing action creator functions to be passed to the `connect` function
 * in order to dispatch actions to the Redux store.
 */
const mapDispatchToProps = {
    hideToast
};
/**
 * Connects the ToastComponent to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns A connected ToastComponent with multilanguage support.
 */
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ToastComponent));