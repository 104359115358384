import React, { useState, useEffect, useRef, useReducer, useMemo } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Dropdown, Offcanvas } from "react-bootstrap";

import DropdownButton from "react-bootstrap/DropdownButton";

import { showToast } from "../../redux/actions/toastAction";
import useWindowDimension from "../../hooks/useWindowDimension";
import Constant from "../../state/utils/constant";

import SearchBox from "../../assets/components/UI/SearchBox/SearchBox";
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../assets/components/UI/CustomDropdown/CustomDropdown";
import HookForm from "../../assets/components/HookForm/HookForm";
import CheckBox from "../../assets/components/UI/CheckBox/CheckBox";
import Label from "../../assets/components/UI/Label/Label";
import ModalPopup from "../../assets/components/UI/ModalPopup/ModalPopup";
import TextField from "../../assets/components/UI/TextField/TextField";
import ConfirmModal from "../../assets/components/UI/ConfirmModal/ConfirmModal";
import FileUpload from "../../assets/components/UI/FileUpload/FileUpload";
import GooglePlaceDropDown from "../../assets/components/GooglePlaceDropDown/GooglePlaceDropDown";


import parse from 'html-react-parser';
import ReactQuill from "react-quill";
import { get } from "lodash";
import { uploadFIle } from "../../services/helper.service";
import AxiosInstance from "../../services/axios";
import { showLoader, hideLoader } from "../../redux/actions/loaderActions";
import MetaTags from 'react-meta-tags';

import "./Events.scss";

const Events = (props) => {
  const quillRef = useRef();
  const dimensions = useWindowDimension();
  let history = useNavigate();
  let { strings, showToast } = props;
  const [searchText, setsearchText] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [stepForm, SetStepForm] = useState();
  const [businessCategoryList, setBusinessCategoryList] = useState([]);
  const [shopsortCategory, setShopsortCategory] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showlistDetails, setShowListDetails] = useState(false);
  const [shopDetail, setShopDetail] = useState(null);
  const [busy, setBusy] = useState(false);
  const [portLocation, setPortLocation] = useState(null);
  const [changeshow, setChangeShow] = useState(false);
  const [MonthType, setMonthType] = useState(null);
  const [monthCategoryList, setMonthCategoryList] = useState([]);
  const [showEnableDisableModal, setShowEnableDisableModal] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [bookType, setBookType] = useState(null);
  const [body, setBody] = useState("");
  const [bookCategoryList, setBookCategoryList] = useState([]);

  const [showImportModal, setShowImportModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [csvFile, setCSVFile] = useState(null);
  const [csvFileName, setCSVFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [headingHeight, setHeadingHeight] = useState(0);

  const [events, setEvents] = useState([]);
  const [filteredEvent, setFilteredEvent] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventRegion, setSelectedEventRegion] = useState([]);
  const [eventRegion, setEventRegion] = useState([]);
  const [eventshow, setEventShow] = useState([]);
  const [shopsortList, setShopsortList] = useState([]);
  const [location, setLocation] = useState(null);
  const [checkApply, setCheckApply] = useState(false);
  const [value, setValue] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [textEditor, setTextEditor] = useState('')
  const [textEditorDelta, setTextEditorDelta] = useState('')

  let limit = 12;

  /**
   * Scrolls the window to the top of the page with a smooth animation.
   * @returns None
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  const [dropdownShow, setDropdownShow] = useState(false);

  /**
   * The initial state for a pagination component.
   * @type {Object}
   * @property {number} page - The current page number.
   * @property {boolean} emptyList - A flag indicating whether the list is empty.
   */
  const initialState = {
    page: 1,
    emptyList: false
  }

  /**
   * A reducer function that takes in the current state and an action and returns a new state.
   * @param {object} state - The current state of the application.
   * @param {object} action - The action to be performed on the state.
   * @param {string} action.type - The type of action to be performed.
   * @returns {object} - The new state of the application.
   * @throws {Error} - If the action type is not recognized.
   */
  function reducer(state, action) {
    if (action.type === 'INCREMENT_PAGE') {
      return { ...state, page: state.page + 1 }
    } if (action.type === 'RESET_PAGE') {
      return { ...state, page: state.page = 1, emptyList: false }
    } if (action.type === 'EMPTY_LIST') {
      return { ...state, emptyList: true }
    }
    throw new Error(' no action type')
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  /**
   * Runs the necessary functions to set the state of the component when it mounts.
   * @returns None
   */
  useEffect(() => {
    setBusinessCategoryList(businessCategoryData);
    setMonthCategoryList(monthCategories);
    getEventRegion();
    setShopsortList(shopSortData);
    setHeadingHeight(
      document.getElementsByClassName("headingHeight")[0]?.offsetHeight
    );

  }, []);

  /**
   * Runs the getEvents function with the current search text, filtered event, and selected event ID
   * whenever any of these values change.
   * @param {function} getEvents - the function to run to get the events
   * @param {string} [searchText=''] - the text to search for in the events
   * @param {string} [filteredEvent=''] - the event to filter by
   * @param {string} [selectedEventId=''] - the ID of the selected event
   * @param {number} state.page - the current page number
   * @returns None
   */
  useEffect(() => {
    getEvents(searchText ? searchText : '', filteredEvent ? filteredEvent : '', selectedEventId ? selectedEventId : '');
  }, [selectedEventId
    , searchText, state.page, filteredEvent])

  /**
   * Retrieves a list of events based on the given search, sorting, and filtering parameters.
   * @param {string} search - The search query to filter events by.
   * @param {string} sorting - The sorting method to use for the events.
   * @param {string} filtering - The filtering criteria to use for the events.
   * @returns None
   */
  const getEvents = async (search, sorting, filtering) => {
    props.showLoader();
    // let params = `?page=${state.page}&limit=${limit}`;
    let params = `?page=${state.page}&limit=${limit}`;
    if (search) {
      params += `&search=${search}`
    }
    if (sorting) {
      params += `&sortBy=${sorting}`
    }
    if (filtering) {
      params += `&regionIds=${filtering}`
    }
    try {
      await AxiosInstance.get(`admin/event/get${params}`).
        then((response) => {
          props.hideLoader();
          if (state.page === 1) {
            setEvents([])
            scrollToTop();
            setEvents(response?.payload)
          } else {
            if (response?.payload?.length === 0) {
              dispatch({ type: 'EMPTY_LIST' })
            }
            if (response?.payload.length > 0) {
              setEvents([...events, ...response?.payload]);
            } else {
              if (state.page === 1) {
                setEvents([])
              }
            }
          }
        })
    } catch (error) {
      props.hideLoader();
    }
  };
  /**
   * Retrieves the event region from the server and sets the state of the component with the response.
   * @returns None
   */
  const getEventRegion = async () => {
    await AxiosInstance.get('admin/region/getFilter').then((response) => {
      setEventRegion(response.payload);
      const addEventRegion = response.payload.map((item) => {
        return { id: item._id, value: item.name, title: item.name }
      })
      setBookCategoryList(addEventRegion);
    })
  }
  const monthCategories = [
    {
      id: "b01",
      title: "January",
      value: "January",
    },
    {
      id: "b02",
      title: "February",
      value: "February",
    },
    {
      id: "b03",
      title: "March",
      value: "March",
    },
    {
      id: "b04",
      title: "April",
      value: "April",
    },
    {
      id: "b05",
      title: "May",
      value: "May",
    },
    {
      id: "b06",
      title: "June",
      value: "June",
    },
    {
      id: "b07",
      title: "July",
      value: "July",
    },
    {
      id: "b08",
      title: "August",
      value: "August",
    },
    {
      id: "b09",
      title: "September",
      value: "September",
    },
    {
      id: "b010",
      title: "October",
      value: "October",
    },
    {
      id: "b011",
      title: "November",
      value: "November",
    },
    {
      id: "b012",
      title: "December",
      value: "December",
    },
  ];
  /**
   * An object representing the fields and validation rules for a step form.
   * @typedef {Object} StepForm
   * @property {Object} fullName - The full name field of the form.
   * @property {string} fullName.name - The name of the full name field.
   * @property {Object} fullName.validate - The validation rules for the full name field.
   * @property {Object} fullName.validate.required - The required validation rule for the full name field.
   * @property {boolean} fullName.validate.required.value - The value of the required validation rule for the full name field.
   * @property {string} fullName.validate.required.message - The error message to display if the required validation rule fails for the full name field.
   * @property
   */
  const stepform = {
    fullName: {
      name: "fullName",
      validate: {
        required: {
          value: true,
          message: strings["INVALID_NAME"],
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: strings["ONLY_ALPHABETICAL_CHARACTERS"],
        },
      },
    },
    website: {
      name: "website",
      validate: {
        required: {
          value: false,
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: strings["INVALID_URL"],
        },
      },
    },
    region: {
      name: "region",
      validate: {
        required: {
          value: true,
          message: strings["INVALID_REGION"],
        },
      },
    },
    month: {
      name: "month",
      validate: {
        required: {
          value: true,
          message: strings["INVALID_REGION"],
        },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: true,
          message: strings["INVALID_ADDRESS"],
        },
      },
    },
    location: {
      name: "location",
      validate: {
        required: {
          value: true,
        },
      },
    },
    description: {
      name: "description",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };
  const shopSortData = [
    {
      id: "1",
      title: "Ascending (A-Z)",
      value: "Ascending (A-Z)",
      sortValue: "name:asc"
    },
    {
      id: "2",
      title: "Descending (Z-A)",
      value: "Descending (Z-A)",
      sortValue: "name:desc"
    },
  ];

  const businessCategoryData = [
    {
      id: "1",
      title: "Eco products",
      value: "Eco products",
    },
    {
      id: "2",
      title: "Endorsed green energy",
      value: "Endorsed green energy",
    },
    {
      id: "3",
      title: "Ecological",
      value: "Ecological",
    },
    {
      id: "4",
      title: "Conservation body membership",
      value: "Conservation body membership",
    },
  ];

  /**
   * Handles the deletion of a book by updating its status to "Deleted" in the database.
   * @param {string} deletedId - The ID of the book to be deleted.
   * @returns None
   */
  const onPressDeleteBook = async (deletedId) => {
    setShowDeleteModal(false);
    await AxiosInstance.put(`admin/event/updatestatus?eventId=${deletedId}`, {
      status: 'Deleted',
    }).then((response) => {
      props.showToast({
        message: response.message,
        type: "success",
      });
      dispatch({ type: 'RESET_PAGE' })
      getEvents(searchText ? searchText : '', filteredEvent ? filteredEvent : '', selectedEventId ? selectedEventId : '');
    }).catch((error) => {
    })
    setShowListDetails(showlistDetails ? false : showlistDetails);
  };


  /**
   * Handles the enabling or disabling of an event by sending a PUT request to the server.
   * @param {string} disabledId - The ID of the event to enable or disable.
   * @param {string} eventStatus - The current status of the event.
   * @returns None
   */
  const onPressEnableDisable = async (disabledId, eventStatus) => {
    setShowEnableDisableModal(false);
    setShowListDetails(false);
    let params = {}
    if (eventStatus === "Enabled") {
      params.status = "Disabled"
    } else {
      params.status = "Enabled"
    }
    await AxiosInstance.put(`admin/event/edit?eventId=${disabledId}`, params)
      .then((response) => {
        props.showToast({
          message: shopDetail?.status === "Enabled"
            ? "The event has been disabled successfully."
            : "The event has been enabled successfully.",
          type: "success",
        });
        getEvents(searchText ? searchText : '', filteredEvent ? filteredEvent : '', selectedEventId ? selectedEventId : '');
      }).catch((error => {
      }))
  };
  /**
   * Handles the cancel confirm button press event.
   * Hides the cancel confirmation modal and sets the change show state to false.
   * @returns None
   */
  const onPressCancelConfirm = () => {
    setShowCancelConfirm(false);
    setChangeShow(false);
  };
  /**
   * Updates the book with the given event ID using the data from the form.
   * @param {string} updateEventId - The ID of the event to update.
   * @returns None
   */

  // update event
  const onPressUpdateBook = async (updateEventId) => {
    const editEventData = stepForm.watch();
    console.log(stepForm.getValues());
    let params = {
      name: editEventData?.fullName,
      month: editEventData?.month?.value,
      website: editEventData?.website,
      // description: textEditorDelta,
      description: editEventData.description,
      regionName: editEventData?.region?.title,
      region: editEventData?.region?.id,
      locationName: editEventData?.location?.address,
      LONG: editEventData?.location?.location?.long,
      LAT: editEventData?.location?.location?.lat,
    }
    if (profileImage) {
      params.profileImage = await uploadFIle(profileImage)
    }
    await AxiosInstance.put(`admin/event/edit?eventId=${updateEventId}`, params)
      .then((response) => {
        props.showToast({
          message: "The event has been updated successfully.",
          type: "success",
        });
        getEvents(searchText ? searchText : '', filteredEvent ? filteredEvent : '', selectedEventId ? selectedEventId : '');
      }).catch(error => {
      })
    setShowUpdateModal(false);
    setShowListDetails(showlistDetails ? false : showlistDetails);
  };
  /**
   * Handles the event when a user changes their profile image.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onProfileImageChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setProfileImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };
  /**
   * Sets the busy state to true when a form is submitted.
   * @param {any} data - the data submitted by the form
   * @returns None
   */
  const onFormSubmit = (data) => {
    setBusy(true);
  };
  /**
   * Sets the state of the body to the given value.
   * @param {any} e - The new value for the body state.
   * @returns None
   */
  const handleBody = (e) => {
    setBody(e);
  };
  /**
   * Handles the submission of a form to add a new shop. Sends a POST request to the server
   * with the form data and updates the list of events on success.
   * @param {Object} data - the form data object
   * @returns None
   */
  const onAddShopSubmit = async (data) => {
    setIsLoading(true)
    const eventData = stepForm.watch();
    const eventImg = await uploadFIle(profileImage);
    let params = {
      name: eventData?.fullName,
      month: eventData?.month?.value,
      website: eventData?.website,
      // description: textEditorDelta,
      description: eventData.description,
      regionName: eventData?.region?.value,
      region: eventData?.region?.id,
      locationName: eventData?.location?.address,
      LONG: eventData?.location?.location?.long,
      LAT: eventData?.location?.location?.lat,
    }
    if (profileImage) {
      params.profileImage = eventImg
    }
    await AxiosInstance.post('admin/event/add', params)
      .then((response) => {
        getEvents(searchText ? searchText : '', filteredEvent ? filteredEvent : '', selectedEventId ? selectedEventId : '');
        setIsLoading(false)
      }).catch((error => {
      }))
    setChangeShow(false);

    /**
     * Resets the values of the step form, text editor, and profile image after a delay of 1 second.
     * @returns None
     */
    setTimeout(() => {
      stepForm.reset(
        {
          fullName: "",
          region: "",
          address: "",
          phoneNumber: "",
          website: "",
          location: "",
          tabDropdown: "",
        },
        {
          keepErrors: false,
          keepDirty: false,
          KeepIsSubmitted: false,
          KeepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
          keepValues: false,
        }
      );
      setTextEditor('')

      setProfileImage(null);
    }, 1000);
  }
  /**
   * Sets the state of the update modal to true, which triggers the modal to be displayed.
   * @returns None
   */
  const onUpdateShopSubmit = async () => {
    setShowUpdateModal(true);
  };
  /**
   * Handles the import of a CSV file by sending it to the server and displaying a success message.
   * @returns None
   */
  const onPressImport = async () => {
    let csvData = new FormData();
    const url = 'auth/eventupload'
    csvData.append('avatar', csvFile)
    await AxiosInstance({
      method: "post",
      url: url,
      data: csvData,
      headers: { "Accept": "multipart/form-data" }
    }).then((response) => {
      props.showToast({
        message: "The item has been imported successfully",
        type: "success",
      });
      getEvents(searchText ? searchText : '', filteredEvent ? filteredEvent : '', selectedEventId ? selectedEventId : '');
    }).catch((error) => {
    })
    setShowImportModal(false);
    setConfirm(true);
  };
  /**
   * Handles the change event when a CSV file is selected.
   * @param {Array<File>} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onCSVFileChange = async (acceptedFiles) => {
    setCSVFile(acceptedFiles[0]);
    setCSVFileName(acceptedFiles[0].name);
    setFileSize(acceptedFiles[0].size / 1000);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {

    });
  };

  /**
   * Inserts an image into the Quill editor at the current cursor position.
   * @param {Event} e - The event object.
   * @returns None
   */
  const insertImage = (e) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const cursorPosition = editor.getSelection().index;
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async function () {
        const uploadImage = await uploadFIle(input.files[0]);
        const link = uploadImage;
        editor.insertEmbed(cursorPosition, 'image', link);
        editor.setSelection(cursorPosition + 1);
      }
    }
  }


  /**
   * Inserts a video into the Quill editor at the current cursor position.
   * @param {Event} e - The event object.
   * @returns None
   */
  const insertVideo = (e) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const cursorPosition = editor.getSelection().index;
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/mp4');
      input.click();
      input.onchange = async function () {
        const uploadImage = await uploadFIle(input.files[0]);
        const link = uploadImage;

        editor.insertEmbed(cursorPosition, 'video', link, 'user');
      }
    }
  }

  /**
   * Returns an object containing modules for the Quill editor. The modules include a toolbar
   * and clipboard configuration.
   * @returns An object containing modules for the Quill editor.
   */
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [
          { align: "right" },
          { align: "center" },
        ],
        [{ list: "ordered" }],
        ["link", "image", "video"],
      ],
      handlers: {
        image: insertImage,
        video: insertVideo
      }
    },
    clipboard: {
      matchVisual: false,
    }
  }), [])


  return (
    <>
      <MetaTags>
        <title>Manage Events | Scotland By-The-Book Admin - Birlinn Ltd</title>
        <meta name="description" content="Add individual events, bulk-upload events, edit existing listing information, temporarily disable listings, or delete listings that are no longer required." />
        <meta property="og:title" content="Manage Events | Scotland By-The-Book Admin - Birlinn Ltd" />
      </MetaTags>
      <div className="eventMain">
        <>
          <div className="headingHeight">
            <div className="setEvent">
              <h1 className="title">{strings["MANAGEEVENTS"]}</h1>
              <div className="eventBtn">
                <CustomButton
                  type="button"
                  title={strings["ADDEVENT"]}
                  className="customAddEvent"
                  onClick={() => {
                    setChangeShow({ type: "add", show: true })
                    setShopDetail([])
                    setProfileImage(null);
                    setMonthType(null)
                    setPortLocation(null)
                  }
                  }
                ></CustomButton>
                <CustomButton
                  type="button"
                  title={strings["IMPORT"]}
                  className="customImport"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowImportModal(true);
                  }}
                ></CustomButton>
              </div>
            </div>
            <div className="setSearch">
              <div className="tabButtonSearch">
                <SearchBox
                  placeholder={strings["SEARCHBYEVENT"]}
                  value={searchText}
                  onSearch={(value) => {
                    if (value !== "") {
                      setsearchText(value.slice(0, 200));
                      if (value?.length >= 3) {
                        dispatch({ type: 'RESET_PAGE' })
                      }
                      setUsersList([]);
                    } else {
                      setsearchText("");
                      dispatch({ type: 'RESET_PAGE' })

                    }
                    setPageLoad(true);
                  }}
                  onClear={() => {
                    setsearchText("");
                    dispatch({ type: 'RESET_PAGE' })
                    // getUserList("");
                  }}
                />
              </div>
              <div className="tabDropdown">
                <Dropdown className="d-inline mx-2" autoClose="outside">
                  <Dropdown.Toggle id="dropdown-autoclose-outside"
                    onClick={() => {
                      setDropdownShow(!dropdownShow);
                    }} >
                    {strings['REGIONS']} ({selectedEventRegion.length})
                  </Dropdown.Toggle>
                  <Dropdown.Menu align={"end"} placement="bottom-end" show={dropdownShow} className={`${dropdownShow === false ? 'dropdown-menu showClose dropdown-menu-end' : 'dropdown-menu show dropdown-menu-end'} `}>
                    <div className="drop_list">
                      {eventRegion?.map((value, index) => {
                        const checkedBox = () => {
                          let item = false;
                          selectedEventRegion.length && selectedEventRegion.forEach((i) => {
                            if (value._id === i.id) {
                              item = true;
                            }
                          })
                          return item;
                        }
                        return (
                          <CheckBox
                            keyId={index}
                            id={value.id}
                            labelTitle={value.name}
                            checked={checkedBox}
                            checkedIcon="icon-check-square-top"
                            onCheckedChange={(checked) => {
                              let eventRegionList = [...selectedEventRegion];
                              if (checked !== true) {
                                const remove = selectedEventRegion.filter((l) => l.id !== value._id)
                                eventRegionList = [...remove];
                              } else {
                                eventRegionList.push({
                                  id: value._id,
                                  name: value.name,
                                  checked: checked
                                })
                              }
                              setSelectedEventRegion([...eventRegionList]);
                            }}
                          />
                        );
                      })}
                    </div>
                    <div className="drop_btns">
                      <CustomButton
                        type="button"
                        title={strings["APPLY"]}
                        className="titleApply"
                        onClick={() => {
                          const filterEventId = selectedEventRegion.map((l) => {
                            return l.id;
                          })
                          dispatch({ type: 'RESET_PAGE' })
                          const check = selectedEventRegion.some((item) => item.checked === true);
                          if (check) {
                            setValue(selectedEventRegion)
                          }
                          setCheckApply(true);
                          setSelectedEventId(filterEventId);
                          setDropdownShow(false);

                        }}
                      />
                      <CustomButton
                        type="button"
                        title={strings["CLEAR"]}
                        className="titleApply"
                        onClick={() => {
                          dispatch({ type: 'RESET_PAGE' })
                          setSelectedEventRegion([]);
                          setSelectedEventId(null);
                          setValue([])
                          setDropdownShow(false);
                        }}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="setPara">
              <p className="bookLine">
                {strings['ABERDEERNSHIRE']}-   {value?.length > 0 && checkApply === true && events?.length > 0 ? value?.map((item) => item.name).join(", ") : (events?.length > 0 ? "All" : "No")}  {value?.length === 1 && checkApply === true ? "region" : "regions"} events
              </p>
              <div className="tabDropdownSecond">
                <CustomDropdown
                  isReqired={true}
                  placeholder={strings["SORT_BY"]}
                  type="text"
                  autoClose={"outside"}
                  dropDownItems={shopSortData}
                  align={"end"}
                  selectedValue={shopsortCategory}
                  onSelect={(evt) => {
                    dispatch({ type: 'RESET_PAGE' })
                    setShopsortCategory(evt)
                    setFilteredEvent(evt.sortValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              height: dimensions.height - headingHeight - 10 + "px",
            }}
            className="eventPage"
            onScroll={(e) => {
              const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
              if (bottom && state.emptyList === false) {
                dispatch({ type: 'INCREMENT_PAGE' })
              }
            }}
          >
            <ul>
              {events?.map((childItem) => {
                let item = childItem?.data
                return (
                  <li
                    key={item._id}
                    className={
                      item?.status === "Enabled" ? "eventIndex" : "disabled eventIndex"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowListDetails(!showlistDetails)
                      setLocation(item?.location?.coordinates);
                      // setShowListDetails(true);
                      setEventShow(item);
                      setShopDetail(item);
                    }}
                  >
                    <div className="shopIndexButton">
                      <CustomButton
                        type="button"
                        className="customEditbtn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShopDetail(item);
                          setEventShow(item);
                          setShowDeleteModal(true);

                        }}
                      >
                        <i className="icon icon-trash-2" />
                      </CustomButton>
                      <CustomButton
                        type="button"
                        className="customEditbtn1"
                        onClick={(e) => {
                          setChangeShow({ type: "edit", show: true });
                          setShopDetail(item);
                          setMonthType({ id: "1", title: item.month, value: item.month })
                          setTextEditor(parse(item?.description))
                          setBookType({ id: item.region, name: item.regionName })
                          setPortLocation({
                            portLocation: {
                              latitude: item?.location?.coordinates[1],
                              longitude: item?.location?.coordinates[0],
                            }
                          })
                          e.stopPropagation();
                        }}
                      >
                        <i className="icon icon-edit-2" />
                      </CustomButton>
                      <DropdownButton
                        id="dropdown-basic-button"
                        className="disableDropdown"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            setShowListDetails(false)
                            setShowEnableDisableModal(true);
                            setShopDetail(item);
                          }}
                        >
                          {item?.status === "Enabled"
                            ? strings["DISABLE"]
                            : strings["ENABLE"]}
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <img src={item.profileImage ? item.profileImage : 'https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg'} className="eventImage" />
                    <div className="eventCard">
                      <div className="TitleWithTag">
                        <p className="metaTitle">{item.month}</p>
                        <span>{item.regionName}</span>
                      </div>
                      <p className="title">{item.name}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <Offcanvas
            show={showlistDetails}
            onHide={() => setShowListDetails(false)}
            {...props}
            placement="end"
            className="eventOffcanvas"
          >
            <Offcanvas.Header>
              <p className="alignCenters">
                <i
                  className="icon icon-arrow-left"
                  onClick={() => {
                    setShowListDetails(false)

                  }}
                ></i>
              </p>
            </Offcanvas.Header>
            {/* about modal */}
            <Offcanvas.Body>
              <div className="mainShopOffcanvas">
                <div className="mainshopCard">
                  <img
                    src={shopDetail?.profileImage ? shopDetail?.profileImage : 'https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg'}
                    alt=""
                    className="scottishCardLeft"
                  />
                  <div className="scottishCardRight">
                    <p className="metaTitleshop">{shopDetail?.name}</p>
                    <p className="authorshop">{shopDetail?.month}</p>
                    <p className="metaTitleRegion">
                      {shopDetail?.regionName}
                    </p>
                  </div>
                </div>
                <div className="detailsCardButton">
                  <CustomButton
                    className="detailsButton"
                    title={strings["EDIT"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setChangeShow({ type: "edit", show: true });
                      setShopDetail(shopDetail);
                      setMonthType({ id: "1", title: shopDetail.month, value: shopDetail.month })
                      setTextEditor(parse(shopDetail?.description))
                      setBookType({ id: shopDetail?.region, name: shopDetail?.regionName })
                      setPortLocation({
                        portLocation: {
                          latitude: shopDetail?.location?.coordinates[1],
                          longitude: shopDetail?.location?.coordinates[0],
                        }
                      })
                    }}
                  >
                    <i className="icon icon-edit-2"></i>
                  </CustomButton>
                  <CustomButton
                    className="detailsButton"
                    onClick={() => {
                      setShowEnableDisableModal(true);
                    }}
                  >
                    <i className="icon icon-minus-circle"></i>
                    {shopDetail?.status === "Enabled" ? strings["DISABLE"] : strings["ENABLE"]}
                  </CustomButton>
                  <CustomButton
                    className="detailsButton"
                    title={strings["DELETE"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteModal(true);
                    }}
                  >
                    <i className="icon icon-trash-2"></i>
                  </CustomButton>
                </div>
                <div className="shopTitle">{strings['ABOUT_EVENT']}</div>
                <div className="aboutPartshop">
                  <div className="aboutshoptext"><span>{eventshow?.description && parse(parse(`${eventshow?.description}`))}</span></div>
                </div>
                <p className="shopLocation">{strings['TITLE_LOCATION']}</p>
                <div className="locationTitle">
                  {eventData.location}
                </div>
                <div className="map_view">
                  <iframe
                    title="map"
                    className="map"
                    src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                      }&q=${get(
                        location,
                        "[1]",
                        1
                      )},${get(location, "[0]", 1)}`}
                    width="100%"
                    height="330px"
                    frameborder="0"
                    allowfullscreen="false"
                    aria-hidden="false"
                    tabindex="0"
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          {/* edit form modal */}
          <Offcanvas
            show={changeshow}
            onHide={() => setChangeShow(false)}
            {...props}
            placement="end"
            className="AddEventOffcanvas"
          >
            <HookForm
              defaultForm={{}}
              init={(form) => SetStepForm(form)}
            >
              {(formMethod) => {
                return (
                  <>
                    <Offcanvas.Header>
                      <p
                        className="alignCenters"
                        onClick={(e) => {
                          setShowCancelConfirm(false);
                          setChangeShow(false);
                          setTextEditor('')
                          setBookType(null)
                        }}
                      >
                        <i className="icon icon-arrow-left"></i>
                      </p>
                      <div className="addbtn">
                        <Label
                          className="Addtitle"
                          title={
                            changeshow.type === "add"
                              ? "Add Event"
                              : changeshow.type === "edit" && "Edit Event"
                          }
                        />
                        <div>
                          <CustomButton
                            type="submit"
                            title={changeshow.type === "edit" ? "Update" : changeshow.type === "add" && "Add"}
                            className={
                              changeshow.type === "edit"
                                ? "customUpdatebtn"
                                : changeshow.type === "add" && "customAddbtn"
                            }
                            disabled={
                              changeshow.type === "add"
                              &&
                              (
                                !formMethod?.formState.isValid
                              )
                            }
                            loading={isLoading && true}
                            onClick={() => {
                              changeshow.type === "add"
                                ? onAddShopSubmit()
                                : onUpdateShopSubmit();
                            }}
                          />
                        </div>
                      </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{ height: dimensions.height - 100 }}>
                      <div className="addForm">

                        <>
                          <div className="addImage">
                            <div className="setImage">
                              <p>{strings['EVENT_IMAGE']}</p>
                            </div>
                            <div className="leftBox">
                              <div className="updateSectionimg">
                                <Controller
                                  defaultValue={null}
                                  name="updateimage"
                                  control={formMethod.control}
                                  render={({
                                    field: { onChange, value },
                                  }) => (
                                    <FileUpload
                                      onDrop={(acceptedFiles) => {
                                        onProfileImageChange(acceptedFiles);
                                        onChange(acceptedFiles);
                                      }}
                                      accept='image/jpeg,image/jpg,image/png'
                                    >
                                      <div className="imgBoxEmpty">
                                        {profileImage || shopDetail?.profileImage ? (
                                          <div className="imageShow">
                                            <img
                                              src={(profileImage || shopDetail?.profileImage) ? (profileImage || shopDetail?.profileImage) : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811436.svg'}
                                              alt="logo"
                                              className="audioRegion"
                                            />
                                            <div className="importImg">
                                              <i className="icon icon-upload"></i>
                                              <p className="fileImported">
                                                {strings['UPLOAD_IMG']}
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="uploadedImg">
                                            <div className="importImg">
                                              <i className="icon icon-upload-1"></i>
                                              <p>{strings['Upload_img']}</p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </FileUpload>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="fieldPartsName">
                            <div className="setName">
                              <p>{strings["FULL_NAME"]}</p>
                            </div>
                            <TextField
                              formMethod={formMethod}
                              rules={stepform.fullName.validate}
                              defaultValue={changeshow.type === "edit" && shopDetail.name ? shopDetail?.name : null}
                              name={stepform.fullName.name}
                              errors={formMethod?.formState?.errors}
                              autoFocus={true}
                              type="text"
                              placeholder={strings["ENTER_SHOP_NAME"]}
                              isReqired={true}
                            />
                          </div>
                          <div className="fieldPartsmonth">
                            <div className="setregion">
                              <p>{strings["MONTH"]}</p>
                            </div>
                            <div className="BooktabDropdown">
                              <Controller
                                defaultValue=""
                                name={stepform.month.name}
                                rules={stepform.month.validate}
                                control={formMethod.control}
                                render={({ field: { onChange } }) => (
                                  <CustomDropdown
                                    isReqired={true}
                                    placeholder={MonthType ? MonthType.value : strings["EVENT_DROPDOWN"]}
                                    type="text"
                                    autoClose={"outside"}
                                    dropDownItems={monthCategoryList}
                                    align={"end"}
                                    selectedValue={MonthType}
                                    onSelect={(evt) => {
                                      onChange(evt);
                                      setMonthType(evt);
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="fieldPartswebsite">
                            <div className="setwebsite">
                              <p>{strings["WEBSITE"]}</p>
                            </div>
                            <TextField
                              formMethod={formMethod}
                              rules={stepform.website.validate}
                              defaultValue={changeshow.type === "edit" && shopDetail?.website ? shopDetail?.website : null}
                              name={stepform.website.name}
                              errors={formMethod?.formState?.errors}
                              autoFocus={true}
                              type="text"
                              placeholder={strings["ENTER_WEBSITE_NAME"]}
                            // isReqired={false}
                            />
                          </div>
                          <div className="fieldPartstexteditor">
                            <div className="settexteditor">
                              <p>{strings["DESCRIPTION"]}</p>
                            </div>
                            <div className="updateSectioneditor">
                              <Controller

                                defaultValue={changeshow.type === "edit" && shopDetail.description ? parse(shopDetail?.description) : ''}
                                name={stepform.description.name}
                                rules={stepform.description.validate}
                                control={formMethod.control}
                                render={({ field: { onChange, value } }) => (
                                  <ReactQuill
                                    ref={quillRef}
                                    placeholder="Write here"
                                    // value={textEditor} modules={modules}
                                    value={value} modules={modules}
                                    formats={[
                                      "header",
                                      "font",
                                      "size",
                                      "bold",
                                      "italic",
                                      "align",
                                      "underline",
                                      "strike",
                                      "blockquote",
                                      "list",
                                      "bullet",
                                      "indent",
                                      "link",
                                      "image",
                                      "video",
                                      "color"
                                    ]}
                                    onChange={(content, delta, source, editor) => {
                                      // let textContent = editor.getText(content);
                                      // setTextEditor(content);
                                      onChange(content)
                                      // const conentsData = editor.getContents();
                                      // setTextEditorDelta(editor.getHTML());
                                      // setDescriptionLength(textContent?.length);
                                    }} />
                                )}
                              />
                            </div>
                          </div>
                          <div className="fieldPartsregion">
                            <div className="setregion">
                              <p>{strings["REGION"]}</p>
                            </div>
                            <div className="BooktabDropdown">
                              <Controller
                                defaultValue=""
                                name={stepform.region.name}
                                rules={stepform.month.validate}
                                control={formMethod.control}
                                render={({ field: { onChange } }) => (
                                  <CustomDropdown
                                    isReqired={true}
                                    placeholder={strings["BOOK_DROPDOWN"]}
                                    type="text"
                                    autoClose={"outside"}
                                    dropDownItems={bookCategoryList}
                                    align={"end"}
                                    selectedValue={bookType}
                                    onSelect={(evt) => {
                                      onChange(evt);
                                      setBookType(evt);
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="fieldPartslocationTitle">
                            <div className="setlocationtitle">
                              <p>{strings["BOOK_LOCATION"]}</p>
                            </div>
                            <div className="locationRightPart">
                              <div className="locationDropdown">
                                <Controller
                                  control={formMethod?.control}
                                  name={stepform.location.name}
                                  rules={stepform.location.validate}
                                  render={({ field: { onChange } }) => (
                                    <GooglePlaceDropDown
                                      getLocation={(place) => {
                                        onChange(place);
                                        place === null
                                          ? setPortLocation(null)
                                          : setPortLocation({
                                            portLocation: {
                                              latitude: place?.location?.lat,
                                              longitude: place?.location?.long,
                                              address: place?.address
                                            },
                                          });

                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="map_view">
                                <iframe
                                  title="map"
                                  className="map"
                                  src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                    }&q=${get(
                                      portLocation,
                                      "portLocation.latitude",
                                      1
                                    )},${get(
                                      portLocation,
                                      "portLocation.longitude",
                                      1
                                    )}`}
                                  width="100%"
                                  height="330px"
                                  frameborder="0"
                                  allowfullscreen="false"
                                  aria-hidden="false"
                                  tabindex="0"
                                />
                              </div>
                            </div>
                          </div>
                        </>

                      </div>
                    </Offcanvas.Body>
                  </>
                );
              }}
            </HookForm>
          </Offcanvas>
        </>
        {/* );
          }}
        </HookForm> */}
      </div>

      <ConfirmModal
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["DELETE"]}
        modalDescription={strings["SUREDELETE_EVENT"]}
        showModal={showDeleteModal}
        onPressLeft={() => {
          onPressDeleteBook(shopDetail._id);
        }}
        onPressRight={() => setShowDeleteModal(false)}
      />
      <ConfirmModal
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={
          shopDetail?.status === "Enabled" ? strings["DISABLE"] : strings["ENABLE"]
        }
        modalDescription={
          shopDetail?.status === "Enabled"
            ? strings["SURE_DISABLE_EVENT"]
            : strings["SURE_ENABLE_EVENT"]
        }
        showModal={showEnableDisableModal}
        onPressLeft={() => {
          onPressEnableDisable(shopDetail._id, shopDetail.status)

        }}
        onPressRight={() => {
          setShowEnableDisableModal(false);
        }}
      />
      <ConfirmModal
        className="bookConfirmModal"
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["Cancle"]}
        modalDescription={strings["SURE_CANCEL_CHANGES"]}
        showModal={showCancelConfirm}
        onPressLeft={() => {
          onPressCancelConfirm();
          setTextEditor('')
          setBookType(null)
          // setEditBookDetails([])
        }}
        onPressRight={() => setShowCancelConfirm(false)}
      />

      {/* update modal */}
      <ConfirmModal
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["UPDATE"]}
        modalDescription={strings["SUREUPDATE"]}
        showModal={showUpdateModal}
        onPressLeft={() => {
          onPressUpdateBook(shopDetail._id)
          setChangeShow(false);
          setTextEditor('')
          setBookType(null)
        }}
        onPressRight={() => setShowUpdateModal(false)}
      />
      <ModalPopup
        className="bookImportModal"
        showModal={showImportModal}
        closeButton={true}
        onHide={() => {
          setShowImportModal(false);
        }}
      >
        <HookForm defaultForm={{}} init={(form) => SetStepForm(form)}>
          {(formMethod) => {
            return (
              <>
                <div className="updateSection">
                  <Controller
                    name="uploadCSV"
                    control={formMethod.control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <input
                          id={"csvFileUploader"}
                          type="file"
                          accept=".xlsx, .xls, .csv"
                          onChange={(file) => {
                            onChange(file);
                            onCSVFileChange(file.target.files);
                          }}
                        />
                        <div className="importFileIcon">
                          {csvFileName === null ? (
                            <label
                              htmlFor="csvFileUploader"
                              className="csfileUploader"
                            >
                              <i className="icon icon-log-in" />
                              {strings["IMPORT_FILE"]}
                            </label>
                          ) : (
                            <label
                              htmlFor="csvFileUploader"
                              className="csfileUploader"
                            >
                              <i className="icon icon-file-plus" />
                              <p className="fileName">{csvFileName}</p>
                              <span className="fileSize">{`${fileSize}    ${fileSize >= 2048 ? 'MB' : 'KB'}`}</span>
                            </label>
                          )}
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="btnInline">
                  <CustomButton
                    type="button"
                    title={strings["YES"]}
                    onClick={() => {
                      onPressImport(false);
                    }}
                    className="titleYes"
                    disabled={csvFile === null ? true : false}
                  />
                  <CustomButton
                    type="button"
                    title={strings["NO"]}
                    onClick={() => {
                      setShowImportModal(false);
                    }}
                    className="titleNo"
                  />
                </div>
              </>
            );
          }}
        </HookForm>
      </ModalPopup>
    </>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * A function that maps dispatch actions to props. 
 * @param {function} showToast - A function that dispatches an action to show a toast notification.
 * @param {function} showLoader - A function that dispatches an action to show a loading spinner.
 * @param {function} hideLoader - A function that dispatches an action to hide a loading spinner.
 * @returns An object containing the mapped dispatch actions.
 */
const mapDispatchToProps = { showToast, showLoader, hideLoader };

/**
 * Connects the Events component to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns The connected Events component.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Events));
