import React from "react";

import Layout from "./layout/index";
import UserLayout from "./layout/User/index";

import Login from "./pages/Auth/Login/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import Regions from "./pages/Regions/Regions";
import Book from "./pages/Book/Book";
import Shop from "./pages/Shop/Shop";
import Events from "./pages/Events/Events";

// User 
import TourScreen from "./pages/User/TourScreen/TourScreen";
import DiscoverScreen from "./pages/User/DiscoverScreen/DiscoverScreen";
import DirectoryScreen from "./pages/User/DirectoryScreen/DirectoryScreen";
import BookmarkScreen from "./pages/User/BookmarkScreen/BookmarkScreen";

import PageNotFound from './pages/PageNotFound/PageNotFound';


const routes = [
  {
    path: "/",
    component: <TourScreen />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/tour",
    component: <TourScreen />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin",
    component: <Login />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin/login",
    component: <Login />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin/forgotpassword",
    component: <ForgotPassword />,
    authenticate: false,
    exact: true,
  },
  {
    path: "/admin/resetpassword",
    component: <ResetPassword />,
    authenticate: false,
    exact: true,
  },

  // For Routes
  {
    path: '/*',
    component: <UserLayout />,
    authenticate: false,
    exact: true
  },
  {
    path: "/admin/*",
    component: <Layout />,
    authenticate: false,
    exact: true,
  },

];



/**
 * An array of objects representing the routes for the user layout.
 * Each object contains the path, component, and authentication requirements for the route.
 * @type {Array}
 */
export const UserLayoutRoute = [

  {
    path: '/tour',
    component: <TourScreen />,
    exact: true,
    authenticate: true
  },
  {
    path: '/discover',
    component: <DiscoverScreen />,
    exact: true,
    authenticate: true
  },
  {
    path: '/directory',
    component: <DirectoryScreen />,
    exact: true,
    authenticate: true
  },
  {
    path: '/bookmark',
    component: <BookmarkScreen />,
    exact: true,
    authenticate: true
  },
  {
    path: '/*',
    component: <PageNotFound />,
    authenticate: false,
    exact: true
  }
];

/**
 * An array of objects representing the routes for the application.
 * @type {Array}
 * @property {string} path - The URL path for the route.
 * @property {React.Component} component - The component to render for the route.
 * @property {boolean} exact - Whether the path must match exactly.
 * @property {boolean} authenticate - Whether the user must be authenticated to access the route.
 * @returns None
 */
export const LayoutRoute = [
  {
    path: "/regions",
    component: <Regions />,
    exact: true,
    authenticate: true,
  },
  {
    path: "/book",
    component: <Book />,
    exact: true,
    authenticate: true,
  },
  {
    path: "/events",
    component: <Events />,
    exact: true,
    authenticate: true,
  },
  {
    path: "/shop",
    component: <Shop />,
    exact: true,
    authenticate: true,
  },
  {
    path: '/*',
    component: <PageNotFound />,
    authenticate: true,
    exact: true
  }
];

export default routes;
