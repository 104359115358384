import React from "react";

const Map = (props) => {

    return (
        <>
            <svg className={`map_style ${'bgcolor-'+props.colorIndex}`} width="auto" height="auto" viewBox="0 0 560 939" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="region_color5" d="M440.99 495.673L417.307 499.515L416.214 534.877L409.291 533.408L388.037 547.643L386.823 560.297L351.48 565.72L351.966 563.008L372.006 547.643L376.014 520.868L376.378 518.608L377.471 511.377L377.592 510.699V510.248H352.816L350.752 479.518L394.596 460.537L420.343 473.869L435.768 473.417L440.99 495.673Z" fill="#009B3E"/>
                <path className="region_color4" d="M531.228 502.339L510.217 528.098L492 592.948L474.511 615.317L447.184 603.342L446.698 582.441L414.028 579.39L382.815 596.789L379.414 595.885L347.715 587.412L351.48 565.72L386.823 560.297L388.037 547.643L409.291 533.408L416.214 534.877L417.307 499.515L440.99 495.673L435.768 473.417L513.982 471.27L531.228 502.339Z" fill="#009B3E"/>
                <path className="region_color7" d="M474.51 615.317L440.383 658.701L418.521 662.316L416.7 662.994L398.482 661.412L390.102 650.228L401.275 641.641V633.394L392.045 630.456L379.414 595.885L382.815 596.789L414.028 579.39L446.698 582.441L447.184 603.342L474.51 615.317Z" fill="#009B3E"/>
                <path className="region_color6" d="M377.107 689.431L392.045 696.888L377.592 708.75L357.917 703.779L336.663 694.402L301.685 678.924L299.013 669.434L304.357 668.982L305.936 662.542L314.559 655.086L295.856 655.538L293.669 648.646L261.606 656.555L267.436 634.185L257.355 619.272L309.215 588.881L347.715 587.412L379.414 595.885L392.045 630.457L401.275 633.394V641.641L390.102 650.228L398.482 661.413L416.7 662.994L387.916 675.648L377.107 689.431Z" fill="#009B3E"/>
                <path className="region_color8" d="M454.714 690.787L434.675 703.214L424.23 701.407L406.498 713.947L404.797 715.077L403.097 716.32L402.004 717.111L396.539 720.952C396.539 720.952 395.203 721.517 393.138 722.195C392.895 722.308 392.652 722.421 392.409 722.421C392.167 722.534 391.924 722.534 391.802 722.647C391.559 722.76 391.316 722.76 391.073 722.873C389.738 723.325 388.28 723.889 386.823 724.341C386.58 724.454 386.337 724.454 386.094 724.567C381.965 725.923 377.835 727.166 376.621 727.166C374.92 727.166 361.804 722.308 351.602 718.466L358.039 703.779L377.835 708.75L392.288 696.775L377.35 689.318L388.159 675.535L412.692 670.225L415.607 669.547L425.444 667.4L429.695 666.496L433.096 665.819V676.439L434.432 677.229L434.675 677.342L454.714 690.787Z" fill="#009B3E"/>
                <path className="region_color13" d="M357.917 703.779L351.48 718.466C348.565 717.337 345.893 716.32 343.829 715.529C343.464 715.416 343.1 715.303 342.735 715.077C341.035 714.399 339.942 713.947 339.456 713.834C339.335 713.834 339.335 713.721 339.335 713.721V713.834L341.764 716.772L342.614 717.789L343.707 719.144L332.169 712.479L336.663 694.289L357.917 703.779Z" fill="#009B3E"/>
                <path className="region_color15" d="M359.496 731.12L342.372 745.581L320.996 725.697L343.708 719.257L349.902 726.601L351.359 728.409L352.088 729.312L352.573 729.877L356.824 730.668L357.431 730.781L358.403 730.894L359.496 731.12Z" fill="#009B3E"/>
                <path className="region_color9" d="M406.984 739.593L397.996 750.326L379.778 758.574L377.592 760.72L374.677 754.506L373.099 733.492L392.895 737.108L395.81 737.56L398.725 738.125L400.911 738.576L406.984 739.593Z" fill="#009B3E"/>
                <path className="region_color16" d="M377.592 760.72L372.856 765.465L348.201 764.562L342.371 745.581L359.496 731.12L361.682 731.459L362.532 731.685L363.14 731.798L363.868 731.911L367.512 732.476L368.605 732.702L373.099 733.493L374.677 754.507L377.592 760.72Z" fill="#009B3E"/>
                <path className="region_color18" d="M472.931 741.627L453.864 759.591L432.974 759.139L428.237 761.285L415.606 747.05L406.983 739.593L432.853 724.454L449.977 726.262L470.988 740.497L472.931 741.627Z" fill="#009B3E"/>
                <path className="region_color23" d="M483.376 783.203L492.971 818.678L430.667 869.631L417.185 844.098L386.215 824.214L367.512 823.197L366.905 789.869L377.592 776.424L372.856 765.465L377.592 760.72L379.778 758.574L390.466 767.273L400.547 777.215L419.129 765.239L428.237 761.285L432.974 759.139L453.864 759.59L472.932 741.627L507.302 761.85L483.376 783.203Z" fill="#009B3E"/>
                <path className="region_color21" d="M322.089 816.306L316.502 817.435L305.815 839.805L291.605 835.173L275.816 858.785L255.169 863.982V874.941L231.608 873.924L220.677 877.427L261.728 808.058L248.004 785.463L233.673 773.939L239.259 746.372L259.299 764.449L286.14 774.504L294.277 781.169L302.778 788.4L301.199 798.342L320.996 796.873L322.089 816.306Z" fill="#009B3E"/>
                <path className="region_color22" d="M430.667 869.631L406.984 888.951L360.225 887.143L358.282 898.667L321.968 921.827L278.002 904.09L279.945 919.116L278.002 933.351L229.3 907.592L235.008 931.543L227.964 939L201.974 887.482L220.677 877.427L231.608 873.924L255.169 874.941V863.982L275.816 858.785L291.605 835.173L305.815 839.805L316.502 817.435L322.089 816.306L332.169 814.272L353.059 841.613L367.512 823.197L386.215 824.214L417.185 844.098L430.667 869.631Z" fill="#009B3E"/>
                <path className="region_color10" d="M264.885 707.056L266.586 718.014L265.007 718.24L261.971 737.786L252.133 732.928H237.437L236.223 736.091L235.494 737.786L222.863 770.097L210.718 755.862L191.893 740.836L196.265 765.239L173.433 828.507L144.163 834.156L169.06 770.662L167.117 743.999L176.712 688.866L197.844 633.055L223.471 632.038V642.432L267.315 633.959L261.363 656.328L242.174 670.79L260.878 681.184L262.456 691.352L262.821 693.95L263.428 697.678L263.55 698.582L264.521 705.135L264.643 705.926L264.885 707.056Z" fill="#009B3E"/>
                <path className="region_color10" d="M171.975 654.295L158.615 673.84L102.262 679.15L105.055 670.338L124.245 663.22L129.953 644.579L111.735 638.252L129.953 623.226L146.227 645.37L171.975 654.295Z" fill="#009B3E"/>
                <path className="region_color10" d="M162.38 705.813L133.718 753.828L127.038 748.518L126.066 775.181L108.942 783.203L106.027 762.754L85.0156 768.064L95.5819 741.401L122.301 735.187V741.401L146.227 712.027L162.38 705.813Z" fill="#009B3E"/>
                <path className="region_color1" d="M136.633 332.533L140.398 357.501L120.358 391.282V406.308L63.0334 442.8L55.382 428.564L71.6565 417.041L56.3536 407.211L69.7133 401.901L54.4104 386.762L65.9483 373.431L79.3079 379.645H87.931V363.715L136.633 332.533Z" fill="#009B3E"/>
                <path className="region_color1" d="M45.9088 451.612L52.5886 467.655L31.5775 513.863V534.312V537.927L22.9545 543.237L18.0964 514.767L25.7479 494.318L19.068 467.655L25.7479 455.227L39.229 457.939L45.9088 451.612Z" fill="#009B3E"/>
                <path className="region_color1" d="M20.0395 553.857L17.2461 563.686L0 579.616L3.76499 560.071L13.3597 550.355L20.0395 553.857Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M383.423 275.818L374.678 282.597L385.488 297.736L397.876 299.995L400.791 291.748L383.423 275.818ZM415.608 271.525L420.101 284.856L412.207 293.556L417.186 305.531L421.68 305.983L427.145 285.308L436.011 277.061L420.709 263.277H407.349L412.328 251.302L397.876 239.778L387.067 241.586L379.172 262.261L396.418 277.4L415.608 271.525Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M410.142 231.531L404.677 235.711L408.685 242.602L419.008 245.314L422.409 237.97L410.142 231.531Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M430.91 249.946L422.044 253.109L420.587 258.193L427.995 259.097L432.489 258.193L430.91 249.946Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M445.242 239.778L443.298 249.946L450.707 251.754L456.172 249.946L452.164 240.343L445.242 239.778Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M430.911 233.79L434.311 224.639L438.319 226.898L437.348 240.23L430.911 233.79Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M443.298 227.915L452.65 219.668L465.038 215.036L454.229 224.639L443.298 227.915Z" fill="#009B3E"/>
                <path className="region_color3" fill-rule="evenodd" clip-rule="evenodd" d="M409.656 210.404L422.53 214.132L427.51 223.735L415.122 220.007L409.656 210.404Z" fill="#009B3E"/>
                <path className="region_color17" d="M428.237 761.285L419.129 765.239L400.546 777.215L390.466 767.273L379.778 758.574L397.996 750.326L406.983 739.593L415.606 747.05L428.237 761.285Z" fill="#009B3E"/>
                <path d="M431.152 870.534L430.667 869.631" stroke="#009B3E" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path className="region_color19" d="M297.435 772.47C297.192 775.408 294.277 781.169 294.277 781.169L286.261 774.391L259.42 764.336L239.381 746.259L259.906 751.456L262.092 737.673L283.711 745.694L283.103 747.615L288.812 761.85L293.548 765.24C293.305 765.24 297.677 769.646 297.435 772.47Z" fill="#009B3E"/>
                <path className="region_color14" d="M366.904 789.869L367.39 823.197L352.937 841.613L332.048 814.272L321.967 816.306L320.874 796.873L301.078 798.342L302.657 788.4L294.155 781.169C294.155 781.169 297.07 775.407 297.313 772.47C297.556 769.533 293.183 765.239 293.183 765.239L298.892 761.85L297.92 755.862L309.093 757.557L312.98 749.535L303.142 745.694L299.013 743.887L312.494 741.74L313.83 737.447L305.936 725.923L320.996 725.697L342.371 745.581L348.201 764.449L372.855 765.465L377.713 776.424L366.904 789.869Z" fill="#009B3E"/>
                <path className="region_color11" d="M343.707 719.257L320.996 725.697L305.936 726.036L294.277 726.149L291.483 734.058L279.824 731.007L272.78 717.224L266.586 718.015L264.885 707.056L264.764 706.265L264.642 705.474L263.671 698.921L263.549 697.904L262.942 694.176L262.578 691.578L260.999 681.41L242.295 671.016L261.606 656.554L293.669 648.646L295.855 655.538L314.559 655.086L305.936 662.542L304.357 668.982L299.013 669.434L301.685 678.924L336.663 694.402L332.291 712.592L343.707 719.257Z" fill="#009B3E"/>
                <path d="M128.01 458.729L137.604 472.965L143.313 519.173L164.324 529.906L168.21 538.718L146.228 562.782L142.341 555.665L143.313 537.023L130.925 543.237L106.999 520.077L103.234 504.938L89.7527 509.457L75.4214 492.51L81.2511 484.489L95.5823 489.008V471.27L119.387 489.008L115.622 464.943L128.01 458.729Z" fill="#009B3E"/>
                <path d="M106.027 560.975L116.594 577.809C116.594 577.809 127.16 570.691 126.188 567.979C125.217 565.268 115.622 552.84 115.622 552.84L106.027 560.975Z" fill="#009B3E"/>
                <path className="region_color0" d="M335.328 427.661L315.289 442.8L346.866 445.511C346.866 445.511 298.164 497.933 298.164 500.644C298.164 503.356 327.798 483.811 327.798 483.811H341.158L350.874 479.631L352.939 510.361H377.836L377.715 510.699L377.593 511.377L376.5 518.608L376.136 520.868L372.128 547.643L352.088 563.121L351.603 565.833L347.838 587.525L309.338 588.993L257.478 619.385L267.68 634.298L223.836 642.771V632.377L198.21 633.394L198.574 632.264L216.792 606.505L173.434 646.499L132.747 616.108L128.011 605.488L150.965 601.082L167.24 561.088L181.571 539.735L175.863 522.788L182.542 512.168L170.154 514.88L166.268 476.693L169.183 438.506L188.251 428.677L224.565 429.581L206.468 401.111L217.885 402.014L210.233 378.063L233.188 372.753L231.244 359.422L240.839 349.593L236.102 338.973L252.377 321.235L308.73 333.663L419.494 318.524L409.899 340.78L416.579 350.609L335.328 427.661Z" fill="#009B3E"/>
                <path d="M350.752 479.517V479.405" stroke="#009B3E" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M435.768 473.416L435.525 472.513" stroke="#009B3E" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path className="region_color20" d="M309.094 757.557L297.92 755.862L299.013 761.85L293.305 765.239L288.569 761.85L282.86 747.615L283.589 745.694L284.682 742.305L294.641 742.079L299.013 743.887L303.143 745.694L312.98 749.535L309.094 757.557Z" fill="#009B3E"/>
                <path className="region_color12" d="M313.83 737.447L312.494 741.853L299.013 743.887L294.641 742.079L284.682 742.305L283.589 745.694L261.971 737.786L265.007 718.128L266.586 718.015L272.78 717.224L279.824 731.007L291.483 734.058L294.277 726.149L305.936 726.036L313.83 737.447Z" fill="#009B3E"/>
                <path className="region_color24" d="M261.971 737.786L259.785 751.456L239.259 746.372L238.895 735.413L252.133 732.815L261.971 737.786Z" fill="#009B3E"/>
                <path d="M471.87 743.422L470.583 744.618" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M468.275 746.773L455.584 758.618" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.46 4.46"/>
                <path d="M454.431 759.701L453.143 760.897L451.322 760.863" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M447.654 760.795L432.292 760.524L418.386 766.605L402.89 776.589" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="5 5"/>
                <path d="M401.372 777.559L399.879 778.529L398.628 777.288" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M396.15 774.863L389.774 768.602L381.807 762.126" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.92 4.92"/>
                <path d="M380.447 761.021L379.075 759.904L377.836 761.145" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M376.343 762.634L374.108 764.868" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="2.98 2.98"/>
                <path d="M373.367 765.624L372.128 766.853L370.306 766.786" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M366.117 766.616L351.434 766.018" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="5.73 5.73"/>
                <path d="M349.345 765.939L347.524 765.86L347.013 764.236" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M346.042 761.111L342.63 750.18" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.77 4.77"/>
                <path d="M342.144 748.612L341.646 746.987L342.994 745.848" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M345.872 743.422L355.964 734.928" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="5.3 5.3"/>
                <path d="M357.409 733.721L358.757 732.581" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10"/>
                <path d="M215.964 883.601L230.938 875.152L254.474 876.156V865.225L275.058 860.001L290.834 836.413L305.116 841.015L315.827 818.758L331.481 815.542L352.333 842.865L366.773 824.488L385.499 825.481L416.516 845.347L430.422 871.677" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M321.316 816.039L320.248 798.158L300.464 799.647L302.067 789.709L285.49 775.799L258.627 765.804L239.159 748.036" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M197.783 634.717L222.922 633.725V644.148L266.776 635.71L260.886 658.058L241.637 672.475L260.351 682.899L265.695 718.175" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M351.265 718.672L357.155 705.258L300.999 680.417L298.327 670.975L303.671 670.479L305.274 664.026L313.836 656.569L295.109 657.065L292.971 650.116L260.886 658.058" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M387.175 677.112L376.403 690.852L391.377 698.298L376.938 710.222L357.154 705.258" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M467.85 614.344L446.464 604.902L445.93 584.043L413.298 581.065L382.123 598.438L346.989 589.006L308.479 590.496L256.611 620.808L266.775 635.71" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M350.062 481.825L352.066 512.013H376.938L371.327 549.263L351.264 564.673L346.99 589.006" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M366.773 824.488L366.238 791.198L376.937 777.785L372.128 766.853" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M352.066 567.155L386.167 561.932L387.37 549.263L408.622 535.105L415.448 536.606L416.65 501.206L440.307 497.483L435.097 475.496" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M378.941 598.077L391.377 631.976L400.607 634.966V643.156L389.373 651.729L397.802 662.909L410.225 664.026" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M372.396 735.311L373.999 755.933L376.67 761.641" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M426.912 761.946L414.913 748.476L405.817 741.527L397.255 751.703L379.075 759.904" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M292.582 766.616L287.907 763.243L282.199 749.006L284.008 743.704L293.882 743.467L302.444 747.089L312.305 750.947L308.419 758.9L297.246 757.208L298.29 763.243L292.582 766.616Z" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M262.465 739.846L281.41 746.604" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M307.12 730.201L313.083 738.887L311.795 743.219L299.323 745.16" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M293.882 781.564C293.882 781.564 296.481 776.747 296.736 773.847C296.991 770.948 292.583 766.616 292.583 766.616" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M320.248 727.121L342.678 720.792L331.518 714.046L335.938 696.2" stroke="#FDFFFD" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M239.159 748.036L259.088 752.876L264.286 719.597L272.119 718.672L279.078 732.367L290.761 735.503L293.506 727.618L320.248 727.121L341.646 746.987" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path d="M258.311 737.68L251.389 734.262" stroke="#FDFFFD" stroke-opacity="0.8" stroke-width="3.31365" stroke-miterlimit="10" stroke-dasharray="4.97 4.97"/>
                <path className="region_color2" fill-rule="evenodd" clip-rule="evenodd" d="M550.127 0L560 7.34402L547.661 29.3534L540.253 24.7733L550.127 0Z" fill="#009B3E"/>
                <path className="region_color2" fill-rule="evenodd" clip-rule="evenodd" d="M526.421 17.8918L536.306 23.397L533.343 58.7069L521.976 61.4595L520.992 36.6411L526.421 17.8918Z" fill="#009B3E"/>
                <path className="region_color2" fill-rule="evenodd" clip-rule="evenodd" d="M508.156 37.16V69.2661L516.511 61.9221L530.866 80.3103L528.898 116.545L512.601 164.253L501.731 161.5L511.653 119.253L503.747 104.587L493.376 115.135L468.686 88.9968L502.266 76.1476L480.539 59.6433L508.156 37.16Z" fill="#009B3E"/>
                <path className="region_color2" fill-rule="evenodd" clip-rule="evenodd" d="M542.719 37.16L546.18 45.8691L558.519 41.7402L542.719 37.16Z" fill="#009B3E"/>
            </svg>

        </>
    );
};

export default Map;
