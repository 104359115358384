/* eslint-disable */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

function HookForm(props) {
    let { onSubmit, init } = props;

    /**
     * Initializes a form using the useForm hook from the react-hook-form library.
     * @param {Object} props - An object containing the default form values.
     * @param {Object} props.defaultForm - An object containing the default values for the form.
     * @returns An object containing the form methods and state.
     */
    const method = useForm({
        mode: "onChange",
        defaultValues: props.defaultForm,
        criteriaMode: "all"
    });
    /**
     * Runs the init function with the given method when the component mounts.
     * @param {function} init - The initialization function to run.
     * @param {string} method - The method to pass to the initialization function.
     * @returns None
     */
    useEffect(() => {
        init && init(method);
    }, [])

    return (
        <form onSubmit={method.handleSubmit(onSubmit)}>
            {props.children(method)}
        </form>
    );
}

/**
 * Default props for the HookForm component.
 * @param {object} defaultValues - The default values for the form fields.
 * @param {function} onSubmit - The function to call when the form is submitted. If not provided, a default function is used that logs a message to the console.
 * @returns None
 */
HookForm.defaultProps = {
    defaultValues: {},
    onSubmit: () => { console.log('onSubmit function prop is not passed..!!') }
}
export default HookForm;
