import React, { useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import { Fade, Modal } from "react-bootstrap";

import "./ConfirmModal.scss";

/**
 * A modal component that displays a confirmation message with two buttons.
 * @param {Object} props - The props object containing the following properties:
 *   @param {boolean} showModal - A boolean indicating whether or not to show the modal.
 *   @param {string} className - A string containing additional CSS classes to apply to the modal.
 *   @param {string} modalTitle - The title of the modal.
 *   @param {boolean} closeIcon - A boolean indicating whether or not to show the close icon.
 *   @param {string} modalDescription - The description of the modal.
 *   @param {string} leftBtnTitle - The title of the left button.
 *   @param {string}
 */
const ConfirmModal = (props) => {
  return (
    <Modal
      className={"confirmModal " + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onPressRight()}
      centered={false}
    >
      <div className="modal-header">
        <p>{props.modalTitle}</p>
        {props.closeIcon && (
          <i
            className="icon icon-x"
            onClick={() => {
              props.onClose();
            }}
          ></i>
        )}
      </div>
      <Modal.Body>
        {props.modalDescription && (
          <p className="modalContent">{props.modalDescription}</p>
        )}
      </Modal.Body>
      <div className="modal-footer">
        <CustomButton
          type="button"
          title={props.leftBtnTitle}
          className="leftBtn"
          onClick={() => {
            props.onPressLeft(true);
          }}
        ></CustomButton>
        <CustomButton
          type="button"
          title={props.rightBtnTitle}
          className="rightBtn"
          onClick={() => {
            props.onPressRight(false);
          }}
        ></CustomButton>
      </div>
    </Modal>
  );
};

/**
 * Default props for the ConfirmModal component.
 * @param {boolean} modalContent - Whether or not the modal content should be displayed.
 */
ConfirmModal.defaultProps = {
  modalContent: true,
};

export default ConfirmModal;
