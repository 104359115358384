import React, { useEffect, useState, useRef } from "react";
import { multilanguage } from "redux-multilanguage";
import Modal from 'react-bootstrap/Modal';
import { Dropdown, Toast } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

import CheckBox from "../../../assets/components/UI/CheckBox/CheckBox";
import SearchBox from "../../../assets/components/UI/SearchBox/SearchBox";
import Images from "../../../assets/IMGS/Images";
import CustomButton from "../../../assets/components/UI/CustomButton/CustomButton";
import ConfirmModal from "../../../assets/components/UI/ConfirmModal/ConfirmModal";
import { showLoader, hideLoader } from "../../../redux/actions/loaderActions";

import useWindowDimension from '../../../hooks/useWindowDimension';
import AxiosInstance from "../../../services/axios";
import MetaTags from 'react-meta-tags';

import "./DirectoryScreen.scss";

const DirectoryScreen = (props) => {
    let { strings } = props;
    const scrollBar = useRef();
    const scrollTopRef = useRef()
    let history = useNavigate();
    let location = useLocation()
    const dimensions = useWindowDimension();
    const [activeTab, setActiveTab] = useState("books");
    const [booksDataShow, setBooksDataShow] = useState(false);
    const [eventsDataShow, setEventsDataShow] = useState(false);
    const [shopsDataShow, setShopsDataShow] = useState(false);
    const [closeHide, setCloseHide] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [filtterDataShow, setFiltterDataShow] = useState(false);
    const filtterClose = () => setFiltterDataShow(false);
    const filtterShow = () => { setFiltterDataShow(true); setFilterDropdownShow(false) };
    const [filterRegionsList, setFilterRegionsList] = useState([]);
    const [bookData, setBookData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [eventsData, setEventsData] = useState([]);
    const [shopsData, setShopsData] = useState([]);
    const [filterByData, setFilterByData] = useState([]);
    const [page, setPage] = useState(1);
    const [searchFilterHeight, setSearchFilterHeight] = useState(0);
    const [seletedFilterDataHeight, setSeletedFilterDataHeight] = useState(0);
    const [titleAreaHeight, setTitleAreaHeight] = useState(0);
    const [filterDropdownShow, setFilterDropdownShow] = useState(false);
    const dimension = useWindowDimension();
    const [searchbar_areaHeight, setSearchbar_areaHeight] = useState(0);
    const [categoriesHeight, setCategoriesHeight] = useState(0);

    const dropClose = () => setFilterDropdownShow(false);

    const [bookMark, setBookMark] = useState(false);
    const [removeBookMark, setRemoveBookMark] = useState(false);
    const [toastBookMark, setToastBookMark] = useState(false);
    const [recentEvents, setRecentEvents] = useState([]);
    const [recentBooks, setRecentBooks] = useState([]);
    const [isScroll, setIsScroll] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [finalSelectedRegions, setfinalSelectedRegions] = useState([])

    /**
     * Sets the height of the categories element using the offsetHeight property.
     * This effect runs once when the component mounts.
     * @returns None
     */
    useEffect(() => {
        setCategoriesHeight(document.getElementsByClassName("categories")[0]?.offsetHeight);
    }, []);

    /**
     * useEffect hook that sets the height of various elements on the page based on their
     * current offsetHeight. This is used to dynamically adjust the layout of the page.
     * @param {boolean} booksDataShow - Whether or not the books data is currently being displayed.
     * @param {boolean} eventsDataShow - Whether or not the events data is currently being displayed.
     * @param {boolean} shopsDataShow - Whether or not the shops data is currently being displayed.
     * @returns None
     */
    useEffect(() => {
        setSearchFilterHeight(document.getElementsByClassName("search_filter")[0]?.offsetHeight);
        setSeletedFilterDataHeight(document.getElementsByClassName("seleted_filter_data")[0]?.offsetHeight);
        setTitleAreaHeight(document.getElementsByClassName("title_area")[0]?.offsetHeight);
    }, [booksDataShow, eventsDataShow, shopsDataShow]);

    /**
     * useEffect hook that sets the state of the data to show based on the location state.
     * @param {Array} dependencies - An array of dependencies that the hook depends on.
     * @returns None
     */
    useEffect(() => {
        if (location?.state !== null) {
            if (location.state.trageted === 'detail') {
                if (location.state.detailType === 'Book') {
                    setBooksDataShow(true)
                } else if (location.state.detailType === 'Event') {
                    setEventsDataShow(true)
                } else {
                    setShopsDataShow(true)
                }
            } else {
                if (location.state.detailType === 'Book') {
                    setBooksDataShow(false)
                } else if (location.state.detailType === 'Event') {
                    setEventsDataShow(false)
                } else {
                    setShopsDataShow(false)
                }
            }
        }
    }, [])

    /**
     * Scrolls the window to the top of the page with a smooth animation.
     * @returns None
     */
    const scrollToTop = () => {
        scrollTopRef.current.scrollIntoView({
            behavior: 'smooth'
        });
    };

    /**
     * Removes the selected bookmark from the bookmark data stored in local storage.
     * @param {Event} e - The event object.
     * @returns None
     */
    const onPressRemoveBookMark = (e) => {
        const getbookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
        if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
            const newData = getbookMarkBookArr.filter((item) => selectedItem._id !== item._id)
            localStorage.setItem('bookmark_data', JSON.stringify(newData))
        }
        setBookMark(false);
        setRemoveBookMark(false);
        setToastBookMark(true);
        setTimeout(() => {
            setToastBookMark(false)
        }, 1500);
    };

    /**
     * Sets the active tab to "books" and calls the recentBook function.
     * @returns None
     */
    const handleBooks = () => {
        setActiveTab("books");
        recentBook()
    };

    /**
     * Sets the active tab to "events" and calls the recentEvent function.
     * @returns None
     */
    const handleEvents = () => {
        setActiveTab("events");
        recentEvent()
    }

    /**
     * Removes a region from the filterByData and selectedRegion arrays, and updates the filterRegionsList array.
     * @param {string} id - the id of the region to remove
     * @param {string} regionName - the name of the region to remove
     * @returns None
     */
    const removeRegion = (id, regionName) => {
        const remove = filterByData.filter((item) => item._id !== id)
        const removeRegion = selectedRegion.filter((item) => item._id !== id);
        setFilterByData(remove);

        let filterRegionRemovedIndex = filterRegionsList.findIndex((item) => item._id === id)
        let dropdownlist = [...filterRegionsList]
        dropdownlist[filterRegionRemovedIndex].checked = false
        setFilterRegionsList([...dropdownlist])

        setSelectedRegion(removeRegion);
        onChangeCheckBox(false, id)
        // getBookData(remove)

        getEventData(remove)
        getShopData(remove)
        scrollToTop()
        // window.scrollTo(0, 0)
        // scrollTopRef.current.scrollIntoView()
        setPage(1)
        setfinalSelectedRegions(removeRegion)
    }

    /**
     * Runs once when the component mounts and sets the initial state of the component.
     * @returns None
     */
    useEffect(() => {
        setFilterRegionsList(filterRegionData);
        getRegionId()
        setBookData([])
        recentBook()
    }, []);

    /**
     * useEffect hook that updates the selected region state whenever the filterRegionsList changes.
     * @param {function} setSelectedRegion - the function to update the selected region state
     * @param {Array} filterRegionsList - the list of filter regions to filter by
     * @returns None
     */
    useEffect(() => {
        setSelectedRegion(filterRegionsList.filter((child) => { if (child?.checked) return child }))
    }, [filterRegionsList])

    // set selected region on selecting apply
    const setSelectedregionForapi = () => {
        setfinalSelectedRegions(filterRegionsList.filter((child) => { if (child?.checked) return child }))
    }

    /**
     * Runs the specified functions to get data for books, events, and shops based on the selected region.
     * @param {boolean} booksDataShow - Whether or not to show data for books.
     * @param {string} searchValue - The search value to filter the data.
     * @param {boolean} eventsDataShow - Whether or not to show data for events.
     * @param {boolean} shopsDataShow - Whether or not to show data for shops.
     * @param {number} page - The current page number.
     * @returns None
     */
    useEffect(() => {
        if (booksDataShow === true) {
            getBookData([...selectedRegion]);
        }
        if (eventsDataShow === true) {
            getEventData([...selectedRegion]);
        }
        if (shopsDataShow === true) {
            getShopData([...selectedRegion]);
        }
    }, [booksDataShow, searchValue, eventsDataShow, shopsDataShow, page, finalSelectedRegions])

    /**
     * Fetches the user's recently explored book from the server using Axios.
     * Sets the state of the component with the fetched data.
     * @returns None
     */
    const recentBook = () => {
        AxiosInstance.get(`user/exploreRecentBook`)
            .then((res) => {
                setRecentBooks(res.payload)
            }).catch((err) => {
            })
    }
    /**
     * Fetches the most recent event from the server and sets the state of the component
     * with the response data.
     * @returns None
     */
    const recentEvent = () => {
        AxiosInstance.get(`user/explorerecent`)
            .then((res) => {
                setRecentEvents(res.payload)
            }).catch((err) => {
            })
    }

    /**
     * Fetches a list of regions from the server and sets the state of the component with the list.
     * @returns None
     */
    const getRegionId = () => {
        AxiosInstance.get(`user/getallregionSortName`)
            .then((res) => {
                setFilterRegionsList(res.payload)
            }).catch((err) => {
            })
    }

    /**
     * Retrieves book data from the server based on the given filter array, page number, and search value.
     * @param {Array} filterArr - An array of filters to apply to the book data.
     * @returns None
     */
    const getBookData = (filterArr) => {
        props.showLoader()
        let array = []
        filterArr && filterArr?.map((item, index) => {
            array.push(item?._id)
        })
        let params = `?page=${page}&limit=20`;
        if (searchValue) {
            params += `&search=${searchValue}`
        }
        if (filterArr?.length > 0) {
            params += `&regionIds=${array}`;
        }
        AxiosInstance.get(`user/booklist${params}`)
            .then((res) => {
                if (page === 1) {
                    setBookData([])
                    setBookData(res.payload)
                    setIsScroll(true)
                } else {
                    if (res.payload.length > 0) {
                        setBookData([...bookData, ...res.payload]);
                        setIsScroll(true)
                    } else {
                        setIsScroll(false)
                        if (page === 1) {
                            setBookData([])
                        }
                    }
                }
                props.hideLoader()
            }).catch((e) => {
                props.hideLoader()
            })
    }

    /**
     * Retrieves event data from the server based on the given filter array, page number, and search value.
     * @param {Array} filterArr - An array of filters to apply to the event data.
     * @returns None
     */
    const getEventData = (filterArr) => {
        props.showLoader()
        let array = []
        filterArr && filterArr?.map((item, index) => {
            array.push(item?._id)
        })
        let params = `?page=${page}&limit=20`;
        if (searchValue) {
            params += `&search=${searchValue}`
        }
        if (filterArr?.length > 0) {
            params += `&regionIds=${array}`;
        }
        AxiosInstance.get(`user/eventlist${params}`)
            .then((res) => {
                if (page === 1) {
                    setEventsData([])
                    setEventsData(res.payload)
                    setIsScroll(true)
                } else {
                    if (res.payload.length > 0) {
                        setEventsData([...eventsData, ...res.payload]);
                        setIsScroll(true)
                    } else {
                        setIsScroll(false)
                        if (page === 1) {
                            setEventsData([])
                        }
                    }
                }
                props.hideLoader()
            }).catch((e) => {
                props.hideLoader()
            })
    }

    /**
     * Retrieves shop data from the server based on the given filter array, page number, and search value.
     * @param {Array} filterArr - An array of filters to apply to the shop data.
     * @returns None
     */
    const getShopData = (filterArr) => {
        props.showLoader()
        let array = []
        filterArr && filterArr?.map((item, index) => {
            array.push(item?._id)
        })
        let params = `?page=${page}&limit=20`;
        if (searchValue) {
            params += `&search=${searchValue}`
        }
        if (filterArr?.length > 0) {
            params += `&regionIds=${array}`;
        }
        AxiosInstance.get(`user/shoplist${params}`)
            .then((res) => {
                if (page === 1) {
                    setShopsData([])
                    setShopsData(res.payload)
                    setIsScroll(true)
                } else {
                    if (res.payload.length > 0) {
                        setShopsData([...shopsData, ...res.payload]);
                        setIsScroll(true)
                    } else {
                        setIsScroll(false)
                        if (page === 1) {
                            setShopsData([])
                        }
                    }
                }
                props.hideLoader()
            }).catch((err) => {
                props.hideLoader()
            })
    }

    /**
     * Updates the checked status of a filter region in the filterRegionsList state.
     * @param {boolean} status - The new checked status of the filter region.
     * @param {string} id - The id of the filter region to update.
     * @returns None
     */
    const onChangeCheckBox = (status, id) => {
        let dupFilterRegionList = JSON.parse(
            JSON.stringify(filterRegionsList)
        );
        for (var i = 0; i < dupFilterRegionList.length; i++) {
            if (dupFilterRegionList[i].id === id) {
                dupFilterRegionList[i].checked = status;
            }
        }
        setFilterRegionsList([...dupFilterRegionList]);
    };

    const filterRegionData = [
        {
            id: "1",
            title: "All Region",
            value: "All Region",
            checked: false
        },
        {
            id: "2",
            title: "Edinburgh",
            value: "Edinburgh",
            checked: false
        },
        {
            id: "3",
            title: "Highlands",
            value: "Highlands",
            checked: false
        },
        {
            id: "4",
            title: "Fife",
            value: "Fife",
            checked: false
        },
        {
            id: "5",
            title: "Angus",
            value: "Angus",
            checked: false
        },
    ];

    /**
     * Renders a list of recent books with bookmark status and click events.
     * @returns A list of recent books with bookmark status and click events.
     */
    const TabBooksData = () => {
        let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];
        return (
            recentBooks.map((val, index) => {
                val['bookmark'] = localstorageBooks?.some((item) => item._id === val._id)
                val['key'] = 'Book'
                return <>
                    <div className="recent_books_cards" key={index} onClick={() => {
                        val['key'] = 'Book'
                        history('/discover', {
                            state: { val: val, isFrom: "recent", type: 'Books', detailType: 'Book', redirectFrom: '/directory', trageted: 'list', mainTargeted: 'directory' }
                        })
                    }}>
                        <div className="book_card">
                            <div className="left_side">
                                <img src={val.profileImage ? val.profileImage : " https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png"} alt="" />
                            </div>
                            <div className="right_side">
                                <div className="contain">
                                    <p className="date">{strings["PUBLISHED_DATE"]}: {moment(val.publishDate).format('DD MMM YYYY') ? moment(val.publishDate).format('DD MMM YYYY') : '-'}</p>
                                    <h3 className="book_name">{val.Title}</h3>
                                    <h4 className="novel_name">{val.SubTitle}</h4>
                                    <h4 className="book_author">by {val.authorName ? val.authorName : '-'}</h4>
                                    <h3 className="region_name">{val.regionName}</h3>
                                </div>
                                <div className="bookmark_events">
                                    {<span className={val?.bookmark ? "icon-heart-fill" : "icon-fav"}
                                        onClick={(e) => {
                                            setSelectedItem(val)
                                            if (val?.bookmark) {
                                                setRemoveBookMark(true)
                                            }
                                            else {
                                                let bookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(val)
                                                    localStorage.setItem('bookmark_data', JSON.stringify(bookMarkBookArr));
                                                } else {
                                                    localStorage.setItem('bookmark_data', JSON.stringify([val]));
                                                }
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark)
                                        }}></span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })
        );
    };

    /**
     * Returns a list of recent events with bookmark data added to each event.
     * @returns A list of recent events with bookmark data added to each event.
     */
    const TabEventsData = () => {
        let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];
        return (
            recentEvents.map((val, index) => {
                val['bookmark'] = localstorageBooks?.some((item) => item._id === val._id)
                val['key'] = 'Event'
                return <>
                    <div className="Recent_events_card" onClick={() => {
                        val['key'] = 'Event'
                        history('/discover', {
                            state: { val: val, type: 'Events', isFrom: "recent", detailType: 'Event', redirectFrom: '/directory', trageted: 'list', mainTargeted: 'directory' }
                        })
                    }}>
                        <div className="top_side">
                            <img src={val.profileImage ? val.profileImage : "https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg"} alt="" />
                        </div>
                        <div className="down_side">
                            <div className="name_bookmark">
                                <div className="event_name">
                                    <h4>{val.name}</h4>
                                </div>
                                <div className="bookmark_events">
                                    {<span className={val?.bookmark ? "icon-heart-fill" : "icon-fav"}
                                        onClick={(e) => {
                                            setSelectedItem(val)
                                            if (val?.bookmark) {
                                                setRemoveBookMark(true)
                                            }
                                            let bookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                                            if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                bookMarkBookArr.push(val)
                                                localStorage.setItem('bookmark_data', JSON.stringify(bookMarkBookArr));
                                            } else {
                                                localStorage.setItem('bookmark_data', JSON.stringify([val]));
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark)
                                        }}></span>}
                                </div>
                            </div>
                            <div className="region_month">
                                <div className="region_name">
                                    <p>{val.regionName}</p>
                                </div>
                                <div className="month_name">
                                    <p>{val.month}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })
        );
    };

    /**
     * Returns a list of book data with bookmark information and click events.
     * @returns A list of book data with bookmark information and click events.
     */
    const BooksData = () => {
        let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];
        return (
            bookData.map((val, index) => {
                val['bookmark'] = localstorageBooks?.some((item) => item._id === val._id)
                val['key'] = 'Book'
                return <>
                    <div className="recent_books_cards" key={index} onClick={() => {
                        val['key'] = 'Book'
                        history('/discover', {
                            state: { val: val, type: 'Books', detailType: 'Book', redirectFrom: '/directory', trageted: 'detail', mainTargeted: 'directory' }
                        })
                    }} >
                        <div className="book_card">
                            <div className="left_side">
                                <img src={val.profileImage ? val.profileImage : " https://res.cloudinary.com/zudu/image/upload/v1657769495/Birlinn/Web/Project-files/Frame_811435.png"} alt="" />
                            </div>
                            <div className="right_side">
                                <div className="contain">
                                    <p className="date">{strings["PUBLISHED_DATE"]}: {moment(val.publishDate).format('DD MMM YYYY') ? moment(val.publishDate).format('DD MMM YYYY') : '-'}</p>
                                    <h3 className="book_name">{val.Title}</h3>
                                    <h4 className="novel_name">{val.SubTitle}</h4>
                                    <h4 className="book_author">by {val.authorName ? val.authorName : '-'}</h4>
                                    <h3 className="region_name">{val.regionName}</h3>
                                </div>
                                <div className="bookmark_events">
                                    {<span className={val?.bookmark ? "icon-heart-fill" : "icon-fav"}
                                        onClick={(e) => {
                                            setSelectedItem(val)
                                            if (val?.bookmark) {
                                                setRemoveBookMark(true)
                                            }
                                            else {
                                                let bookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(val)
                                                    localStorage.setItem('bookmark_data', JSON.stringify(bookMarkBookArr));
                                                } else {
                                                    localStorage.setItem('bookmark_data', JSON.stringify([val]));
                                                }
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark)
                                        }}></span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })
        );
    };

    /**
     * Returns a list of event cards with bookmark functionality and click event.
     * @returns A list of event cards with bookmark functionality and click event.
     */
    const EventsData = () => {
        let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];
        return (
            eventsData.map((val, index) => {
                val['bookmark'] = localstorageBooks?.some((item) => item._id === val._id)
                val['key'] = 'Event'
                return <>
                    <div className="Recent_events_card" onClick={() => {
                        val['key'] = 'Event'
                        history('/discover', {

                            state: { val: val, type: 'Events', detailType: 'Event', redirectFrom: '/directory', trageted: 'detail', mainTargeted: 'directory' }
                        })
                    }}>
                        <div className="top_side">
                            <img src={val.profileImage ? val.profileImage : "https://res.cloudinary.com/scotlandbythebook/image/upload/v1663308405/Birlinn/Event%20Images/Event_Default_wide_nyuvhx.svg"} alt="" />
                        </div>
                        <div className="down_side">
                            <div className="name_bookmark">
                                <div className="event_name">
                                    <h4>{val.name}</h4>
                                </div>
                                <div className="bookmark_events">
                                    {<span className={val?.bookmark ? "icon-heart-fill" : "icon-fav"}
                                        onClick={(e) => {
                                            setSelectedItem(val)
                                            if (val?.bookmark) {
                                                setRemoveBookMark(true)
                                            } else {
                                                let bookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                                                if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                    bookMarkBookArr.push(val)
                                                    localStorage.setItem('bookmark_data', JSON.stringify(bookMarkBookArr));
                                                } else {
                                                    localStorage.setItem('bookmark_data', JSON.stringify([val]));
                                                }
                                            }
                                            e.stopPropagation();
                                            setBookMark(!bookMark)
                                        }}></span>}
                                </div>
                                {/* <div className="bookmark_events">
                                    {!bookMark && <span className="icon-fav" onClick={(e) => { e.stopPropagation(); setBookMark(true) }}></span>}
                                    {bookMark && <span className="icon-heart-fill" onClick={(e) => { e.stopPropagation(); setRemoveBookMark(true) }}></span>}
                                </div> */}
                            </div>
                            <div className="region_month">
                                <div className="region_name">
                                    <p>{val.regionName}</p>
                                </div>
                                <div className="month_name">
                                    <p>{val.month}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })
        );
    };

    /**
     * Renders a list of shop cards with bookmark functionality. 
     * @returns A list of shop cards with bookmark functionality.
     */

    const getServiceTimingTitle = (serviceTimeDetails) => {
        let stationText;
        const currentDate = moment();
        const currentDay = currentDate.format('dddd');
        const currentTime = currentDate.format('HH:mm');
        const currentDayData = serviceTimeDetails.find(item => item.day === currentDay);
        if (currentDayData?.status) {
            if (currentDayData?.fullDay) {
                stationText = '24 Hours Open';
            } else {
                const startTime = moment(currentDayData?.startTime, 'hh:mm a');
                const endTime = moment(currentDayData?.endTime, 'hh:mm a');
                if (currentDate.isBetween(startTime, endTime)) {
                    const diffMinutes = endTime.diff(currentDate, 'minutes');
                    if (diffMinutes <= 0) {
                        stationText = 'Closed';
                    } else if (diffMinutes <= 60 && diffMinutes > 0) {
                        stationText = 'Closes Soon at ' + endTime.format('hh:mm A');
                    } else {
                        stationText = `Open ${currentDayData?.startTime + " to " + currentDayData?.endTime}`;
                    }

                } else {
                    stationText = 'Closed';
                }
            }
        } else {
            stationText = 'Closed';
        }
        return (
            <>{stationText}</>
        );
    }

    const ShopData = () => {
        let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];

        return (
            shopsData.map((val, index) => {
                val['bookmark'] = localstorageBooks?.some((item) => item._id === val._id)
                val['key'] = 'Shop'
                return <>
                    <div className="shop_cards" onClick={() => {
                        val['key'] = 'Shop'
                        history('/discover', {
                            state: { val: val, type: 'Shops', detailType: 'Shop', redirectFrom: '/directory', trageted: 'detail', mainTargeted: 'directory' }
                        })
                    }}>
                        {console.log('val', val)}
                        <div className="contain_area">
                            <div className="main_contain">
                                <div className="shop_img">
                                    <img src={val.shopImage ? val.shopImage : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811437.svg'} alt="" />
                                </div>
                                <div className="shop_text">
                                    <div className="time">
                                        <p>{getServiceTimingTitle(val.serviceTiming)}</p>
                                    </div>
                                    <div className="shop_name">
                                        <p>{val.name}</p>
                                    </div>
                                    <div className="region_name">
                                        <p>{val.regionName}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="bookmark_events">
                                {<span className={val?.bookmark ? "icon-heart-fill" : "icon-fav"}
                                    onClick={(e) => {
                                        setSelectedItem(val)
                                        if (val?.bookmark) {
                                            setRemoveBookMark(true)
                                        } else {
                                            let bookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                                            if (bookMarkBookArr && bookMarkBookArr.length > 0) {
                                                bookMarkBookArr.push(val)
                                                localStorage.setItem('bookmark_data', JSON.stringify(bookMarkBookArr));
                                            } else {
                                                localStorage.setItem('bookmark_data', JSON.stringify([val]));
                                            }
                                        }
                                        e.stopPropagation();
                                        setBookMark(!bookMark)
                                    }}></span>}
                            </div>
                        </div>
                    </div>
                </>
            })
        );
    };

    /**
     * Renders the Directory page with a list of books, events, and shops. Allows users to filter and search for specific items.
     * @returns {JSX.Element} - The rendered Directory page.
     */

    const resetOnTabChange = (key) => {
        setFilterByData([]);
        setSelectedRegion([]);
        // setFilterRegionsList(filterRegionData)
        setFilterRegionsList(filterRegionsList.map((child) => ({...child, checked: false}) ))
        setPage(1)
        if(key === 'book'){
            setBooksDataShow(true);  
        } else if(key === 'event'){
            setEventsDataShow(true); 
        } else if(key === 'shop') {
            setShopsDataShow(true); 
        }
    }

    return (
        <>
            <MetaTags>
                <title>Directory | Scotland By-The-Book - Birlinn Ltd</title>
                <meta name="description" content="Browse through our books, events, and shops directory to learn more about local festivals, bookshops, and more within each Scottish region." />
                <meta property="og:title" content="Directory | Scotland By-The-Book - Birlinn Ltd" />
            </MetaTags>
            {<div style={{ height: dimension.height - searchbar_areaHeight + "px" }} className={`directory_main ${booksDataShow ? 'dnone' : eventsDataShow ? 'dnone' : shopsDataShow ? 'dnone' : closeHide ? 'dblock' : ''} `}>
                <div className="categories">
                    <div className="books_card" onClick={(e) => {  resetOnTabChange('book') }}>
                        <img src={Images.DIRECTORY.BOOKSCARD} alt="" />
                        <p className="card_text">{strings["BOOKS"]}</p>
                    </div>
                    <div className="event_card" onClick={(e) => {  resetOnTabChange('event') }}>
                        <img src={Images.DIRECTORY.EVENTSCARD} alt="" />
                        <p className="card_text">{strings["EVENTS"]}</p>
                    </div>
                    <div className="shop_card" onClick={(e) => {  resetOnTabChange('shop') }}>
                        <img src={Images.DIRECTORY.SHOPCARD} alt="" />
                        <p className="card_text">{strings["SHOPS"]}</p>
                    </div>
                </div>
                <div className="recent_highlights">
                    <div className="tabs_section">
                        <p className="text">{strings["RECENT_HIGHLIGHTS"]}</p>
                    </div>
                    <div className="Tabs">
                        <ul className="tab-btn">
                            <li className={`tab_btn_style ${activeTab === "books" ? "active" : ""}`} onClick={handleBooks}><i className="icon icon-books"></i><span>{strings["BOOKS"]}</span></li>
                            <li className={`tab_btn_style ${activeTab === "events" ? "active" : ""}`} onClick={handleEvents}><i className="icon icon-events"></i><span>{strings["EVENTS"]}</span></li>
                        </ul>
                        <div className="outlet" style={{ height: dimension.height - categoriesHeight - 152 + "px" }}>
                            <div className="outlet_main">
                                {activeTab === "books" ? <TabBooksData /> : <TabEventsData />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {(booksDataShow || eventsDataShow || shopsDataShow) && <div className={'data_main'}>
                <div className="title_area">
                    <a onClick={() => { setBooksDataShow(false); setEventsDataShow(false); setShopsDataShow(false); setCloseHide(true); setSearchValue('') }} className="close" href="javascript:;"><i className="icon icon-chevron-left"></i></a>
                    <p className="title_text">{booksDataShow && 'Books'}{eventsDataShow && 'Events'}{shopsDataShow && 'Shops'}</p>
                </div>
                <div className="data_n_filter">
                    <div className="search_filter">
                        <SearchBox
                            placeholder={(booksDataShow && 'Search by title, author, genre or region') || (eventsDataShow && 'Search by event name') || (shopsDataShow && 'Search by shop name')}
                            value={searchValue}
                            onClear={() => {
                                setSearchValue('')
                                setPage(1)
                            }}
                            onSearch={(e) => {
                                setSearchValue(e)
                                setPage(1)
                            }}
                        />
                        <a className="filter_btn" onClick={filtterShow} href="javascript:;">
                            <span><i className="icon icon-filtter"></i></span><span className="text_filter_btn">{strings["FILTER"]}</span>
                        </a>
                    </div>
                    <div className="seleted_filter_data">
                        <p className="title">{strings["FILTERBY"]}</p>
                        <div className="seleted_item">
                            <ul className="sleteted_ul">
                                {filterByData.length > 0 &&
                                    filterByData.map((item, index) => {
                                        return <li onClick={() => {
                                            removeRegion(item._id, item.name)
                                        }}>{item.name}<span><i className="icon icon-x"></i></span></li>
                                    })}
                            </ul>
                        </div>
                    </div>
                    <div className="data_section"
                        onScroll={(e) => {
                            // const { scrollTop, scrollHeight, clientHeight } = scrollBar?.current;
                            // if (Math.round(scrollTop + clientHeight) === scrollHeight) {
                            //     setPage(page + 1);
                            // }
                            const { scrollTop, scrollHeight, clientHeight } = scrollBar?.current;
                            if (Math.round(scrollTop + clientHeight) === scrollHeight) {
                                if (isScroll === true) {
                                    setPage(page + 1);
                                }
                            }
                        }}
                        ref={scrollBar} style={dimensions && { height: dimensions?.height - titleAreaHeight - searchFilterHeight - seletedFilterDataHeight - 29 + "px" }}>
                        {booksDataShow && <div ref={scrollTopRef} className="data_section_show"><BooksData /></div>}
                        {eventsDataShow && <div ref={scrollTopRef} className="data_section_show"><EventsData /></div>}
                        {shopsDataShow && <div ref={scrollTopRef} className="data_section_show"><ShopData /></div>}
                    </div>
                </div>
            </div>}

            <ConfirmModal
                leftBtnTitle={strings["ONLYYES"]}
                rightBtnTitle={strings["ONLYNO"]}
                modalTitle={strings["REMOVEBOOKMARK"]}
                modalDescription={strings["SUREREMOVEBOOKMARK"]}
                showModal={removeBookMark}
                onPressLeft={onPressRemoveBookMark}
                onPressRight={() => setRemoveBookMark(false)}
                className="remove_bookmark_popup"
            />

            <Toast className="bookmark_tost" show={toastBookMark} delay={3000} autohide>
                <div className="tostcenter">
                    <span className="tost_icon"><i className="icon icon-coolicon"></i></span>
                    <p className="bookmark_tost_text">{strings["THE_BOOKMARK_REMOVE_SUCCESSFULLY"]}</p>
                </div>
            </Toast>

            <Modal className="filter_popup" show={filtterDataShow} onHide={filtterClose} animation={false} onClick={dropClose}>
                <Modal.Header>
                    <Modal.Title>{strings["FILTER"]}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="drop_down">
                    <div className="tabDropdown">
                        <Dropdown className="d-inline mx-2" autoClose={"outside"} onClick={(e) => {
                            e.stopPropagation(); setFilterDropdownShow(true)
                        }}>
                            <Dropdown.Toggle id="dropdown-autoclose-outside" className="drop_text" >
                                {selectedRegion.length > 0 ?
                                    selectedRegion.map((item, index) => {
                                        if (selectedRegion.length - 1 > index) {
                                            return `${item.name}, `
                                        } else {
                                            return item.name;
                                        }
                                    })
                                    : 'Selected Region'}
                                <span className="icon-chevron-down drop_arrow"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align={"end"} placement="bottom-end" className={`${filterDropdownShow === false ? 'dropdown-menu showClose dropdown-menu-end' : 'dropdown-menu show dropdown-menu-end'} `} show={filterDropdownShow}>
                                <SearchBox placeholder="Search Region" />
                                <div className="list_style">
                                    {filterRegionsList?.map((item, index) => {
                                        return (
                                            <CheckBox
                                                keyId={index}
                                                id={item._id}
                                                labelTitle={item.name}
                                                checked={item.checked}
                                                checkedIcon="icon-check-square-top"
                                                onCheckedChange={(checked) => {
                                                    item['checked'] = checked
                                                    let regionList = [...filterRegionsList]
                                                    regionList[index]['checked'] = checked
                                                    setFilterRegionsList(regionList)
                                                    setFilterDropdownShow(false)
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Modal.Body>
                <Modal.Footer className="filter_btn">
                    <CustomButton
                        type="button"
                        title={strings["APPLY"]}
                        className="apply_btn"
                        disabled={selectedRegion.length === 0}
                        onClick={() => {
                            setPage(1)
                            setSelectedregionForapi()
                            // window.scrollTo(0, 0)
                            // scrollTopRef.current.scrollIntoView()
                            scrollToTop()
                            setFilterByData([...selectedRegion])
                            setFiltterDataShow(false)
                            if (booksDataShow === true) {
                                // getBookData([...selectedRegion]);
                            }
                            if (eventsDataShow === true) {
                                // getEventData([...selectedRegion]);
                            }
                            if (shopsDataShow === true) {
                                // getShopData([...selectedRegion]);
                            }
                        }}
                    >
                    </CustomButton>
                    <a className="cancle_btn" onClick={filtterClose}>
                        {strings["CANCEL"]}
                    </a>
                </Modal.Footer>
            </Modal>
        </>
    );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
    return {};
}

/**
 * A function that maps the showLoader and hideLoader actions to props in a component.
 * @returns An object containing the showLoader and hideLoader actions as props.
 */
const mapDispatchToProps = { showLoader, hideLoader };

/**
 * Connects the DirectoryScreen component to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state from the Redux store to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns A higher-order component that is connected to the Redux store and the multilanguage HOC.
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(DirectoryScreen));
