import React, { useState, useEffect, useMemo, useRef } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import { Offcanvas } from "react-bootstrap";
import ReactQuill from "react-quill";
import { Dropdown } from "react-bootstrap";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../redux/actions/toastAction";
import useWindowDimension from "../../hooks/useWindowDimension";
import SearchBox from "../../assets/components/UI/SearchBox/SearchBox";
import CheckBox from "../../assets/components/UI/CheckBox/CheckBox";
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../assets/components/UI/CustomDropdown/CustomDropdown";
import HookForm from "../../assets/components/HookForm/HookForm";
import Constant from "../../state/utils/constant";
import RadioButton from "../../assets/components/UI/RadioButton/RadioButton";
import CustomToggleButton from "../../assets/components/UI/CustomToggleButton/CustomToggleButton";
import FileUpload from "../../assets/components/UI/FileUpload/FileUpload";
import TextField from "../../assets/components/UI/TextField/TextField";
import Label from "../../assets/components/UI/Label/Label";
import ConfirmModal from "../../assets/components/UI/ConfirmModal/ConfirmModal";
import ModalPopup from "../../assets/components/UI/ModalPopup/ModalPopup";
import GooglePlaceDropDown from "../../assets/components/GooglePlaceDropDown/GooglePlaceDropDown";
import "react-datepicker/dist/react-datepicker.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import AxiosInstance from "../../services/axios";
import { uploadFIle } from "../../services/helper.service";
import InputMask from "react-input-mask";
import { useReducer } from "react";
import parse from 'html-react-parser';
import { showLoader, hideLoader } from "../../redux/actions/loaderActions";
import "./Shop.scss";
import MetaTags from 'react-meta-tags';

const Shop = (props) => {
  const quillRef = useRef();
  const dimensions = useWindowDimension();
  let history = useNavigate();
  let { strings, showToast } = props;
  const [searchText, setsearchText] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [stepForm, setStepForm] = useState();
  const [busy, setBusy] = useState(false);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [portLocation, setPortLocation] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);
  const [bookCategoryList, setBookCategoryList] = useState([]);
  const [bookType, setBookType] = useState(null);
  const [ShopData, setShopData] = useState([]);
  const [location, setLocation] = useState(null);

  const [businessCategoryList, setBusinessCategoryList] = useState([]);

  const [timingCategoryList, setTimingCategoryList] = useState([]);

  const [shopsortCategory, setShopsortCategory] = useState(null);
  const [shopsortList, setShopsortList] = useState([]);
  const [addshow, setAddShow] = useState(false);
  const addhandleClose = () => setAddShow(false);
  const addhandleShow = () => setAddShow(true);

  const [changeshow, setChangeShow] = useState(false);
  const [shopDetail, setShopDetail] = useState(null);
  const [showlistDetails, setShowListDetails] = useState(false);
  const [showEnableDisableModal, setShowEnableDisableModal] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [phone, setPhone] = React.useState("");
  const [csvFile, setCSVFile] = useState(null);
  const [csvFileName, setCSVFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [body, setBody] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [shopsRegion, setShopsRegion] = useState([]);
  const [selectedShopsRegion, setSelectedShopsRegion] = useState([])
  const [selectedShopId, setSelectedShopId] = useState(null);
  const [filteredShops, setFilteredShops] = useState(null);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [checkApply, setCheckApply] = useState(false);
  const [value, setValue] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [textEditor, setTextEditor] = useState('')
  const [textEditorDelta, setTextEditorDelta] = useState('')

  const limit = 12;

  // sync service timing data in shopdetail object
  useEffect(() => {
    setShopDetail(prev => {
      return {
        ...prev,
        serviceTiming: serviceData
      }
    })
  }, [serviceData])

  /**
   * Handles the change event when a CSV file is selected.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onCSVFileChange = async (acceptedFiles) => {
    setCSVFile(acceptedFiles[0]);
    setCSVFileName(acceptedFiles[0].name);
    setFileSize(acceptedFiles[0].size / 1000);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
    });

  };
  /**
   * Scrolls the window to the top of the page with a smooth animation.
   * @returns None
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  /**
   * The initial state for a pagination component. 
   * @type {{page: number, emptyList: boolean}}
   */
  const initialState = {
    page: 1,
    emptyList: false
  }

  /**
   * A reducer function that takes in a state and an action and returns a new state based on the action.
   * @param {object} state - the current state of the application
   * @param {object} action - the action to perform on the state
   * @returns {object} - the new state of the application
   * @throws {Error} - if the action type is not recognized
   */
  function reducer(state, action) {
    if (action.type === 'INCREMENT_PAGE') {
      return { ...state, page: state.page + 1 }
    } if (action.type === 'RESET_PAGE') {
      return { ...state, page: state.page = 1, emptyList: false }
    } if (action.type === 'EMPTY_LIST') {
      return { ...state, emptyList: true }
    }

    throw new Error(' no action type')
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  /**
   * Retrieves a list of shops based on the given search, filtering, and sorting parameters.
   * @param {string} search - The search query to filter the shops by.
   * @param {string} filtering - The region IDs to filter the shops by.
   * @param {string} sorting - The sorting method to use for the shops.
   * @returns None
   */
  const getShops = async (search, filtering, sorting) => {
    props.showLoader();
    let params = `?page=${state.page}&limit=${limit}`;
    if (search) {
      params += `&search=${search}`
    }
    if (filtering) {
      params += `&regionIds=${filtering}`
    }
    if (sorting) {
      params += `&sortBy=${sorting}`
    }
    await AxiosInstance.get(`admin/shop/get${params}`).then((res) => {
      props.hideLoader();
      if (state.page === 1) {
        setShopData([])
        scrollToTop();
        setShopData(res?.payload)
      } else {
        if (res?.payload?.length === 0) {
          dispatch({ type: 'EMPTY_LIST' })
        }
        if (res?.payload.length > 0) {

          setShopData([...ShopData, ...res?.payload]);
        } else {
          if (state.page === 1) {
            setShopData([])
          }
        }
      }
    }).catch((error) => {
      props.hideLoader();
    })
  };
  /**
   * Retrieves the list of shops regions from the server and sets the state of the component
   * with the response data.
   * @returns None
   */
  const getShopsRegion = async () => {
    await AxiosInstance.get('admin/region/getfilter').then((response) => {
      setShopsRegion(response.payload);
      const temp = response.payload.map((item) => {
        return { id: item._id, title: item.name, value: item.name };
      })
      setBookCategoryList(temp);
    })
  }
  /**
   * Runs the necessary functions to initialize the component and set the state variables.
   * @returns None
   */
  useEffect(() => {
    setBusinessCategoryList(businessCategoryData);
    setShopsortList(shopSortData);
    // setServiceData(timingContent);
    initTiming();
    setTimingCategoryList(timingCategoryData);
    // setBookCategoryList(bookCategories);
    setHeadingHeight(
      document.getElementsByClassName("headingHeight")[0]?.offsetHeight
    );
    getShopsRegion();
  }, []);



  /**
   * Runs the getShops function with the current search text, selected shop ID, and filtered shops
   * whenever any of these values change.
   * @param {function} getShops - the function to get the shops
   * @param {string} searchText - the current search text
   * @param {string} selectedShopId - the currently selected shop ID
   * @param {string} filteredShops - the currently filtered shops
   * @param {object} state - the current state object
   * @returns None
   */
  useEffect(() => {
    getShops(searchText ? searchText : '', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '')
  }, [selectedShopId, searchText, state.page, filteredShops])


  const timingContent = [
    {
      id: "t01",
      day: "Monday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
    {
      id: "t02",
      day: "Tuesday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
    {
      id: "t03",
      day: "Wednesday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
    {
      id: "t04",
      day: "Thursday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
    {
      id: "t05",
      day: "Friday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
    {
      id: "t06",
      day: "Saturday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
    {
      id: "t07",
      day: "Sunday",
      status: true,
      startTime: null,
      endTime: null,
      fullDay: false,
    },
  ];

  const timeData = [
    {
      id: "t01",
      title: "00:00 am",
      value: "00:00 am",
    },
    {
      id: "t02",
      title: "00:30 am",
      value: "00:30 am",
    },
    {
      id: "t03",
      title: "01:00 am",
      value: "01:00 am",
    },
    {
      id: "t04",
      title: "01:30 am",
      value: "01:30 am",
    },
    {
      id: "t05",
      title: "02:00 am",
      value: "02:00 am",
    },
    {
      id: "t06",
      title: "02:30 am",
      value: "02:30 am",
    },
    {
      id: "t07",
      title: "03:00 am",
      value: "03:00 am",
    },
    {
      id: "t08",
      title: "03:30 am",
      value: "03:30 am",
    },
    {
      id: "t09",
      title: "04:00 am",
      value: "04:00 am",
    },
    {
      id: "t10",
      title: "04:30 am",
      value: "04:30 am",
    },
    {
      id: "t11",
      title: "05:00 am",
      value: "05:00 am",
    },
    {
      id: "t12",
      title: "05:30 am",
      value: "05:30 am",
    },
    {
      id: "t13",
      title: "06:00 am",
      value: "06:00 am",
    },
    {
      id: "t14",
      title: "06:30 am",
      value: "06:30 am",
    },
    {
      id: "t15",
      title: "07:00 am",
      value: "07:00 am",
    },
    {
      id: "t16",
      title: "07:30 am",
      value: "07:30 am",
    },
    {
      id: "t17",
      title: "08:00 am",
      value: "08:00 am",
    },
    {
      id: "t18",
      title: "08:30 am",
      value: "08:30 am",
    },
    {
      id: "t19",
      title: "09:00 am",
      value: "09:00 am",
    },
    {
      id: "t20",
      title: "09:30 am",
      value: "09:30 am",
    },
    {
      id: "t21",
      title: "10:00 am",
      value: "10:00 am",
    },
    {
      id: "t22",
      title: "10:30 am",
      value: "10:30 am",
    },
    {
      id: "t23",
      title: "11:00 am",
      value: "11:00 am",
    },
    {
      id: "t24",
      title: "11:30 am",
      value: "11:30 am",
    },
    {
      id: "t25",
      title: "12:00 pm",
      value: "12:00 pm",
    },
    {
      id: "t26",
      title: "12:30 pm",
      value: "12:30 pm",
    },
    {
      id: "t27",
      title: "01:00 pm",
      value: "01:00 pm",
    },
    {
      id: "t28",
      title: "01:30 pm",
      value: "01:30 pm",
    },
    {
      id: "t29",
      title: "02:00 pm",
      value: "02:00 pm",
    },
    {
      id: "t30",
      title: "02:30 pm",
      value: "02:30 pm",
    },
    {
      id: "t31",
      title: "03:00 pm",
      value: "03:00 pm",
    },
    {
      id: "t32",
      title: "03:30 pm",
      value: "03:30 pm",
    },
    {
      id: "t33",
      title: "04:00 pm",
      value: "04:00 pm",
    },
    {
      id: "t34",
      title: "04:30 pm",
      value: "04:30 pm",
    },
    {
      id: "t35",
      title: "05:00 pm",
      value: "05:00 pm",
    },
    {
      id: "t36",
      title: "05:30 pm",
      value: "05:30 pm",
    },
    {
      id: "t37",
      title: "06:00 pm",
      value: "06:00 pm",
    },
    {
      id: "t38",
      title: "06:30 pm",
      value: "06:30 pm",
    },
    {
      id: "t39",
      title: "07:00 pm",
      value: "07:00 pm",
    },
    {
      id: "t40",
      title: "07:30 pm",
      value: "07:30 pm",
    },
    {
      id: "t41",
      title: "08:00 pm",
      value: "08:00 pm",
    },
    {
      id: "t42",
      title: "08:30 pm",
      value: "08:30 pm",
    },
    {
      id: "t43",
      title: "09:00 pm",
      value: "09:00 pm",
    },
    {
      id: "t44",
      title: "09:30 pm",
      value: "09:30 pm",
    },
    {
      id: "t45",
      title: "10:00 pm",
      value: "10:00 pm",
    },
    {
      id: "t46",
      title: "10:30 pm",
      value: "10:30 pm",
    },
    {
      id: "t47",
      title: "11:00 pm",
      value: "11:00 pm",
    },
    {
      id: "t48",
      title: "11:30 pm",
      value: "11:30 pm",
    },
    {
      id: "t49",
      title: "12:00 am",
      value: "12:00 am",
    },
  ];

  const ShopContent = [
    {
      id: "s01",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Waterstones.png",
      title: "Waterstones",
      metaTitle: "25 George Street, 33 Bon-Accord Centre, Aberdeen, AB25 1HZ",
      isEnabled: true,
    },
    {
      id: "s02",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Tackle.png",
      title: "Tackle & Books",
      metaTitle: "20 Dee Street, Banchory, AB31 5ST",
      isEnabled: true,
    },
    {
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Camphill.png",
      title: "Camphill Bookshop",
      metaTitle: "The Celtic House, Shore St, Bowmore, Isle of Islay PA43 7LD",
      isEnabled: true,
    },
    {
      id: "s03",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Colonsay.png",
      title: "Colonsay bookshop",
      metaTitle: "18 Ythan Terrace, Ellon, Aberdeenshire AB41 9LJ",
      isEnabled: true,
    },
    {
      id: "s04",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Bookshop.png",
      title: "Orb's Bookshop",
      metaTitle: "6-8 Main Street, Tobermory, Isle of Mull PA75 6NU",
      isEnabled: true,
    },
    {
      id: "s05",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797733/Birlinn/Web/Project-Images/Yeadons.png",
      title: "Yeadons of Banchory",
      metaTitle: "Unit 2, Lorne Street, Lochgilphead",
      isEnabled: true,
    },
    {
      id: "s06",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Waterstones.png",
      title: "Waterstones",
      metaTitle: "25 George Street, 33 Bon-Accord Centre, Aberdeen, AB25 1HZ",
      isEnabled: true,
    },
    {
      id: "s07",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Tackle.png",
      title: "Tackle & Books",
      metaTitle: "20 Dee Street, Banchory, AB31 5ST",
      isEnabled: true,
    },
    {
      id: "s08",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Camphill.png",
      title: "Camphill Bookshop",
      metaTitle: "The Celtic House, Shore St, Bowmore, Isle of Islay PA43 7LD",
      isEnabled: true,
    },
    {
      id: "s09",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Colonsay.png",
      title: "Colonsay bookshop",
      metaTitle: "18 Ythan Terrace, Ellon, Aberdeenshire AB41 9LJ",
      isEnabled: true,
    },
    {
      id: "s10",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797732/Birlinn/Web/Project-Images/Bookshop.png",
      title: "Orb's Bookshop",
      metaTitle: "6-8 Main Street, Tobermory, Isle of Mull PA75 6NU",
      isEnabled: true,
    },
    {
      id: "s11",
      image:
        "https://res.cloudinary.com/zudu/image/upload/v1655797733/Birlinn/Web/Project-Images/Yeadons.png",
      title: "Yeadons of Banchory",
      metaTitle: "Unit 2, Lorne Street, Lochgilphead",
      isEnabled: true,
    },
  ];

  const tableHeader = [
    {
      day: "Day",
      startday: "Start Time",
      endday: "End Time",
      hrs: "24 hrs",
      action: "Action",
    },
  ];

  const businessCategoryData = [
    {
      id: "1",
      title: "ALL",
      value: "ALL",
    },
    {
      id: "2",
      title: "Edinburgh",
      value: "Edinburgh",
      isSelected: false,
    },
    {
      id: "3",
      title: "Highlands",
      value: "Highlands",
      isSelected: false,
    },
    {
      id: "4",
      title: "Aberdeenshire",
      value: "Aberdeenshire",
      isSelected: true,
    },
    {
      id: "5",
      title: "Argyll and Bute",
      value: "Argyll and Bute",
      isSelected: false,
    },
    {
      id: "6",
      title: "Argyll and Bute",
      value: "Argyll and Bute",
      isSelected: false,
    },
  ];

  const shopSortData = [
    {
      id: "1",
      title: "Ascending (A-Z)",
      value: "Ascending (A-Z)",
      sortValue: "name:asc"
    },
    {
      id: "2",
      title: "Descending (Z-A)",
      value: "Descending (Z-A)",
      sortValue: "name:desc"
    },
  ];

  const timingCategoryData = [
    {
      id: "1",
      title: "9:00 am",
      value: "9:00 am",
    },
    {
      id: "2",
      title: "10:00 am",
      value: "10:00 am",
    },
    {
      id: "3",
      title: "11:00 am",
      value: "11:00 am",
    },
    {
      id: "4",
      title: "9:00 am",
      value: "9:00 am",
    },
  ];

  const bookCategories = [
    { id: "b01", title: "Action and Adventure", value: "Action and Adventure" },
    { id: "b02", title: "Classics", value: "Classics" },
    {
      id: "b03",
      title: "Comic Book or Graphic Novel",
      value: "Comic Book or Graphic Novel",
    },
    {
      id: "b04",
      title: "Detective and Mystery",
      value: "Detective and Mystery",
    },
    { id: "b05", title: "Fantasy", value: "Fantasy" },
    { id: "b06", title: "Historical Fiction", value: "Historical Fiction" },
    { id: "b06", title: "Horror", value: "Horror" },
    { id: "b07", title: "Literary Fiction", value: "Literary Fiction" },
  ];

  /**
   * An object containing the fields and validation rules for a step form.
   * @typedef {Object} StepForm
   * @property {Object} fullName - The full name field of the form.
   * @property {string} fullName.name - The name of the full name field.
   * @property {Object} fullName.validate - The validation rules for the full name field.
   * @property {Object} fullName.validate.required - The required validation rule for the full name field.
   * @property {boolean} fullName.validate.required.value - The value of the required validation rule for the full name field.
   * @property {string} fullName.validate.required.message - The error message to display if the required validation rule fails for the full name field.
   * @property
   */
  const stepform = {
    fullName: {
      name: "fullName",
      validate: {
        required: {
          value: true,
          message: strings["INVALID_NAME"],
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: strings["ONLY_ALPHABETICAL_CHARACTERS"],
        },
      },
    },
    phoneNumber: {
      name: "phoneNumber",
      validate: {
        required: {
          value: false,
        },
        minLength: {
          value: 10,
          message: strings["PHONE_NO_MUST"],
        },
      },
    },
    email: {
      name: "email",
      validate: {
        required: {
          value: false,
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: strings["INVALID_EMAIL"],
        },
      },
    },
    website: {
      name: "website",
      validate: {
        required: {
          value: false,
        },
        pattern: {
          value: Constant.REGEX.URL,
          message: strings["INVALID_URL"],
        },
      },
    },
    region: {
      name: "region",
      validate: {
        required: {
          value: true,
          message: strings["INVALID_REGION"],
        },
      },
    },
    address: {
      name: "address",
      validate: {
        required: {
          value: true,
          message: strings["ADDRESS_REQUIRED"],
        },
      },
    },
    location: {
      name: "location",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };

  /**
   * Handles the event when a user changes their profile image.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onProfileImageChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setProfileImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };

  /**
   * Sets the state of the body to the given event.
   * @param {Event} e - The event object.
   * @returns None
   */
  const handleBody = (e) => {
    setBody(e);
  };

  /**
   * Handles the change event for a radio button group. Sets the confirm type state to the value of the selected radio button.
   * @param {Event} event - The change event triggered by selecting a radio button.
   * @returns None
   */
  const radioChangeHandler = (event) => {
    setConfirmType(event.target.value);
  };

  /**
   * Updates the status of a shop to "Deleted" and displays a success message.
   * @param {string} deleteShopId - the ID of the shop to delete
   * @returns None
   */
  const onPressDeleteBook = async (deleteShopId) => {
    await AxiosInstance.put(`admin/shop/updatestatus?shopId=${deleteShopId}`, {
      status: 'Deleted',
    }).then((response) => {
      props.showToast({
        message: response.message,
        type: "success",
      })
      dispatch({ type: 'RESET_PAGE' })
      getShops(searchText ? searchText : '', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '');
    }).catch((error) => {
    })
    setShowDeleteModal(false);
    setShowListDetails(showlistDetails ? false : showlistDetails);
    props.showToast({
      message: "The book has been deleted successfully.",
      type: "success",
    });
  };

  /**
   * Updates a book with the given ID using the data from the form.
   * @param {string} updateBookId - The ID of the book to update.
   * @returns None
   */
  const onPressUpdateBook = async (updateBookId) => {
    const editform = stepForm.watch();
    let params = {
      name: editform?.fullName,
      phone: phone,
      email: editform?.email,
      website: editform?.website,
      regionName: editform?.tabDropdown?.value,
      region: editform?.tabDropdown.id,
      locationName: editform?.location?.address,
      LONG: editform?.location?.location?.long,
      LAT: editform?.location?.location?.lat,
      cafe: confirmType,
      serviceTiming: serviceData,
      address: editform.address,
      // description: textEditorDelta,
      description: editform.description,
    }
    if (profileImage) {
      // params.profileImage = await uploadFIle(profileImage)
      params.shopImage = await uploadFIle(profileImage)
    }
    await AxiosInstance.put(`admin/shop/edit?shopId=${updateBookId}`, params).then((response) => {
      props.showToast({
        message: "The book has been updated successfully.",
        type: "success",
      });

      var myDiv = document.getElementById('shopPage');
      myDiv.scrollTop = 0;
    })
    setShowUpdateModal(false);
    setShowListDetails(showlistDetails ? false : showlistDetails);
    dispatch({ type: 'RESET_PAGE' })
    getShops(searchText ? searchText : '', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '')


  };

  /**
   * Handles the enabling or disabling of a book.
   * @param {string} disableId - The ID of the book to enable or disable.
   * @param {string} bookStatus - The current status of the book.
   * @returns None
   */
  const onPressEnableDisable = async (disableId, bookStatus) => {
    setShowEnableDisableModal(false);
    setShowListDetails(false);
    let params = {}
    if (bookStatus === "Enabled") {
      params.status = "Disabled"
    } else {
      params.status = "Enabled"
    }
    await AxiosInstance.put(`admin/shop/updatestatus?shopId=${disableId}`, params).then((response) => {
      props.showToast({
        message: shopDetail?.status === "Enabled"
          ? "The book has been disabled successfully."
          : "The book has been enabled successfully.",
        type: "success",
      })
      getShops(searchText ? searchText : '', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '')
    }).catch((err) => {
    })
  };

  /**
   * Handles the cancel confirm button press event.
   * Hides the cancel confirmation modal and sets the change show state to false.
   * @returns None
   */
  const onPressCancelConfirm = () => {
    setShowCancelConfirm(false);
    setChangeShow(false);
  };

  /**
   * Handles the import of a CSV file to the server.
   * @param {Object} data - The data object containing the CSV file to import.
   * @returns None
   */
  const onPressImport = async (data) => {
    var bodyFormData = new FormData();
    const url = 'auth/shopupload'
    bodyFormData.append('avatar', csvFile)
    await AxiosInstance({
      method: "post",
      url: url,
      data: bodyFormData,
      headers: { "Accept": "multipart/form-data" }
    }).then((response) => {
      props.showToast({
        message: "The item has been imported successfully",
        type: "success",
      });
      getShops(searchText ? searchText : '', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '')
    })
    setShowImportModal(false);
    setConfirm(true);
    // setTimeout(() => {
    //   setConfirm(true);
    //   history("/shop");
    // }, 3000);
  };

  /**
   * Callback function that is triggered when a form is submitted.
   * @param {Object} data - The data submitted from the form.
   * @returns None
   */
  const onFormSubmit = (data) => {
    setBusy(true);
    stepForm.reset(
      {
        fullName: "",
        region: "",
        email: "",
        address: "",
        phoneNumber: "",
        website: "",
        location: "",
        tabDropdown: "",
        description: ""
      },
      {
        keepErrors: false,
        keepDirty: false,
        KeepIsSubmitted: false,
        KeepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
        keepValues: false,
      }
    );
    // setProfileImage(null);
    setBody(null)
    // setShopDetail(null)
  };

  // clear all ext data from forms
  const clearAllContent = () => {
    setProfileImage(null);
    setBody(null)
    setShopDetail(null)
    setPhone(null)
  }

  /**
   * Initializes the timing data for the service by setting the start and end times for each item in the timingContent array.
   * @returns None
   */
  const initTiming = () => {
    let dupServiceData = timingContent;
    for (var i = 0; i < dupServiceData.length; i++) {
      dupServiceData[i].startTime = timeData[18]?.value;
      dupServiceData[i].endTime = timeData[34]?.value;
    }
    setServiceData(dupServiceData);
    // onShopDetails()
  };

  /**
   * Updates the start time of a service in the serviceData array.
   * @param {any} value - The new start time value.
   * @param {number} index - The index of the service to update.
   * @returns None
   */
  const onStartTime = (value, index) => {
    let dupServiceData = JSON.parse(JSON.stringify(serviceData));
    for (var i = 0; i < dupServiceData.length; i++) {
      if (dupServiceData[i].id === index) {
        dupServiceData[i].startTime = value;
      }
    }
    setServiceData([...dupServiceData]);
  };

  /**
   * Updates the end time of a service in the serviceData array.
   * @param {any} value - the new end time value
   * @param {number} index - the index of the service to update
   * @returns None
   */
  const onEndTime = (value, index) => {
    let dupServiceData = JSON.parse(JSON.stringify(serviceData));
    for (var i = 0; i < dupServiceData.length; i++) {
      if (dupServiceData[i].id === index) {
        dupServiceData[i].endTime = value;
      }
    }
    setServiceData([...dupServiceData]);
  };

  /**
   * Updates the status of a service in the serviceData array based on the given id.
   * @param {boolean} status - the new status of the service
   * @param {number} id - the id of the service to update
   * @returns None
   */
  const onToggleChange = (status, id) => {
    let duptimingContent = JSON.parse(JSON.stringify(serviceData));
    for (var i = 0; i < duptimingContent.length; i++) {
      if (duptimingContent[i].id === id) {
        duptimingContent[i].status = status;
      }
    }
    setServiceData([...duptimingContent]);
  };
  /**
   * Updates the status of a checkbox with the given ID and updates the business category list.
   * @param {boolean} status - The new status of the checkbox.
   * @param {number} id - The ID of the checkbox to update.
   * @returns None
   */
  const onChangeCheckBox = (status, id) => {
    let dupBusinessCategoryList = JSON.parse(
      JSON.stringify(businessCategoryList)
    );
    for (var i = 0; i < dupBusinessCategoryList.length; i++) {
      if (dupBusinessCategoryList[i].id === id) {
        dupBusinessCategoryList[i].isSelected = status;
      }
    }
    setBusinessCategoryList([...dupBusinessCategoryList]);
  };

  /**
   * Updates the status of a service checkbox and updates the service data accordingly.
   * @param {boolean} status - the new status of the checkbox
   * @param {number} id - the id of the service to update
   * @returns None
   */
  const onChangeServiceCheckBox = (status, id) => {
    let dupTimingCategoryList = JSON.parse(JSON.stringify(serviceData));
    for (var i = 0; i < dupTimingCategoryList.length; i++) {
      if (dupTimingCategoryList[i].id === id) {
        dupTimingCategoryList[i].fullDay = status;
        if (status === true) {
          dupTimingCategoryList[i].startTime = timeData[0].value;
          dupTimingCategoryList[i].endTime =
            timeData[timeData.length - 1].value;
        }
      }
    }
    // setTimingCategoryList([...dupTimingCategoryList]);
    setServiceData([...dupTimingCategoryList]);
  };

  /**
   * Handles the submission of a form to add a new shop. Sends a POST request to the server
   * with the form data and updates the list of shops.
   * @returns None
   */
  const onAddShopSubmit = async () => {
    setIsLoading(true)
    const data = stepForm.watch();
    console.log(data)
    let params = {
      name: data?.fullName,
      phone: phone,
      email: data?.email,
      website: data?.website,
      regionName: data?.tabDropdown?.value,
      region: data?.tabDropdown.id,
      locationName: data?.location?.address,
      LONG: data?.location?.location?.long,
      LAT: data?.location?.location?.lat,
      description: data.description,
      cafe: confirmType,
      serviceTiming: serviceData,
      address: data.address,
    }
    if (profileImage) {
      params.shopImage = await uploadFIle(profileImage)
    }
    await AxiosInstance.post('admin/shop/add', params).then((response) => {
    })
    dispatch({ type: 'RESET_PAGE' })
    getShops(searchText ? searchText : '', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '')
    clearAllContent()
    setIsLoading(false)
    onShopDetails();
  }
  /**
   * Resets the step form and clears the profile image and body state variables.
   * @returns None
   */
  const onShopDetails = () => {
    setChangeShow(false);
    setTimeout(() => {
      stepForm.reset(
        {
          fullName: "",
          region: "",
          email: "",
          address: "",
          phoneNumber: "",
          website: "",
          location: "",
          tabDropdown: "",
          description: ""
        },
        {
          keepErrors: false,
          keepDirty: false,
          KeepIsSubmitted: false,
          KeepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
          keepValues: false,
        }
      );
      setProfileImage(null);
      setBody(null)
    }, 1000);
  };
  /**
   * Sets the state of the update modal to true, which triggers the modal to be displayed.
   * @param {number} editShopId - The ID of the shop being edited.
   * @returns None
   */
  const onUpdateShopSubmit = async (editShopId) => {
    setShowUpdateModal(true);
  }


  /**
   * Inserts an image into the Quill editor at the current cursor position.
   * @param {Event} e - The event object.
   * @returns None
   */
  const insertImage = (e) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const cursorPosition = editor.getSelection().index;
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async function () {
        const uploadImage = await uploadFIle(input.files[0]);
        const link = uploadImage;
        editor.insertEmbed(cursorPosition, 'image', link);
        editor.setSelection(cursorPosition + 1);
      }
    }
  }


  /**
   * Inserts a video into the Quill editor at the current cursor position.
   * @param {Event} e - The event object.
   * @returns None
   */
  const insertVideo = (e) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const cursorPosition = editor.getSelection().index;
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/mp4');
      input.click();
      input.onchange = async function () {
        const uploadImage = await uploadFIle(input.files[0]);
        const link = uploadImage;

        editor.insertEmbed(cursorPosition, 'video', link, 'user');
      }
    }
  }

  /**
   * Returns an object containing modules for the Quill editor. The modules include a toolbar
   * and clipboard configuration.
   * @returns An object containing modules for the Quill editor.
   */
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [
          { align: "right" },
          { align: "center" },
        ],
        [{ list: "ordered" }],
        ["link", "image", "video"],
      ],
      handlers: {
        image: insertImage,
        video: insertVideo
      }
    },
    clipboard: {
      matchVisual: false,
    }
  }), [])

  return (
    <>
      <MetaTags>
        <title>Manage Shops | Scotland By-The-Book Admin - Birlinn Ltd</title>
        <meta name="description" content="Add an individual shop, bulk-upload shops, edit existing listing information, temporarily disable listings, or delete listings that are no longer required." />
        <meta property="og:title" content="Manage Shops | Scotland By-The-Book Admin - Birlinn Ltd" />
      </MetaTags>
      <div className="shopMain">
        <>
          <div className="headingHeight">
            <div className="setShop">
              <h1 className="title">{strings["MANAGESHOP"]}</h1>
              <div className="eventBtn">
                {/* add shop */}
                <CustomButton
                  type="button"
                  title={strings["ADDSHOP"]}
                  className="customAddEvent"
                  onClick={() => {
                    setChangeShow({ type: "add", show: true })
                    initTiming()
                    onFormSubmit()
                    clearAllContent()
                  }}
                ></CustomButton>
                <CustomButton
                  type="button"
                  something
                  title={strings["IMPORT"]}
                  className="customImport"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowImportModal(true);
                  }}
                ></CustomButton>
              </div>
            </div>
            <div className="setSearch">
              <div className="tabButtonSearch">
                <SearchBox
                  placeholder={strings["SEARCHBYSHOP"]}
                  value={searchText}
                  onSearch={(value) => {
                    if (value !== "") {
                      dispatch({ type: 'RESET_PAGE' })
                      setsearchText(value.slice(0, 200));
                      if (value?.length >= 3) {
                        // getShops(value, selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '')
                      }
                    } else {
                      setsearchText("");
                      dispatch({ type: 'RESET_PAGE' })
                      // getShops('', selectedShopId ? selectedShopId : '', filteredShops ? filteredShops : '');
                    }
                    setPageLoad(true);
                  }}
                  onClear={() => {
                    dispatch({ type: 'RESET_PAGE' })
                    setsearchText("");
                  }}
                />
              </div>
              <div className="tabDropdown">
                <Dropdown className="d-inline mx-2" autoClose="outside">
                  <Dropdown.Toggle id="dropdown-autoclose-outside"
                    onClick={() => {
                      setDropdownShow(!dropdownShow);
                    }}>
                    {strings['REGIONS']} ({selectedShopsRegion?.length})
                  </Dropdown.Toggle>
                  <Dropdown.Menu align={"end"} placement="bottom-end" show={dropdownShow} className={`${dropdownShow === false ? 'dropdown-menu showClose dropdown-menu-end' : 'dropdown-menu show dropdown-menu-end'} `}>
                    <div className="drop_list">
                      {shopsRegion?.map((value, index) => {
                        const checkedBox = () => {
                          let item = false;
                          selectedShopsRegion.length > 0 && selectedShopsRegion.forEach((i) => {
                            if (value._id === i.id) {
                              item = true;
                            }
                          })
                          return item;
                        }
                        return (
                          <CheckBox
                            key={value._id}
                            id={value._id}
                            labelTitle={value.name}
                            checked={checkedBox}
                            checkedIcon="icon-check-square-top"
                            onCheckedChange={(checked) => {
                              let shopsRegionList = [...selectedShopsRegion];
                              if (checked !== true) {
                                const remove = selectedShopsRegion.filter((l) => l.id !== value._id);
                                shopsRegionList = [...remove]
                              } else {
                                shopsRegionList.push({ id: value._id, name: value.name, checked: checked })
                              }
                              setSelectedShopsRegion([...shopsRegionList])
                            }}
                          />
                        );
                      })}
                    </div>
                    <div className="drop_btns">
                      <CustomButton
                        type="button"
                        title={strings["APPLY"]}
                        className="titleApply"
                        onClick={() => {
                          setDropdownShow(false);
                          const filteredShopsId = selectedShopsRegion.map((l) => {
                            return l.id;
                          })
                          dispatch({ type: 'RESET_PAGE' })
                          const check = selectedShopsRegion.some((item) => item.checked === true)
                          if (check) {
                            setValue(selectedShopsRegion)
                          }
                          setCheckApply(true);
                          setSelectedShopId(filteredShopsId);
                        }}
                      />
                      <CustomButton
                        type="button"
                        title={strings["CLEAR"]}
                        className="titleApply"
                        onClick={() => {
                          dispatch({ type: 'RESET_PAGE' })
                          setDropdownShow(false);
                          setValue([])
                          setSelectedShopsRegion([]);
                          setSelectedShopId(null)
                        }}
                      />
                    </div>
                  </Dropdown.Menu>

                </Dropdown>
              </div>
            </div>

            <div className="setPara">
              <p className="bookLine">{strings['ARGYLL-SHOWING']}   {value?.length > 0 && checkApply === true && ShopData?.length > 0 ? value?.map((item) => item.name).join(", ") : (ShopData?.length > 0 ? "All" : "No")}   {value?.length === 1 && checkApply === true ? "region" : "regions"} shops</p>
              <div className="tabDropdownSecond">
                <CustomDropdown
                  isReqired={true}
                  placeholder={strings["SORT_BY"]}
                  type="text"
                  autoClose={"outside"}
                  dropDownItems={shopSortData}
                  align={"end"}
                  selectedValue={shopsortCategory}
                  onSelect={(evt) => {
                    dispatch({ type: 'RESET_PAGE' })
                    setShopsortCategory(evt);
                    setFilteredShops(evt.sortValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              height: dimensions.height - headingHeight - 10 + "px",
            }}
            onScroll={(e) => {
              const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
              if (bottom && state.emptyList === false) {
                dispatch({ type: 'INCREMENT_PAGE' })
              }
            }}
            id="shopPage"
            className="shopPage"
          >
            <ul>
              {ShopData?.map((shopsItem) => {
                let item = shopsItem?.data;
                return (
                  <li
                    key={item._id}
                    className={
                      item.status === "Enabled" ? "shopIndex" : "disabled shopIndex"
                    }
                    onClick={() => {
                      setShowListDetails(true);
                      setShopDetail(item);
                      setLocation(item?.location?.coordinates);
                      setServiceData(item.serviceTiming)
                    }}
                  >
                    <div
                      className="shopIndexButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShopDetail(item);
                      }}
                    >
                      <CustomButton
                        type="button"
                        className="customEditbtn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setShopDetail(item);
                        }}
                      >
                        <i className="icon icon-trash-2" />
                      </CustomButton>
                      <CustomButton
                        type="button"
                        className="customEditbtn1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setChangeShow({ type: "edit", show: true });
                          setShopDetail(item);
                          setConfirmType(item?.cafe ? "yes" : "no")
                          setBookType({ id: item.region, name: item.regionName })
                          setTextEditor(parse(item?.description))
                          setPhone(item?.phone)
                          setPortLocation({
                            portLocation: {
                              latitude: item?.location?.coordinates[1],
                              longitude: item?.location?.coordinates[0],
                              address: item?.location
                            }
                          })
                        }}
                      >
                        <i className="icon icon-edit-2" />
                      </CustomButton>
                      <DropdownButton
                        id="dropdown-basic-button"
                        className="disableDropdown"
                        align={'end'}
                      >
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowEnableDisableModal(true);
                          }}
                        >
                          {item?.status === "Enabled"
                            ? strings["DISABLE"]
                            : strings["ENABLE"]}
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <img src={item?.shopImage ? item?.shopImage : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811437.svg'} className="shopImage" alt="" />
                    <div className="shopCard">
                      <div className="TitleWithTag">
                        <p className="title">{item?.name}</p>
                        <span>{item?.regionName}</span>
                      </div>
                      <p className="metaTitle">{item?.address}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* edit book modal */}
          <Offcanvas
            show={showlistDetails}
            onHide={() => {
              setShowListDetails(false)
              // onFormSubmit()
            }}
            {...props}
            placement="end"
            className="shopOffcanvas"
          >
            <Offcanvas.Header>
              <p className="alignCenters">
                <i
                  className="icon icon-arrow-left"
                  onClick={() => setShowListDetails(false)}
                ></i>
              </p>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="mainShopOffcanvas">
                <div className="mainshopCard">
                  <img
                    src={shopDetail?.shopImage ? shopDetail?.shopImage : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811437.svg'}
                    alt=""
                    className="scottishCardLeft"
                  />
                  <div className="scottishCardRight">
                    <p className="metaTitleshop">{shopDetail?.name}</p>
                    <p className="authorshop">
                      {shopDetail?.email}
                    </p>
                    <div className="detailsCardMain">
                      <p className="detailsCardMainInner">{shopDetail?.cafe === false ? 'no cafe' : 'cafe'}</p>
                    </div>
                  </div>
                </div>
                <div className="detailsCardButton">
                  <CustomButton
                    className="detailsButton"
                    title={strings["EDIT"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setChangeShow({ type: "edit", show: true });
                      setConfirmType(shopDetail?.cafe ? "yes" : "no")
                      setBookType({ id: shopDetail.region, name: shopDetail.regionName })
                      setTextEditor(parse(shopDetail?.description))
                      setPhone(shopDetail?.phone)
                      setPortLocation({
                        portLocation: {
                          latitude: shopDetail?.location?.coordinates[1],
                          longitude: shopDetail?.location?.coordinates[0],
                          address: shopDetail?.location
                        }
                      })

                    }}
                  >
                    <i className="icon icon-edit-2"></i>
                  </CustomButton>
                  <CustomButton
                    className="detailsButton"
                    onClick={() => {
                      setShowEnableDisableModal(true);
                    }}
                  >
                    <i className="icon icon-minus-circle"></i>
                    {shopDetail?.status === "Enabled"
                      ? strings["DISABLE"]
                      : strings["ENABLE"]}
                  </CustomButton>
                  <CustomButton
                    className="detailsButton"
                    title={strings["DELETE"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteModal(true);
                    }}
                  >
                    <i className="icon icon-trash-2"></i>
                  </CustomButton>
                </div>

                <div className="shopTitle">{strings['ABOUT_SHOP']}</div>
                <div className="aboutPartshop">
                  <div className="aboutshoptext">
                    <span>{shopDetail?.description && parse(parse(`${shopDetail?.description}`))}</span>
                  </div>
                </div>
                <div className="serviceTimings">
                  <p>{strings['SERVICE_TIMINGS']}</p>
                </div>
                <div className="Timingsweekend">
                  <div className="days">
                    {/* {/* Mon 24 Hours
                          <br />
                          Tue 5:00 am to 8:00 pm
                          <br />
                          Wed 5:00 am to 8:00 pm
                          <br />
                          Thu 5:00 am to 8:00 pm
                          <br />
                          Fri 5:00 am to 8:00 pm
                          <br />
                          Sat Close
                          <br />    
                          Sun Close */}
                    {shopDetail?.serviceTiming?.length > 1 &&
                      shopDetail?.serviceTiming?.map((l) => {
                        return (
                          <div>
                            {l?.day}   {l.status === false ? "closed" : l?.fullDay && l?.status === true ? "24 hours " : (l?.startTime + ' ' + 'to' + ' ' + l?.endTime)}
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
                <div className="shopLocation">{strings['BOOK_LOCATION']}</div>
                <div className="locationTitle">
                  {shopDetail?.address}
                </div>
                <div className="map_view">
                  <iframe
                    title="map"
                    className="map"
                    src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                      }&q=${get(
                        location,
                        "[1]",
                        1
                      )},${get(location, "[0]", 1)}`}
                    width="100%"
                    height="330px"
                    frameborder="0"
                    allowfullscreen="false"
                    aria-hidden="false"
                    tabindex="0"
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          {/* ADD FORM  */}
          <Offcanvas
            show={changeshow}
            onHide={() => {
              setChangeShow(false)
              if (changeshow.type === "add") { clearAllContent() }
            }}
            {...props}
            placement="end"
            className="AddShopOffcanvas"
          >
            <HookForm
              defaultForm={{}}
              init={(form) => setStepForm(form)}
              onSubmit={(e) => onFormSubmit(e)}
            >
              {(formMethod) => {
                return (
                  <>
                    <Offcanvas.Header>
                      <p
                        className="alignCenters"
                        onClick={(e) => {
                          setShowCancelConfirm(true);
                        }}
                      >
                        <i className="icon icon-arrow-left"></i>
                      </p>
                      <div className="addbtn">
                        <Label
                          className="Addtitle"
                          title={
                            changeshow.type === "add"
                              ? "Add Shop"
                              : changeshow.type === "edit" && "Edit Shop"
                          }
                        />
                        <div>
                          <CustomButton
                            defaultForm={{}}
                            type="submit"
                            title={changeshow.type === "add" ? "Add" : changeshow.type === "edit" && "Update"}
                            className={changeshow.type === "add" ? "customAddbtn" : changeshow.type === "edit" && "customUpdatebtn"}
                            disabled={changeshow.type === "add" &&
                              (
                                !formMethod?.formState.isValid
                                // profileImage &&
                                // bookType &&
                                // portLocation
                              )
                            }
                            loading={isLoading && true}
                            onClick={() => {
                              changeshow.type === "add" ?
                                onAddShopSubmit()
                                :
                                onUpdateShopSubmit()
                            }}
                          />
                        </div>
                      </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{ height: dimensions.height - 100 }}>
                      <div className="addForm">

                        <div className="addImage">
                          <div className="setImage">
                            <p>{strings['SHOP_IMAGE']}</p>
                          </div>
                          <div className="leftBox">
                            <div className="updateSectionimg">
                              <Controller
                                name="updateimage"
                                control={formMethod.control}
                                render={({
                                  field: { onChange, value },
                                }) => (
                                  <FileUpload
                                    onDrop={(acceptedFiles) => {
                                      onProfileImageChange(acceptedFiles);
                                      onChange(acceptedFiles);
                                    }}
                                    accept='image/jpeg,image/jpg,image/png'
                                  >
                                    <div className="imgBoxEmpty">
                                      {profileImage || shopDetail?.shopImage ? (
                                        <div className="imageShow">
                                          <img
                                            src={(profileImage || shopDetail?.shopImage) ? (profileImage || shopDetail?.shopImage) : 'https://res.cloudinary.com/zudu/image/upload/v1657769494/Birlinn/Web/Project-files/Frame_811436.svg'}
                                            alt="logo"
                                            className="audioRegion"
                                          />
                                          <div className="importImg">
                                            <i className="icon icon-upload"></i>
                                            <p> {strings['UPLOAD_IMG']}</p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="uploadedImg">
                                          <div className="importImg">
                                            <i className="icon icon-upload-1"></i>
                                            <p> {strings['Upload_img']}</p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </FileUpload>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="fieldPartsName">
                          <div className="setName">
                            <p>{strings["FULL_NAME"]}</p>
                          </div>
                          <TextField
                            formMethod={formMethod}
                            rules={stepform.fullName.validate}
                            defaultValue={changeshow.type === "edit" && shopDetail?.name ? shopDetail?.name : null}
                            name={stepform.fullName.name}
                            errors={formMethod?.formState?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder={strings["ENTER_SHOP_NAME"]}
                            isReqired={true}
                          />
                        </div>
                        <div className="fieldPartsphone">
                          <div className="setPhone">
                            <p>{strings["PHONE_NUMBER"]}</p>
                          </div>
                          <div className="phone_input">
                            <InputMask
                              value={phone}
                              onChange={e => setPhone(e.target.value)}
                              placeholder={strings["ENTER_PHONENUMBER_OPTIONAL"]}
                              mask="+44 999-9999-999"
                              maskChar=" "
                              max
                            />
                          </div>
                        </div>

                        <div className="fieldPartsemail">
                          <div className="setEmail">
                            <p>{strings["EMAIL"]}</p>
                          </div>
                          <TextField
                            formMethod={formMethod}
                            rules={stepform.email.validate}
                            defaultValue={changeshow.type === "edit" && shopDetail?.email ? shopDetail?.email : null}
                            name={stepform.email.name}
                            errors={formMethod?.formState?.errors}
                            autoFocus={true}
                            placeholder={strings["ENTER_EMAIL_NAME"]}
                          // isReqired={false}
                          />
                        </div>
                        <div className="fieldPartswebsite">
                          <div className="setwebsite">
                            <p>{strings["WEBSITE"]}</p>
                          </div>
                          <TextField
                            formMethod={formMethod}
                            rules={stepform.website.validate}
                            name={stepform.website.name}
                            defaultValue={changeshow.type === "edit" && shopDetail?.website ? shopDetail?.website : null}
                            errors={formMethod?.formState?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder={strings["ENTER_WEBSITE_NAME"]}
                          // isReqired={false}
                          />
                        </div>
                        <div className="fieldPartsradio">
                          <div className="setRadio">
                            <p>{strings["CAFE"]}</p>
                          </div>
                          <div className="radio_row">
                            <RadioButton
                              changed={radioChangeHandler}
                              id="YesRadio"
                              isSelected={confirmType === "yes"}
                              label="yes"
                              value="yes"
                            />
                            <RadioButton
                              changed={radioChangeHandler}
                              id="NoRadio"
                              isSelected={confirmType === "no"}
                              label="no"
                              value="no"
                            />
                          </div>
                        </div>

                        <div className="fieldPartstexteditor">
                          <div className="settexteditor">
                            <p>{strings["DESCRIPTION"]}</p>
                          </div>
                          <div className="updateSectioneditor">

                            <Controller
                              defaultValue={shopDetail?.description !== undefined ? parse(shopDetail?.description) : ''}
                              name="description"
                              control={formMethod.control}
                              render={({ field: { onChange, value } }) => (
                                <ReactQuill
                                  // ref={quillRef}
                                  placeholder="Write something"
                                  value={value} modules={modules}
                                  formats={[
                                    "header",
                                    "font",
                                    "size",
                                    "bold",
                                    "italic",
                                    "align",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                    "list",
                                    "bullet",
                                    "indent",
                                    "link",
                                    "image",
                                    "video",
                                    "color"
                                  ]}
                                  onChange={(content, delta, source, editor) => {

                                    // let textContent = editor.getText(content);
                                    // setTextEditor(content);
                                    // const conentsData = editor.getContents();
                                    // setTextEditorDelta(editor.getHTML());
                                    onChange(content)
                                    // setDescriptionLength(textContent?.length);
                                  }} />
                              )}
                            />

                            {/* <ReactQuill
                              ref={quillRef}
                              placeholder="Write something"
                              value={textEditor} modules={modules}
                              formats={[
                                "header",
                                "font",
                                "size",
                                "bold",
                                "italic",
                                "align",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "indent",
                                "link",
                                "image",
                                "video",
                                "color"
                              ]}
                              onChange={(content, delta, source, editor) => {
                                let textContent = editor.getText(content);
                                setTextEditor(content);
                                const conentsData = editor.getContents();
                                setTextEditorDelta(editor.getHTML());
                                // setDescriptionLength(textContent?.length);
                              }} /> */}
                          </div>
                        </div>
                        <div className="fieldParttiming">
                          <div className="settiming">
                            <p>Service Timing*</p>
                          </div>
                          <div className="serviceTiming">
                            <div className="btnEdit">
                              {" "}
                              <i
                                className="icon icon-edit"
                                onClick={addhandleShow}
                              ></i>
                            </div>
                            <div className="weekTiming">
                              <div className="weekendTiming">
                                <i className="icon icon-calendar"></i>
                                <h4 className="week">
                                  {
                                    serviceData.map((item) => {
                                      return (
                                        <div>
                                          {item.day}
                                        </div>
                                      )
                                    })
                                  }
                                </h4>
                                <i className="icon icon-clock"></i>
                                <h4 className="week1">
                                  {changeshow.type === "add" ?
                                    serviceData.map((item) => {
                                      return (
                                        <div>
                                          {item.status === false ? "closed" : item?.fullDay && item?.status === true ? "24 hours " : (item?.startTime + ' ' + 'to' + ' ' + item?.endTime)}
                                        </div>
                                      )
                                    })
                                    : shopDetail?.serviceTiming?.length !== 1 ? shopDetail?.serviceTiming?.map((l) => {
                                      return (
                                        <div>
                                          {l.status === false ? "closed" : l?.fullDay && l?.status === true ? "24 hours " : (l?.startTime + ' ' + 'to' + ' ' + l?.endTime)}
                                        </div>
                                      )
                                    }) : serviceData.map((item) => {
                                      return (
                                        <div>
                                          {item.status === false ? "closed" : item?.fullDay && item?.status === true ? "24 hours " : (item?.startTime + ' ' + 'to' + ' ' + item?.endTime)}
                                        </div>
                                      )
                                    })}</h4>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="fieldPartsregion">
                          <div className="setregion">
                            <p>{strings["REGION"]}</p>
                          </div>
                          <div className="BooktabDropdown">
                            <Controller
                              defaultValue=""
                              name="tabDropdown"
                              control={formMethod.control}
                              render={({ field: { onChange } }) => (
                                <CustomDropdown
                                  isReqired={true}
                                  placeholder={strings["BOOK_DROPDOWN"]}
                                  type="text"
                                  autoClose={"outside"}
                                  dropDownItems={bookCategoryList}
                                  align={"end"}
                                  selectedValue={bookType}
                                  onSelect={(evt) => {
                                    onChange(evt);
                                    setBookType({ id: evt.id, name: evt.name })
                                    setBusinessCategory(evt);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="fieldPartsaddress">
                          <div className="setaddress">
                            <p>{strings["ADDRESS"]}</p>
                          </div>
                          <TextField
                            formMethod={formMethod}
                            rules={stepform.address.validate}
                            defaultValue={changeshow.type === "edit" && shopDetail?.address}
                            name={stepform.address.name}
                            errors={formMethod?.formState?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder={strings["ENTER_ADDRESS_NAME"]}
                            isReqired={false}
                          />
                        </div>
                        <div className="fieldPartslocationTitle">
                          <div className="setlocationtitle">
                            <p>{strings["LOCATION"]}</p>
                          </div>
                          <div className="locationRightPart">
                            <div className="locationDropdown">
                              <Controller
                                defaultValue=""
                                control={formMethod?.control}
                                name={stepform.location.name}
                                rules={stepform.location.validate}
                                render={({ field: { onChange } }) => (
                                  <GooglePlaceDropDown
                                    getLocation={(place) => {
                                      place === null
                                        ? setPortLocation(null)
                                        : setPortLocation({
                                          portLocation: {
                                            latitude: place?.location?.lat,
                                            longitude: place?.location?.long,
                                            address: place?.address
                                          },
                                        });
                                      onChange(place);
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="map_view">
                              <iframe
                                title="map"
                                className="map"
                                src={`https://www.google.com/maps/embed/v1/place?key=${Constant.GOOGLEMAPAPI.APIKEY
                                  }&q=${get(
                                    portLocation,
                                    "portLocation.latitude",
                                    1
                                  )},${get(
                                    portLocation,
                                    "portLocation.longitude",
                                    1
                                  )}`}
                                width="100%"
                                height="330px"
                                frameborder="0"
                                allowfullscreen="false"
                                aria-hidden="false"
                                tabindex="0"
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                    </Offcanvas.Body>
                  </>
                );
              }}
            </HookForm>
          </Offcanvas>

          {/* SERVICES TIMING */}
          <Offcanvas
            show={addshow}
            onHide={addhandleClose}
            {...props}
            placement="end"
            className="servicesOffcanvas"
          >
            <Offcanvas.Header>
              <p className="alignCenters">
                <i
                  className="icon icon-arrow-left"
                  onClick={() => {
                    addhandleClose(true);
                  }}
                ></i>
              </p>
              <div className="updatebtn">
                <p className="donetitle">Service Timings</p>
                <div>
                  <CustomButton
                    defaultForm={{}}
                    // onSubmit={(e) => onChangeSubmit(e)}
                    type="submit"
                    title={strings["DONE"]}
                    className="customDonebtn"
                    onClick={() => {
                      addhandleClose(false);
                    }}
                  ></CustomButton>
                </div>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div>
                <p className="availableTitle">
                  {strings["AVAILABLE_DAYS"]}
                </p>
                <table>
                  {tableHeader?.map((item, index) => {
                    return (
                      <>
                        <tr key={item.day} className="serviceTable">
                          <th className="serviceDay">{item.day}</th>
                          <th className="serviceStart">
                            {item.startday}
                          </th>
                          <th className="serviceEnd">{item.endday}</th>
                          <th className="serviceHour">{item.hrs}</th>
                          <th className="serviceAction">{item.action}</th>
                        </tr>
                      </>
                    );
                  })}
                </table>
                <table className="subTiming">
                  {serviceData?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className="serviceDay">{item.day}</td>
                          <td>
                            <div className="timeDropdown">
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={
                                  item.startTime ? item.startTime : null
                                }
                                disabled={item.fullDay || !item.status}
                              >
                                {timeData?.map((value) => {
                                  return (
                                    <Dropdown.Item
                                      href="#"
                                      key={value.id}
                                      onClick={() =>
                                        onStartTime(value.value, item.id)
                                      }
                                    >
                                      {value.value}
                                    </Dropdown.Item>
                                  );
                                })}
                              </DropdownButton>
                            </div>
                          </td>
                          <td>
                            <div className="timeDropdown1">
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={item.endTime ? item.endTime : null}
                                disabled={item.fullDay || !item.status}
                              >
                                {timeData?.map((value) => {
                                  return (
                                    <Dropdown.Item
                                      href="#"
                                      key={timeData.id}
                                      onClick={() =>
                                        onEndTime(value.value, item.id)
                                      }
                                    >
                                      {value.value}
                                    </Dropdown.Item>
                                  );
                                })}
                              </DropdownButton>
                            </div>
                          </td>
                          <td>
                            <CheckBox
                              keyId={index}
                              id={item.id}
                              className="serviceBox"
                              labelTitle={item?.title}
                              checked={item?.fullDay}
                              checkedIcon="icon-check-square-top"
                              onCheckedChange={(checked) => {
                                onChangeServiceCheckBox(checked, item.id);
                              }}
                            />
                          </td>
                          <td>
                            <CustomToggleButton
                              onCheckChange={(e) => {
                                onToggleChange(e, item.id)
                              }}
                              checked={item.status}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </>
        {/* );
          }}
        </HookForm> */}
      </div>

      <ConfirmModal
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["DELETE"]}
        modalDescription={strings["SUREDELETE_SHOP"]}
        showModal={showDeleteModal}
        onPressLeft={() => {
          onPressDeleteBook(shopDetail._id)
        }}
        onPressRight={() => setShowDeleteModal(false)}
      />
      <ConfirmModal
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["UPDATE"]}
        modalDescription={strings["SUREUPDATE"]}
        showModal={showUpdateModal}
        onPressLeft={() => {
          onPressUpdateBook(shopDetail._id)
          setShopDetail(null)
          setConfirmType(null)
          setBookType(null)
          setPortLocation(null)
          setTextEditor('')
          setPhone(null);
          setProfileImage(null);
          setChangeShow(false)

        }}
        onPressRight={() => setShowUpdateModal(false)}
      />
      <ConfirmModal
        className="bookConfirmModal"
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["Cancle"]}
        modalDescription={strings["SURE_CANCEL_CHANGES"]}
        showModal={showCancelConfirm}
        onPressLeft={() => {
          onPressCancelConfirm();
          setShopDetail(null)
          setConfirmType(null)
          setBookType(null)
          setTextEditor('')
          setPhone(null);
          setPortLocation(null);
          setProfileImage(null)
          setShowListDetails(false);
        }}
        onPressRight={() => setShowCancelConfirm(false)}
      />

      <ConfirmModal
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={
          shopDetail?.status === "Enabled" ? strings["DISABLE"] : strings["ENABLE"]
        }
        modalDescription={
          shopDetail?.status === "Enabled"
            ? strings["SURE_DISABLE_BOOK"]
            : strings["SURE_ENABLE_BOOK"]
        }
        showModal={showEnableDisableModal}
        onPressLeft={() => {
          onPressEnableDisable(shopDetail._id, shopDetail.status)
        }}
        onPressRight={() => {
          setShowEnableDisableModal(false);
        }}
      />

      <ModalPopup
        className="bookImportModal"
        showModal={showImportModal}
        closeButton={true}
        onHide={() => {
          setShowImportModal(false);
        }}
      >
        <HookForm defaultForm={{}} init={(form) => setStepForm(form)}>
          {(formMethod) => {
            return (
              <>
                <div className="updateSection">
                  <Controller
                    name="uploadCSV"
                    control={formMethod.control}
                    render={({ field: { onChange, value } }) =>
                    (
                      <>
                        <input
                          type="file"
                          id={'csvFileUploader'}
                          accept=".xlsx, .xls, .csv"
                          onChange={(file) => {
                            onChange(file);
                            onCSVFileChange(file.target.files);
                          }}
                        />
                        <div className='importFileIcon' >{csvFileName === null ? <label htmlFor="csvFileUploader" className="csfileUploader"><i className='icon icon-log-in' />{strings['IMPORT_FILE']}</label> : <label htmlFor="csvFileUploader" className="csfileUploader">< i className='icon icon-file-plus' /><p className="fileName">{csvFileName}</p><span className="fileSize">{`${fileSize}    ${fileSize >= 2048 ? 'MB' : 'KB'}`}</span></label>}</div>
                      </>
                    )}
                  />
                </div>
                <div className="btnInline">
                  <CustomButton
                    type="button"
                    title={strings["YES"]}
                    onClick={() => {
                      onPressImport(false);
                    }}
                    className="titleYes"
                    disabled={csvFile === null ? true : false}
                  />
                  <CustomButton
                    type="button"
                    title={strings["NO"]}
                    onClick={() => {
                      setShowImportModal(false);
                    }}
                    className="titleNo"
                  />
                </div>
              </>
            );
          }}
        </HookForm>
      </ModalPopup>
    </>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * A function that maps dispatch actions to props. 
 * @param {function} showToast - A function that dispatches an action to show a toast notification.
 * @param {function} showLoader - A function that dispatches an action to show a loading spinner.
 * @param {function} hideLoader - A function that dispatches an action to hide a loading spinner.
 * @returns An object containing the mapped dispatch actions.
 */
const mapDispatchToProps = { showToast, showLoader, hideLoader };

/**
 * Connects the Shop component to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns A new component that is connected to the Redux store and the multilanguage higher-order component.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Shop));
