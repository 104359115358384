import React, { useState, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ConfirmModal from "../../assets/components/UI/ConfirmModal/ConfirmModal";
import { showToast } from "../../redux/actions/toastAction";
import useWindowDimension from "../../hooks/useWindowDimension";
import SearchBox from "../../assets/components/UI/SearchBox/SearchBox";
import FileUpload from "../../assets/components/UI/FileUpload/FileUpload";
import CustomButton from "../../assets/components/UI/CustomButton/CustomButton";
import HookForm from "../../assets/components/HookForm/HookForm";
import { uploadFIle } from "../../services/helper.service";
import { useRef } from "react";
import AxiosInstance from "../../services/axios";
import parse from 'html-react-parser';
import { showLoader, hideLoader } from "../../redux/actions/loaderActions";
import MetaTags from 'react-meta-tags';

import "./Regions.scss";

const Regions = (props) => {
  const dimensions = useWindowDimension();
  let { strings, showToast } = props;
  const [searchText, setsearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);

  const [show, setShow] = useState(false);
  const [changeshow, setChangeShow] = useState(false);
  const chnagehandleClose = () => setChangeShow(false);
  const [busy, setBusy] = useState(false);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [regions, setRegions] = useState([]);
  const [regionDetails, setRegionDetails] = useState([]);
  const [profileForm, setProfileForm] = useState();
  const [editRegionId, setEditRegionId] = useState('')
  const [body, setBody] = useState("");

  const changehandleShow = () => setChangeShow(true);

  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [regionImage, setRegionImage] = useState();

  const [regionAudioName, setRegionAudioName] = useState(null);
  const [regionVideoName, setRegionVideoName] = useState(null);
  const [expandCard, setExpandCard] = useState("");
  const [showAll, setShowAll] = useState({ id: null, show: false });
  const [textEditor, setTextEditor] = useState('')
  const [textEditorDelta, setTextEditorDelta] = useState('')

  /**
   * Handles the event when a user changes their profile image. 
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onProfileImageChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setRegionImage(event.target.result);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  };
  /**
   * Handles the cancel confirm button press event.
   * Hides the cancel confirmation modal and sets the change show state to false.
   * @returns None
   */
  const onPressCancelConfirm = () => {
    setShowCancelConfirm(false);
    setChangeShow(false);
  };
  /**
   * A component that displays a limited amount of text content and provides a "read more" button
   * to expand the content.
   * @param {Object} props - The props object.
   * @param {string} props.content - The text content to display.
   * @param {number} props.limit - The character limit for the text content before it is truncated.
   * @param {string} props.name - The name of the content.
   * @returns A JSX element that displays the truncated text content and a "read more" button.
   */
  const ReadMoreText = ({ content, limit, name }) => {
    const toShow = parse(content?.substring(0, limit));
    if (content?.length <= limit) {
      return <p className="areaSubTitle table-scroll">{parse(content)}</p>;
    } else {
      if (showAll.id === name) {
        return (
          <>
            <p className="areaSubTitle table-scroll">
              {parse(content)}
            </p>
            <div className="more_btn">
              <span
                onClick={(e) => {
                  setShowAll({ id: null, show: false });
                  e.stopPropagation();
                  setExpandCard("");
                }}
              >
                less
              </span>
            </div>
          </>
        );
      } else if (showAll.id !== name) {
        return (
          <>
            <p className="areaSubTitle table-scroll">
              {toShow}
            </p>
            <div className="more_btn">
              <span
                onClick={(e) => {
                  // showMore();
                  setExpandCard(name);
                  e.stopPropagation();
                  setShowAll({ id: name, show: true });
                }}
              >
                more
              </span>
            </div>
          </>
        );
      }
    }
  };


  /**
   * Toggles the playback of an audio and video element.
   * If the audio element is playing, it will pause the video element and vice versa.
   * @returns None
   */
  const handleAudio = () => {
    audio.current.play() ? video.current.pause() : audio.current.pause()
  }

  /**
   * Plays or pauses the video and pauses the audio if it is playing.
   * @returns None
   */
  const handleVideo = () => {
    video.current.play() ? audio?.current.pause() : video.current.play()
  }

  const audio = useRef(null);
  const video = useRef(null);

  /**
   * Reads the contents of an audio file and sets the state of the region audio name.
   * @param {File[]} acceptedFiles - An array of accepted audio files.
   * @returns None
   */
  const onRegionAudio = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setRegionAudioName(event.target.result);
    });
    reader?.readAsDataURL(acceptedFiles[0]);
  };

  /**
   * Handles the change event when a new video file is selected for the region.
   * @param {File[]} acceptedFiles - An array of accepted files.
   * @returns None
   */
  const onRegionVideoChange = async (acceptedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setRegionVideoName(event.target.result);
    });
    reader?.readAsDataURL(acceptedFiles[0]);
  };

  /**
   * Sets the state of `show` to true, which will cause the regions view to be displayed.
   * @returns None
   */
  const onPressregionsView = () => {
    setShow(true);
  };

  /**
   * Handles the submission of form data and displays a success toast message.
   * @param {Object} data - the form data to be submitted
   * @returns None
   */
  const onChangeSubmit = (data) => {
    setBusy(true);
    props.showToast({
      message: "s",
      type: "success",
    });
  };
  /**
   * Retrieves a region from the server based on the given search string.
   * @param {string} search - The search string to use when querying the server.
   * @returns None
   * @throws {Error} If there is an error retrieving the region from the server.
   */
  const getRegion = async (search) => {
    props.showLoader()
    try {
      const { payload } = await AxiosInstance.get(
        `admin/region/get?search=${search ? search : ""}`
      );
      setRegions(payload);
      props.hideLoader();
    } catch (err) {
      props.hideLoader();
    }
  };
  /**
   * This effect hook sets the height of the heading element and retrieves the region based on the search text.
   * @returns None
   */
  useEffect(() => {
    setHeadingHeight(
      document.getElementsByClassName("headingHeight")[0]?.offsetHeight
    );
    getRegion(searchText ? searchText : "")
  }, []);


  /**
   * Updates a book with the given ID with the new information provided in the text editor, image, audio, and video.
   * @param {string} id - The ID of the book to update.
   * @returns None
   */
  const onPressUpdateBook = async (id) => {
    const params = {
      about: textEditorDelta
    }
    if (regionImage) {
      params.profileImage = await uploadFIle(regionImage);
    }
    if (regionAudioName) {
      params.audio = await uploadFIle(regionAudioName);
    }
    if (regionVideoName) {
      params.video = await uploadFIle(regionVideoName);
    }
    await AxiosInstance.put(`admin/region/edit?regionId=${id}`, params).then((response) => {
      props.showToast({
        message: "The item has been updated successfully.",
        type: "success",
      });
      getRegion(searchText ? searchText : "")
    }).catch((error) => {
    })
    setShowUpdateModal(false);
    setChangeShow(changeshow ? false : changeshow);
  };

  return (
    <>
      <MetaTags>
        <title>Manage Regions | Scotland By-The-Book Admin - Birlinn Ltd</title>
        <meta name="description" content="Edit any of the regional information within the app and get an at-a-glance overview of the number of books, events, and bookshops within each region." />
        <meta property="og:title" content="Manage Regions | Scotland By-The-Book Admin - Birlinn Ltd" />
      </MetaTags>
      <div className="regionsMain">
        <div className="headingHeight">
          <h1>{strings["MANAGE_REGIONS"]}</h1>
          <div className="tabButtonSearch">
            <SearchBox
              placeholder={strings["SEARCHBYREGION"]}
              value={searchText}
              onSearch={(value) => {
                if (value !== "") {
                  setPage(1);
                  setsearchText(value?.slice(0, 200));
                  value.length >= 3 && getRegion(value);
                } else {
                  setsearchText("");
                  getRegion("");
                }
                setPageLoad(true);
              }}
              onClear={() => {
                setsearchText("");
                getRegion("");
              }}
            />
          </div>
          <p> {regions.length > 0 ? `${strings['SHOWING']} ${regions.length} ${strings['REGIONS']}` : 'No data found'}

          </p>
        </div>
        <div
          style={{ height: dimensions.height - headingHeight - 10 + "px" }}
          className="mainContainer"
        >
          <ul>
            {regions.map((item, index) => {
              return (
                <li key={index} className="mainIndex" onClick={(e) => {
                  setRegionDetails(item);
                  e.stopPropagation();
                  setTextEditor(parse(item?.about))
                  onPressregionsView();
                }}>
                  <div className="ic  utton">
                    <CustomButton
                      type="button"
                      title={strings["EDIT"]}
                      className="customEditbtn"
                      onClick={(e) => {
                        e.stopPropagation();
                        changehandleShow();
                        setRegionDetails(item);
                        setTextEditor(parse(item?.about))
                        setRegionAudioName(item?.audio ? item?.audio : null);
                        setRegionVideoName(item?.video ? item.video : null)
                      }
                      }
                    >
                      <i className="icon icon-edit-2" />
                    </CustomButton>
                  </div>
                  <img
                    src={item?.profileImage}
                    className="mainImage"
                    alt=""
                  />
                  <h5>{item?.name}</h5>
                  {item?.about && <ReadMoreText
                    content={parse((item?.about))} limit={185}
                    name={item._id}
                  />
                  }
                  {/* <p>{item?.about}</p>
                  <span>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      toggleAboutShow(item?._id);


                    }}>view less</button>
                  </span> */}

                  <div className="mainIcons">
                    <div className="main">
                      <i className="icon icon-events"></i>
                      <h6>{item?.totalEvents}</h6>
                    </div>
                    <div className="main">
                      <i className="icon icon-books"></i>
                      <h6>{item?.totalBooks}</h6>
                    </div>
                    <div className="main">
                      <i className="icon icon-shop"></i>
                      <h6>{item?.totalShops}</h6>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        {...props}
        placement="end"
        className="regionOffcanvas"
      >
        <Offcanvas.Header>
          <p className="alignCenters">
            <i
              className="icon icon-arrow-left"
              onClick={() => {
                setShow(false);
                setRegionDetails([])
                setTextEditor('')
                setRegionImage(null)
                setRegionAudioName(null);
                setRegionVideoName(null)
              }}
            ></i>
          </p>
          <p className="title">{regionDetails?.name}</p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <img
              src={regionDetails?.profileImage}
              alt=""
              className="regionimg"
            />
          </div>
          <div className="" onClick={changehandleShow}>
            <CustomButton
              type="submit"
              title={strings["EDIT"]}
              className="offcanvasEditbtn"
              onClick={() => {
                setBody(parse(regionDetails?.about))
                setRegionAudioName(regionDetails?.audio ? regionDetails?.audio : null);
                setRegionVideoName(regionDetails?.video ? regionDetails.video : null)
              }}
            >
              <i className="icon icon-edit-2" />
            </CustomButton>
          </div>
          <div className="regiontext">
            <p>  <span>{regionDetails?.about && parse(parse(`${regionDetails?.about}`))}</span></p>
          </div>
          <div className="Icons">
            <div className="off">
              <i className="icon icon-events"></i>
              <h6>{regionDetails?.totalEvents}</h6>
            </div>
            <div className="off">
              <i className="icon icon-books"></i>
              <h6>{regionDetails?.totalBooks}</h6>
            </div>
            <div className="off">
              <i className="icon icon-shop"></i>
              <h6>{regionDetails?.totalShops}</h6>
            </div>
          </div>
          {regionDetails.video && (
            <div className="media">
              <p>{strings['MEDIA']}</p>
              <iframe
                src={regionDetails?.video}
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={changeshow}
        onHide={() => setChangeShow(false)}
        {...props}
        placement="end"
        className="chnageOffcanvas"
      >
        <Offcanvas.Header>
          <p className="alignCenters">
            <i
              className="icon icon-arrow-left"
              onClick={(e) => {
                setShowCancelConfirm(true);
              }}
            ></i>
          </p>
          <div className="updatebtn">
            <p className="updatetitle">{regionDetails.name}</p>
            <div>
              <CustomButton
                defaultForm={{}}
                onSubmit={(e) => onChangeSubmit(e)}
                title={strings["UPDATE"]}
                className="customUpdatebtn"
                onClick={(e) => {
                  setShowUpdateModal(true);
                }}
              ></CustomButton>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="whiteBox">
            <HookForm
              defaultForm={{}}
              init={(form) => setProfileForm(form)}
            >
              {(formMethod) => {
                return (
                  <>
                    <div className="updateSection">
                      <Controller
                        name="updateimage"
                        control={formMethod.control}
                        render={({ field: { onChange, value } }) => (
                          <FileUpload
                            onDrop={(acceptedFiles) => {
                              onProfileImageChange(acceptedFiles);
                              onChange(acceptedFiles);
                            }}
                            accept="image/jpeg,image/jpg,image/png"
                          >
                            <div className="imgBoxEmpty">
                              <div className="hoverImg">
                                <div className="uploadedImg">
                                  {regionImage || regionDetails?.profileImage ? (
                                    <div>
                                      <img
                                        src={regionImage || regionDetails?.profileImage}
                                        alt="logo"
                                        className="imgRegion"
                                      />
                                      <div className="hoverDiv">
                                        < i className="icon icon-upload" />
                                        <p className="uploadText" >{strings["UPLOAD_IMG"]}</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="hoverDiv">
                                      < i className="icon icon-upload" />
                                      <p className="uploadText" >{strings["UPLOAD_IMG"]}</p>
                                    </div>
                                  )}

                                </div>
                              </div>
                            </div>
                          </FileUpload>
                        )}
                      />
                    </div>
                    <div className="updateAudio">
                      <div className="setAudio">
                        <p>{strings["UPLOAD_AUDIO"]}</p>
                      </div>
                      <div className="rightBox">
                        <div className="updateSectionaudio">
                          <Controller
                            name="regionaudio"
                            control={formMethod.control}
                            render={({ field: { onChange, value } }) => (
                              <FileUpload
                                onDrop={(acceptedFiles) => {
                                  onRegionAudio(acceptedFiles);
                                  onChange(acceptedFiles);
                                }}
                                accept=".mp3"
                              >
                                <div className="imgBoxEmpty">
                                  <div className="uploadedAudio">
                                    {regionAudioName || regionDetails?.audio ? (
                                      <audio controls autoplay ref={audio} onPlay={handleAudio} >
                                        <source src={regionAudioName || regionDetails?.audio} type="audio/mp3" />
                                      </audio>
                                    ) : (
                                      <div className="importAudio">
                                        <i className="icon icon-log-in"></i>
                                        <p>{strings['Import_File']}</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </FileUpload>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="updateVideo">
                      <div className="setVideo">
                        <p>{strings["UPLOAD_VIDEO"]}</p>
                      </div>
                      <div className="rightBox2">
                        <div className="updateSectionvideo">
                          <Controller
                            defaultValue=""
                            name="regionVideo"
                            control={formMethod.control}
                            render={({ field: { onChange, value } }) => (
                              <FileUpload
                                // files="video/mp4"
                                onDrop={(acceptedFiles) => {
                                  onRegionVideoChange(acceptedFiles);
                                  onChange(acceptedFiles);
                                }}
                                accept="video/mp4"
                              >
                                <div className="imgBoxEmpty">
                                  <div className="uploadedVideo">
                                    {regionVideoName || regionDetails?.video ? (
                                      <video width="400" height="100" controls ref={video} onPlay={handleVideo} >
                                        <source src={regionVideoName || regionDetails?.video} type="video/mp4" />
                                      </video>
                                    ) : (
                                      <div className="importVideo">
                                        <i className="icon icon-log-in"></i>
                                        <p>{strings['Import_File']}</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </FileUpload>
                            )}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="mainEditor">
                      <div className="setEditor">
                        <p>{strings['ABOUT_REGION']}</p>
                      </div>
                      <div className="updateSectioneditor">
                        <ReactQuill
                          placeholder="Write something"
                          value={textEditor} modules={{
                            toolbar: [
                              ["bold", "italic", "underline"],
                              [
                                { align: "right" },
                                { align: "center" },
                              ],
                              [{ list: "ordered" }],
                              ["link", "image"],
                            ],
                          }}
                          onChange={(content, delta, source, editor) => {
                            let textContent = editor.getText(content);
                            setTextEditor(content);
                            setTextEditorDelta(editor.getHTML());
                          }} />
                      </div>
                    </div>
                  </>
                );
              }}
            </HookForm>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <ConfirmModal
        className="bookConfirmModal"
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["NO"]}
        modalDescription={strings["SURE_CANCEL_CHANGES"]}
        showModal={showCancelConfirm}
        onPressLeft={() => {
          onPressCancelConfirm();
          setShow(false);
          setRegionDetails([])
          setBody(null)
          setRegionAudioName(null);
          setRegionVideoName(null)

        }}
        onPressRight={() => setShowCancelConfirm(false)}
      />
      <ConfirmModal
        className="bookConfirmModal"
        leftBtnTitle={strings["YES"]}
        rightBtnTitle={strings["NO"]}
        modalTitle={strings["UPDATE"]}
        modalDescription={strings["SUREUPDATE"]}
        showModal={showUpdateModal}
        onPressLeft={() => {
          onPressUpdateBook(regionDetails._id);
          setShow(false);
        }}
        onPressRight={() => setShowUpdateModal(false)}
      />
    </>
  );
};

/**
 * Maps the state of the Redux store to props for the component.
 * @param {Object} state - The current state of the Redux store.
 * @returns An object containing the props to be passed to the component.
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * A function that maps dispatch actions to props. 
 * @param {function} showToast - A function that dispatches an action to show a toast notification.
 * @param {function} showLoader - A function that dispatches an action to show a loading spinner.
 * @param {function} hideLoader - A function that dispatches an action to hide a loading spinner.
 * @returns An object containing the mapped dispatch actions.
 */
const mapDispatchToProps = { showToast, showLoader, hideLoader };

/**
 * Connects the Regions component to the Redux store and the multilanguage higher-order component.
 * @param {Function} mapStateToProps - A function that maps the state to props.
 * @param {Function} mapDispatchToProps - A function that maps dispatch actions to props.
 * @returns A new component that is connected to the Redux store and the multilanguage higher-order component.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Regions));
